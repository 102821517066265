import Bus from '@common/js/bus'
import Vuex from '@/assets/js/store'

export default {
  namespaced: true,
  state() {
    return {
      theme: '#869aa4', // 主题颜色
      role: {}, //角色信息
      modelIndex: 0, // 当前切换的角色人物index
      playerList: [], //人物列表
      pageMsgList: [], // 弹幕列表
      worldMsgList: [], //世界消息
      yuanGPT: [], //小元GPT消息
      privateChatMsgList: {}, //私聊消息库建立 【"id-id":[]】
      unreadMsgP: [], //私人未读消息数据"[sendId,recvId]"
      unreadMsgW: '', //发送者的id
      userInfoList: [], //数据库用户信息
      loadingSwitch: false, // loding界面开关
      yaoganWatch: 2, // 摇杆的监听方向
      webuiSwitch: false, // 交互界面开关 包括聊天语音视频个人中心等
      board: false, // 展板界面开关
      boardData: null, // 展板数据
      editMode: false, // 编辑模式
      boxShow: false, // 照片 视频查看 开关
      sits: null, // 座位信息
      sitShow: false, // 弹出填写座位信息,
      dbClick: 0, // 单击
      streamShow: false, // 弹出直播流填写界面
      standStatus: 0, // 起立状态 1坐下 2 起立
      connectInfo: {
        //请求消息头
        CMD_VISITOR_LOGIN: 1059,
        CMD_COMMON_HEARTBEAT: 1000, //心跳 1000,ping
        CMD_COMMON_POSITION: 3, //实时同步位置信息 3,positions
        CMD_BUFFER_POSITION: 4, //缓存位置信息 4,positions
        CMD_COMMON_DEBUG: 1100, //用来调试
        CMD_USER_LOGIN: 1050, //登录 1050,name,id,info
        CMD_USER_CHATS: 1051, //公屏聊天 1051,messaged
        CMD_USER_CHAT: 1052, //私聊 1052,rev_id,message
        CMD_USER_QUIT: 1053, //退出 1053,null
        CMD_USER_SIT_DOWN: 1054, //坐下 1054,sitname,id
        CMD_USER_STAND_UP: 1055, //起立 1055,sitname,id
        CMD_USER_CUSTOM: 1056, //用户广播自定义消息 1056,custom_msg
        CMD_USER_CUSTOM_SEND: 1057, //用户向指定id自定义消息 1057,rev_id,custom_msg
        CMD_ITEM_DISPLAY: 1070, //隐藏显示物体 1070,itemname
        CMD_SET_PLAYER: 1080, //变更人物信息，1080,name,info(base64)
        CMD_SCENE_STATE: 1058, //场景同步 object

        //响应消息头
        MSG_RELOGIN: 198, //重复登录,现有账户退出。198,id
        MSG_NULL: 200, //无消息头
        MSG_LOGIN_SUCCESS: 201, //登录成功 201,id
        MSG_NEWPLAYER: 202, //新用户加入 202,playerinfo
        MSG_QUIT: 203, //退出 203,id
        MSG_POS: 204, //位置信息  204,id,positions
        MSG_BUF_POS: 205, //缓存位置信息 205,positions0,positions1,....
        MSG_PLAYERS: 206, //用户列表 202,json_encode(players)  $player = array("fd" => $this->fd,"id" => $pid,"name" => $cur_name,"info" => $info);
        MSG_CHATS: 207, //公屏聊天 207,id,msg
        MSG_CHAT: 208, //私聊 208,send_id,rev_id,msg
        MSG_SITS: 209, //当前所有座位信息 209,json_encode(sits) $sit[$sitname] = id;
        MSG_SIT_DOWN: 210, //坐下 210,sitname,id
        MSG_STAND_UP: 211, //起立 211,sitname,id
        MSG_CUSTOM: 212, //用户自定义消息 212,id,custom_msg
        MSG_CLEAR_SITDOWNS: 214, //制清理入座信息 214,
        MSG_ITEM_DISPLAY: 220, //用来调试 220,itemname,0; //hide 0 /display 1
        MSG_DEBUG: 300, //用来调试
        MSG_ALL_CHATS: 221, //历史群聊记录 221,json_string
        MSG_MY_CHAT: 222, //历史私聊记录 221,json_string
        MSG_SET_PLAYER: 223, //人物信息 223,id,name,useinfo(base64)
        MSG_SCENE_STATE: 213 //场景状态信息
      },
      sendStreamId: 0, // 推流者id
      notJoinRoomButStream: '', // 未加入房间但收到推流
      editAuthShow: false, // 修改权限开关
      navOut: false, // 首页导航和退出登录的显示与隐藏
      enterPWD: false, // 输入密码的显示隐藏
      enterInfo: { e: null, i: null } // 输入密码的e,i
    }
  },

  mutations: {
    //websocket链接
    login(state, data) {
      let that = this
      let ws = document.o2.ws
      let responseCmd = state.connectInfo
      // state.connectInfo = { ...ws.requestCmd, ...ws.responseCmd }
      //历史群聊记录 221,json_string
      ws.on(responseCmd.MSG_ALL_CHATS, (str) => {
        let arr = JSON.parse(str)
        if (arr.length > 0) {
          arr.forEach((item) => {
            let msg = item.split(',')
            state.worldMsgList.push({
              name: msg[0],
              id: msg[1],
              msg: msg[2]
            })
          })
        }
      })

      //历史私聊记录 222,json_string
      ws.on(responseCmd.MSG_MY_CHAT, (str) => {
        let arr = JSON.parse(str)
        if (arr.length > 0) {
          arr.forEach((item) => {
            let msg = item.split(',')
            that.commit('chat/pcml', {
              sendname: msg[0],
              reName: msg[1],
              msg: msg[4],
              sendid: msg[2],
              reid: msg[3]
            })
          })
        }
      })

      // 登录成功
      ws.on(responseCmd.MSG_LOGIN_SUCCESS, (str) => {
        let arr = str.split(',')
        console.log(
          '%cWebsocket Login Success! Id: %c' + arr[0] + ' %cFd: %c' + arr[1],
          'color:#67C23A',
          'color:#409EFF',
          'color:#67C23A',
          'color:#409EFF'
        )
        state.role.fd = arr[1]
        Bus.$emit('ws_login_success')
      })

      // 获取用户列表 206
      ws.on(responseCmd.MSG_PLAYERS, (str) => {
        let arr = JSON.parse(str)
        arr.map((element) => {
          element.hasAudio = false
          element.hasVideo = false
          state.playerList.push(element)
        })

        // 通知聊天室 修改名称
        Bus.$emit('changeChatImg')
      })

      // 增加新用户
      ws.on(responseCmd.MSG_NEWPLAYER, (str) => {
        let arr = str.split(',')

        state.playerList.push({
          id: arr[0],
          name: arr[1],
          vip: arr[2],
          fd: arr[3],
          roomid: arr[4],
          userinfo: arr[5],
          hasAudio: false,
          hasVideo: false
        })

        // 通知聊天室 修改名称
        Bus.$emit('changeChatImg')
      })

      // 用户退出
      ws.on(responseCmd.MSG_QUIT, (str) => {
        var value = state.playerList.findIndex((el) => el.id == str)
        if (value != -1) {
          state.playerList.splice(value, 1)
        }
      })

      // 获取公屏聊天列表
      ws.on(responseCmd.MSG_CHATS, (str) => {
        let msg = str.split(',')
        var user = state.playerList.filter((el) => el.id == msg[0])
        var name = ''
        if (user.length) {
          name = user[0].name
        }

        if (msg.length == 3 && msg[2] == 'msgPage') {
          state.pageMsgList.push({
            name,
            msg: decodeURIComponent(msg[1])
          })
        } else {
          state.unreadMsgW = msg[0]
        }

        state.worldMsgList.push({
          name,
          msg: decodeURIComponent(msg[1]),
          id: msg[0]
        })
      })

      //获取私聊列表
      ws.on(responseCmd.MSG_CHAT, (str) => {
        let arr = str.split(',')
        var sendid = state.playerList.filter((el) => el.id == arr[0])[0].name
        var reid = state.playerList.filter((el) => el.id == arr[1])[0].name
        var m = arr[2]
        //privateChatMsgList: {}, //私聊消息库建立 【"id-id":[]】
        that.commit('chat/pcml', {
          sendname: sendid,
          reName: reid,
          msg: m,
          sendid: arr[0],
          reid: arr[1]
        })
        // 私人未读消息数据"sendId-recvId"
        state.unreadMsgP.push([arr[0], arr[1]])
      })

      //重复登录
      ws.on(responseCmd.MSG_RELOGIN, (str) => {
        if (state.role.fd == str) {
          // 退出登录 自己写
          Bus.$emit('relogin')
        }
      })

      // 变更信息
      ws.on(responseCmd.MSG_SET_PLAYER, (str) => {
        try {
          let arr = str.split(',')
          const id = arr[0]
          const name = arr[1]
          const { modelIdx } = JSON.parse(window.atob(arr[2]))

          let player = document.o2.playerMgr.getPlayerById(id)

          // 改头顶名称
          if (player && player.namePanel) {
            let height = player.namePanel.position.y
            // let color = player.namePanel.material.color;
            player?.add_lable_to_char(name, height)
            player.setModel(modelIdx)
          }

          state.playerList.forEach((f, i) => {
            if (f.id == id) {
              state.playerList[i].name = name
              state.playerList[i].userinfo = window.btoa(
                JSON.stringify({
                  modelIdx
                })
              )
            }
          })

          // 通知聊天室 修改头像
          Bus.$emit('changeChatImg')
        } catch (error) {
          console.log('%cUpdateInfo error' + error, 'color:red')
        }
      })

      //场景状态信息
      ws.on(responseCmd.MSG_SCENE_STATE, (str) => {
        let { send, url, roomid } = JSON.parse(str)
        if (send == state.role.id) {
          state.sendStreamId = state.role.id
          state.notJoinRoomButStream = url
          Vuex.commit('chat/setStreamShow', false)
          if (url) {
            Bus.$emit('message_tips', { theme: 'success', content: '推流成功' })
          }
        }

        if (url && roomid !== Vuex.state.roomId) {
          state.notJoinRoomButStream = url
        } else {
          Bus.$emit('screenStream', url)
        }
      })

      //自定义消息
      ws.on(responseCmd.MSG_CUSTOM, (str) => {
        let msg = str.split(',')

        if (msg[1].split('-')[0] == 'jinyuyin') {
          Bus.$emit('delAllpublish', {
            id: msg[0],
            type: 1,
            roomId: msg[1].split('-')[1]
          })
        }

        if (msg[1].split('-')[0] == 'jinshipin') {
          Bus.$emit('delAllpublish', {
            id: msg[0],
            type: 0,
            roomId: msg[1].split('-')[1]
          })
        }

        if (msg[1].split('-')[0] == 'outpersonSingle') {
          Bus.$emit('outpersonSingle', {
            sendid: msg[0],
            id: msg[1].split('-')[1]
          })
        }

        if (msg[1].split('-')[0] == 'jinyuyinbyone') {
          Bus.$emit('delOnepublish', {
            send: msg[0],
            id: msg[1].split('-')[1],
            type: 1,
            roomId: msg[1].split('-')[2]
          })
        }

        if (msg[1].split('-')[0] == 'jinshipinbyone') {
          Bus.$emit('delOnepublish', {
            send: msg[0],
            id: msg[1].split('-')[1],
            type: 0,
            roomId: msg[1].split('-')[2]
          })
        }

        if (msg[1].split('-')[0] == 'changeRoomId') {
          state.playerList.forEach((f) => {
            if (f.id == msg[0]) {
              f.roomid = msg[1].split('-')[1]
            }
          })
        }
      })
    },
    setRole(state, value) {
      state.role = value
    },
    setModelIndex(state, value) {
      state.modelIndex = value
    },
    // 发送消息
    send(state, data) {
      document.o2.ws.send(data)
    },
    // 删除弹幕列表消息
    setMsgPageValue(state) {
      state.pageMsgList.shift()
    },
    // 建立私聊信息库
    pcml(state, data) {
      let key1 = data.sendid + '-' + data.reid
      let key2 = data.reid + '-' + data.sendid
      if (
        !state.privateChatMsgList.hasOwnProperty(key1) &&
        !state.privateChatMsgList.hasOwnProperty(key2)
      ) {
        state.privateChatMsgList[key1] = []
        state.privateChatMsgList[key1].push({
          name: data.sendname,
          msg: decodeURIComponent(data.msg),
          id: data.sendid
        })
      } else {
        if (state.privateChatMsgList.hasOwnProperty(key1)) {
          state.privateChatMsgList[key1].push({
            name: data.sendname,
            msg: decodeURIComponent(data.msg),
            id: data.sendid
          })
        } else {
          state.privateChatMsgList[key2].push({
            name: data.sendname,
            msg: decodeURIComponent(data.msg),
            id: data.sendid
          })
        }
      }
      // console.log(state.privateChatMsgList, '++++')
      Bus.$emit('privateChatMsgListChange')
    },
    //移出已读的信息
    shiftOutUnread(state, data) {
      // debugger
      if (state.unreadMsgP.length == 0) return
      let index = state.unreadMsgP.findIndex(
        (item) =>
          (item[0] == data[0] && item[1] == data[1]) || (item[0] == data[1] && item[1] == data[0])
      )
      if (index != -1) state.unreadMsgP.splice(index, 1)
    },
    //恢复未读记录
    restoreUnread(state, data) {
      // state.unreadMsgP = data.unreadMsgP
      // state.unreadMsgW = data.unreadMsgW
      // Bus.$emit('unreadMsgPChange', data.unreadMsgP)
      // Bus.$emit('unreadMsgWChange', data.unreadMsgW)
    },
    //设置小元GPT记录
    setGPT(state, data) {
      // debugger
      if (data.flag) {
        let index = state.yuanGPT.findIndex((item) => item.msg.includes('请耐心等待结果返回'))
        state.yuanGPT.splice(index, 1)
      }
      state.yuanGPT.push({
        name: data.name,
        msg: decodeURIComponent(data.msg),
        id: data.id
      })
    },
    //清空小元GPT
    setGPTEmpty(state) {
      state.yuanGPT.length = 0
    },
    //数据库用户信息
    setUserInfoList(state, data) {
      state.userInfoList = data
      Bus.$emit('setUserInfoListChange')
    },
    changeTRTCStatus(state, e) {
      if (e) {
        let findIndex = state.playerList.findIndex((f) => f.id == e.id)
        if (findIndex != -1) {
          if (e.type == 'audio') {
            state.playerList[findIndex].hasAudio = e.flag
          } else if (e.type == 'video') {
            state.playerList[findIndex].hasVideo = e.flag
          } else {
            state.playerList[findIndex].hasAudio = e.flag
            state.playerList[findIndex].hasVideo = e.flag
          }
        }
      }
    },
    changeLoading(state, value) {
      state.loadingSwitch = value
    },
    changeWebui(state, value) {
      state.webuiSwitch = value
    },
    changeyaoganWatch(state, value) {
      state.yaoganWatch = value
    },
    setBoard(state, value) {
      state.board = value
    },
    setboardData(state, value) {
      state.boardData = value
    },
    changeEditMode(state) {
      if (state.role.auth !== 2) {
        return
      }
      state.editMode = !state.editMode
      Bus.$emit('editMode_change')
    },
    setboxShow(state, value) {
      state.boxShow = value
    },
    clearPlayer(state) {
      state.playerList = []
    },
    // 获取座位信息
    setSits(state, value) {
      state.sits = value
      state.sits.forEach((f) => {
        f.pos = {
          x: f.x,
          y: f.y,
          z: f.z
        }
      })
    },
    // 设置双击
    setDbClick(state, value) {
      if (value) {
        state.dbClick = 0
      } else {
        state.dbClick++
      }
    },
    changeSitShow(state, value) {
      if (!state.editMode) return
      if (state.role.auth !== 2) {
        return
      }
      state.sitShow = value
      if (!value) {
        document.o2play.pauseKeyStart()
      }
    },
    setStandStatus(state, value) {
      state.standStatus = value
    },
    setStreamShow(state, value) {
      state.streamShow = value
    },
    changeNotJoinRoomButStream(state, value) {
      state.notJoinRoomButStream = value
    },
    editAuth(state, value) {
      if (state.role.id != 18) {
        return
      }
      state.editAuthShow = value
      if (value) {
        Bus.$emit('setuserId', {
          id: state.role.id,
          auth: state.role.auth
        })
      }
    },
    // 在线变更auth
    changeAuth(state, value) {
      state.role.auth = value
    },
    setnavOut(state, value) {
      state.navOut = value
    },
    setEnterPWD(state, value) {
      state.enterPWD = value.enterPWD
      if (value.e) {
        state.enterInfo.e = value.e
        state.enterInfo.i = value.i
      }
    }
  },

  actions: {},

  getters: {}
}
