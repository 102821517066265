var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('XyzTransition',{attrs:{"xyz":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.openModalShow),expression:"$store.state.openModalShow"}],staticClass:"openmodal-shadow",on:{"click":_vm.close}},[_c('div',{staticClass:"openmodal",style:({
          'text-align': _vm.$store.state.openModalShowInfo.function === 'agree' ? 'left' : 'center'
        }),on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"openmodal-span"},[_vm._v(_vm._s(_vm.$store.state.openModalShowInfo.title))]),_c('div',{staticClass:"openmodal-btns"},[_c('button',{staticClass:"openmodal-btn",class:{
              cancel: _vm.hover.cancel
            },on:{"click":_vm.close,"touchstart":function($event){_vm.hover.cancel = true},"touchend":function($event){_vm.hover.cancel = false}}},[_vm._v(" "+_vm._s(_vm.$store.state.openModalShowInfo.cancel)+" ")]),_c('button',{staticClass:"openmodal-btn openmodal-btn2",class:{
              agree: _vm.$store.state.openModalShowInfo.function === 'agree',
              agreeclick: _vm.$store.state.openModalShowInfo.function === 'agree' && _vm.hover.ok,
              other: _vm.$store.state.openModalShowInfo.function !== 'agree',
              otherclick: _vm.$store.state.openModalShowInfo.function !== 'agree' && _vm.hover.ok
            },on:{"click":function($event){return _vm.functions(_vm.$store.state.openModalShowInfo.function)},"touchstart":function($event){_vm.hover.ok = true},"touchend":function($event){_vm.hover.ok = false}}},[_vm._v(" "+_vm._s(_vm.$store.state.openModalShowInfo.ok)+" ")])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }