import * as THREE from 'three/build/three.module'
import { wsClient } from '../../../common/js/wsClient'
import { sit } from './api'
import Vuex from './store'

var _o2 = null
var lastSendPos
var lastSendTime = 0
var lastSyncTime = 0
var ws
var cfg
var role

export class multiplayer {
  constructor(o2) {
    _o2 = o2
    _o2.ws = wsClient
    cfg = Vuex.state
    ws = wsClient
    this.mapPlayerInfo = {}
    this.mapHand = {}
    this.mapPoP = {}
    this.bLogin = false
    this.moveSpd = 3000
    this.bShowChatPop = true
    this.bSendRolePos = true
    this.playerCount = 0
    this.uiPop
    this.RoleId = 0
  }

  init(url, roleInfo, roomId) {
    this.roleInfo = roleInfo
    this.loginWS(url, roleInfo, roomId)
    this.addEventListener()
    this.initGif()
  }

  reLogin(roomId,roleInfo) {
    console.log(_o2.ws)
    console.log(_o2.ws.close)
    _o2.role = null
    _o2.playerMgr.removeAll()
    _o2.ws.close()
    this.loginWS(ws.curUrl, roleInfo, roomId)
  }

  initGif() {
    this.mapPrebGif = {}
    let gifUrls = Vuex.state.gifUrls
    if (gifUrls) {
      for (let i = 0; i < gifUrls.length; i++) {
        let idx = i
        document.o2.import_object_url(gifUrls[i]).then((obj) => {
          this.mapPrebGif[idx] = obj
        })
      }
    }
  }
  //登陆websocket服务器
  loginWS(url, roleInfo, roomId) {
    this.roleInfo = roleInfo
    ws.connect(url).then(() => {
      ws.send(`${ws.requestCmd.CMD_USER_LOGIN},${roleInfo.name},${roleInfo.id},${roleInfo.auth},${roomId},${window.btoa(JSON.stringify(roleInfo.info))}`)
    })
  }

  addEventListener() {
    ws.on(ws.responseCmd.MSG_LOGIN_SUCCESS, this.onLogin.bind(this))
    ws.on(ws.responseCmd.MSG_PLAYERS, this.onPlayers.bind(this))
    ws.on(ws.responseCmd.MSG_NEWPLAYER, this.onNewPlayer.bind(this))
    ws.on(ws.responseCmd.MSG_SITS, this.onSits.bind(this))
    ws.on(ws.responseCmd.MSG_CLEAR_SITDOWNS, this.onClearSitDowns.bind(this))
    ws.on(ws.responseCmd.MSG_QUIT, this.onPlayerQuit.bind(this))
    ws.on(ws.responseCmd.MSG_POS, this.onPos.bind(this))
    ws.on(ws.responseCmd.MSG_BUF_POS, this.onBufferPos.bind(this))
    ws.on(ws.responseCmd.MSG_SIT_DOWN, this.onSitDown.bind(this))
    ws.on(ws.responseCmd.MSG_STAND_UP, this.onStandUp.bind(this))
    ws.on(ws.responseCmd.MSG_CUSTOM, this.onCustom.bind(this))
    Vuex.commit('chat/login')
    _o2.frame_move_recall.push(this.onFrame.bind(this))
  }

  onFrame(t) {
    this.sendRolePos()
    this.syncPlayersPos(t)
  }

  onLogin(msg) {
    let id = parseInt(msg)
    this.RoleId = id
    let roleInfo = { id, ...this.roleInfo }
    if (this.customRoleInfo) {
      roleInfo = this.customRoleInfo(roleInfo)
    }
    _o2.playerMgr.import_role(roleInfo).then((p) => {
      this.bLogin = true
      _o2.role = p
      role = p
      if (this.onPlayerLoaded) {
        this.onPlayerLoaded(p)
      }
    })
  }

  onPos(data) {
    let id = data.getUint16(2, true) //id
    let x = data.getInt16(4, true) * 100 //x
    let y = data.getInt16(6, true) * 100 //y
    let z = data.getInt16(8, true) * 100 //z
    let rot = data.getInt16(10, true) //rot
    this.update_player_position(id, x, y, z, rot)
  }

  onBufferPos(data) {
    let lens = data.getUint16(2, true) //lens
    for (let i = 0; i < lens; i++) {
      let id = data.getUint16(i * 10 + 4, true) //id
      let x = data.getInt16(i * 10 + 6, true) * 100 //x
      let y = data.getInt16(i * 10 + 8, true) * 100 //y
      let z = data.getInt16(i * 10 + 10, true) * 100 //z
      let rot = data.getInt16(i * 10 + 12, true) //rot
      this.update_player_position(id, x, y, z, rot)
    }
  }

  onNewPlayer(msg) {
    let arrStr = msg.split(',')
    if (arrStr.length == 6) {
      let id = parseInt(arrStr[0])
      let name = arrStr[1]
      let vip = parseInt(arrStr[2])
      let info = JSON.parse(window.atob(arrStr[5]))
      this.addPlayer({ id, name, vip, info })
    }
  }

  onPlayers(msg) {
    let arr = JSON.parse(msg)
    arr.forEach((e) => {
      if (e.id != this.RoleId) {
        e.info = JSON.parse(window.atob(e.userinfo))
        this.addPlayer(e)
      }
    })
  }

  onSits(msg) {
    this.mapSitState = JSON.parse(msg)
    for (const key in this.mapSitState) {
      let id = parseInt(this.mapSitState[key])
      let p = _o2.playerMgr.getPlayerById(id)
      let sit = this.getSitByName(key)
      p?.setSit(sit)
    }
  }

  onSitDown(msg) {
    let arrStr = msg.split(',')
    if (arrStr.length == 2) {
      let sitName = arrStr[0]
      let id = parseInt(arrStr[1])
      let sit = this.getSitByName(sitName)
      let p = _o2.playerMgr.getPlayerById(id)
      p ? p.setSit(sit) : this.addFakePlayer(id, sit)
    }
  }

  onStandUp(msg) {
    let arrStr = msg.split(',')
    let sitName = arrStr[0]
    let id = parseInt(arrStr[1])
    let p = _o2.playerMgr.getPlayerById(id)
    if (p) {
      p.bFake ? _o2.playerMgr.removePlayer(id) : p.setSit()
    }
  }

  onClearSitDowns() {
    let allPlayer = _o2.playerMgr.dicPlayer
    for (const key in allPlayer) {
      if (Object.hasOwnProperty.call(object, key)) {
        let p = allPlayer[key]
        if (p.bFake) {
          _o2.playerMgr.removePlayer(p.id)
        } else {
          p.setSit()
        }
      }
    }
  }

  onPlayerQuit(msg) {
    let id = parseInt(msg)
    //let p = _o2.playerMgr.getPlayerById(id);
    _o2.playerMgr.removePlayer(id)
  }

  onCustom(msg) {
    let pos = msg.indexOf(',')
    if (pos > 0) {
      let id = parseInt(msg.substr(0, pos)) || 0
      msg = msg.substr(++pos)
      pos = msg.indexOf(",")
      let cmd = null
      let data = null
      if(pos > 0){
        cmd = msg.substr(0,pos)
        data = msg.substr(++pos)
      }else{
        cmd = msg
      }
      let p = _o2.playerMgr.getPlayerById(id)
      if(!p)return
      if (cmd == 'gif') {
        let idx = parseInt(msg.substr(4)) || 0
        clearTimeout(p.gif?.actionId)
        p.obj?.remove(p.gif)
        p.gif = this.mapPrebGif[idx]?.clone()
        if (p.gif) {
          p.obj?.add(p.gif)
          p.gif.position.set(0, Vuex.state.height, 0)
          p.gif.actionId = setTimeout(() => {
            p.obj?.remove(p.gif)
            p.gif = null
          }, Vuex.state.time)
        }
      }else if(p[cmd]){
        p[cmd](data)
      }
    }
  }

  syncPlayersPos(t) {
    if (_o2.playerMgr.arrPlayer) {
      //更新多人在线其他角色位置
      for (let i = 0; i < _o2.playerMgr.arrPlayer.length; i++) {
        let player = _o2.playerMgr.arrPlayer[i]
        if (player.obj && player != role) {
          this.move_player_position(player, t)
        }
      }
    }
  }

  sendRolePos() {
    let curTime = Date.now()
    if (curTime - lastSyncTime < 500) return
    if (this.bLogin && this.bSendRolePos && role.obj) {
      lastSyncTime = curTime
      let obj = role.obj
      let angle = Math.ceil((obj.rotation.y * 180) / Math.PI)
      let x = Math.ceil(obj.position.x / 100)
      let y = Math.ceil(obj.position.y / 100)
      let z = Math.ceil(obj.position.z / 100)
      let buffer = new ArrayBuffer(10)
      let data = new DataView(buffer)
      data.setInt8(0, 191)
      data.setInt8(1, ws.requestCmd.CMD_BUFFER_POSITION)
      data.setInt16(2, x, true)
      data.setInt16(4, y, true)
      data.setInt16(6, z, true)
      data.setInt16(8, angle, true)
      let str = x + ',' + y + ',' + z + ',' + angle
      if (str != lastSendPos) {
        lastSendPos = str
        wsClient.send(data)
        lastSendTime = curTime
      } else if (curTime - lastSendTime >= 30000) {
        lastSendTime = curTime
        wsClient.send(data)
      }
    }
  }

  addPlayer(info) {
    if (this.customPlayerInfo) {
      info = this.customPlayerInfo(info)
    }
    _o2.playerMgr.import_player(info).then((p) => {
      if (p.obj) {
        p.obj.visible = false
        if (this.onPlayerLoaded) {
          if (p.sit) {
            let sit = this.getSitByName(p.sit)
            p.setSit(sit)
          }
          this.onPlayerLoaded(p)
        }
      }
    })
  }

  addFakePlayer(id, sit) {
    if (cfg && cfg.playerCfg && cfg.playerCfg.sitModels) {
      let length = cfg.playerCfg.sitModels
      let playerInfo = {}
      playerInfo.id = id
      let modelIdx = Math.floor(Math.random() * length)
      playerInfo.info = { modelIdx }
      playerInfo.bFake = true
      if (this.customFakePlayerInfo) {
        playerInfo = this.customFakePlayerInfo(playerInfo)
      }
      _o2.playerMgr.import_player(playerInfo).then((p) => {
        p.setSit(cfg)
      })
    }
  }

  update_player_position(id_player, posx, posy, posz, angle) {
    let player = _o2.playerMgr.getPlayerById(id_player)
    if (player && player != role && player.obj) {
      if (!player.target) player.target = new THREE.Vector3()
      player.target.x = posx
      player.target.z = posz
      player.target.y = posy
      player.targetAngle = (angle * Math.PI) / 180
      if (player.obj && !player.bInitPos) {
        player.bInitPos = true;
        player.obj.position.set(posx, posy, posz)
        player.obj.visible = true
      }
    }
  }

  add_hand_to_sit(name) {
    let sit = get_sit(name)
    if (sit && handMtl) {
      if (!dicSitHand[name]) {
        let sitHand = new THREE.Sprite()
        sitHand.position.set(sit.pos.x, sit.pos.y + 2000, sit.pos.z)
        sitHand.material = handMtl
        sitHand.scale.set(500, 500, 1)
        handNode.add(sitHand)
        dicSitHand[name] = sitHand
      }
    }
  }

  remove_hand_to_sit(name) {
    if (dicSitHand && dicSitHand[name]) {
      handNode.remove(dicSitHand[name])
      delete dicSitHand[name]
    }
  }

  remove_all_hand() {
    if (dicSitHand) {
      for (const key in dicSitHand) {
        handNode.remove(dicSitHand[key])
        delete dicSitHand[key]
      }
    }
  }

  move_player_position(player, time_passed) {
    if (player.sit) return
    let dst = player.target.clone()
    let state = 'idle'
    dst.y = player.obj.position.y
    dst.sub(player.obj.position)
    let len = dst.length()
    if (len > 10) {
      state = 'walk'
    }
    player.setState(state)
    if (len <= this.moveSpd * time_passed || len > this.moveSpd * 1.5) {
      let angle = player.targetAngle
      if (!angle) angle = 0
      let current = player.obj.rotation._y
      if (!current) current = 0
      let dst2 = (angle * 180) / Math.PI - (current * 180) / Math.PI
      if (Math.abs(dst2) > 180) {
        if (dst2 < 0) current = current - Math.PI * 2
        else current = current + Math.PI * 2
      }
      current = current * 0.9 + angle * 0.1

      player.obj.rotation.set(0, current, 0)
      player.obj.position.set(player.target.x, player.target.y, player.target.z)
    } else {
      player.obj.lookAt(player.target.x, player.obj.position.y, player.target.z)
      let fullDst = player.target.clone().sub(player.obj.position)
      dst.y = 0
      let temp = fullDst.length() / dst.length()
      fullDst.normalize()
      fullDst.multiplyScalar(this.moveSpd * temp * time_passed)
      player.obj.position.add(fullDst)
    }
  }

  getSitByName(name) {
    if (Vuex?.state.chat.sits) {
      let sits = Vuex.state.chat.sits
      for (let i = 0; i < sits.length; i++) {
        if (name == sits[i].name) {
          return sits[i]
        }
      }
    }
  }
  //setChatPop(bgUrl,width,height,textWidth,textHeight,left,top,fontSize,)

  // addChatPop(p,msg){
  //   if(this.uiPop){
  //     this.uiPop.get
  //   }
  // }

  // chat_pop(char_obj, text, high = 2200) {
  //   document.copy
  //   if (!text.length) return;
  //   let old_item;
  //   let old_idx = -1;
  //   for (let i = 0; i < chat_pops.length; i++) {
  //     let chat = chat_pops[i];
  //     if (chat.parent == char_obj) {
  //       old_item = chat;
  //       old_idx = i;
  //       break;
  //     }
  //   }
  //   if (old_item) {
  //     //删掉
  //     old_item.parent.remove(old_item);
  //   }
  //   let image = new Image();
  //   //image.src = require("../assets/img/pop.png");
  //   image.onload =  ()=> {
  //     let canvas = document.createElement("canvas");
  //     canvas.width = 256;
  //     canvas.height = 512;
  //     const drawingContext = canvas.getContext("2d");
  //     drawingContext.fillStyle = "#000000";
  //     drawingContext.globalAlpha = 0.8;
  //     drawingContext.drawImage(this, 0, 0);
  //     drawingContext.globalAlpha = 1;
  //     drawingContext.fillStyle = "#000000";
  //     drawingContext.font = "20px Georgia";
  //     drawingContext.textAlign = "center";
  //     wrapText(drawingContext, text, 140, 140, 160, 24);
  //     //drawingContext.fillText("这是一大长串文字，不知道能不能自动换行啥的", 128, 128);

  //     let map = new THREE.CanvasTexture(canvas);
  //     let sprite = new THREE.Sprite(
  //       new THREE.SpriteMaterial({ map: map, color: "#ffffff" })
  //     );
  //     sprite.position.set(0, high, 0);
  //     sprite.scale.set(1000, 2000, 1);
  //     sprite.depthWrite = false;
  //     char_obj.add(sprite);
  //     sprite.beginTime = Date.now();
  //     sprite.canvas = canvas;
  //     sprite.alphaTest = 0.01;
  //     if (old_idx >= 0) {
  //       chat_pops[old_idx] = sprite;
  //     } else chat_pops.push(sprite);
  //   };
  // }
}
