import { get, post } from './api-request'

export const register_user2 = (params) => get('/register_user2.php', params) //token注册

export const sendCode = (params) => post('/public/users/sendSms', params) //获取验证码

export const register = (params) => post('/public/users/registerSms', params) //手机号注册

export const login = (params) => post('/public/users/login', params) //手机号登录

export const userInfo = (params) => post('/public/users/userInfo', params) //获取用户信息

export const guestLogin = (params) => post('/public/users/guestLogin', params) //游客登录

export const sit = (params) => post('/public/SitManager/list', params) //座位列表

export const getshow = (params) => post('/public/ShowsManager/getallshow', params) //展板列表

export const bindMobile = (params) => post('/public/users/bindMobile', params) //微信手机号绑定

export const setpsd = (params) => post('/public/users/uppsd', params) //微信手机号修改密码

export const beforeupload = (params) => post('/public/resouce/beforeupload', params) //上传前

export const afterupload = (params) => post('/public/resouce/afterupload', params) //上传后

export const addBoard = (params) => post('/public/ShowsManager/addBoard', params) //添加展板

export const delBoard = (params) => post('/public/ShowsManager/delBoard', params) //删除展板

export const add_sit = (params) => post('/public/SitManager/add', params) //录入座位坐标/public/SitManager/add

export const rename = (params) => post('/public/users/edit', params) //修改用户名

export const TrtcUserSig = (params) => post('/public/Common/TrtcUserSig', params) //腾讯实时音视频签名UserSig

export const AliSts = (params) => post('/public/Common/AliSts', params) //阿里OSS上传访问控制STS

export const edit = (params) => post('/public/users/edit', params)

export const wxLogin2 = (params) =>
  get('/public/users/wxLogin2?callbackHost=https://o2vr.net/upload/demoTest', params) //微信登录(内嵌)

export const wxEnter = (params) => get('/public/users/wxEnter', params) //微信登录

export const editBoard = (params) => post('/public/ShowsManager/editBoard', params) // 展板编辑

export const chatGPT = (params) => post('/public/ChatGPT/Chat', params) // ChatGPT聊天

export const chatGPTList = (params) => post('/public/ChatGPT/ChatList', params) // ChatGPT聊天记录

export const userInfoList = (params) => post('/public/users/list', params) // 获取用户列表

export const scene_check = (params) => post('/public/sceneManager/check', params) // 验证场景密码

export const checkPsd = (params) => post('/public/sceneManager/checkPsd', params) // 验证场景是否需要密码

export const registerPsd = (params) => post('/public/users/registerPsd', params) // 密码注册
