import Vue from 'vue'
import Router from 'vue-router'
import Vuex from './store'

Vue.use(Router)

function getParmas(url) {
  if (url.includes('?')) {
    var temp1 = url.split('?')
    var pram = temp1[1]
    var keyValue = pram.split('&')
    var obj = {}
    for (var i = 0; i < keyValue.length; i++) {
      var item = keyValue[i].split('=')
      var key = item[0]
      var value = item[1]
      obj[key] = value
    }
    return obj
  } else return null
}

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/login',
    component: () => import('../../views/login.vue'),
    meta: { title: '登录' },
    name: 'login'
  },
  {
    path: '/index',
    component: () => import('../../views/index.vue'),
    meta: { title: '首页' },
    name: 'index'
  }
]

const router = new Router({
  routes
})

router.beforeEach(async (to, from, next) => {
  //将所有需要登陆才显示的页面的路由都放进一个数组
  try {
    //获取登陆状态
    const hasToken = await Vuex.dispatch('getToken')

    const nextRoute = []
    routes.map((m, i) => {
      if (i !== 0) {
        nextRoute.push(m.path)
      }
    })

    if (to.path === '/login') {
      if (!hasToken) {
        //如果是登录页，则跳过验证
        next() //必不可少
        return //以下的代码不执行
      } else {
        //判断登陆状态
        next({
          path: '/index',
          replace: true
        }) //如果未登录，则跳转到登录页\
        return
      }
    }

    if (nextRoute.indexOf(to.path) >= 0) {
      //判断该页面是否需要登陆
      if (getParmas(location.href)?.sceneId) {
        Vuex.commit('changeScene', parseInt(getParmas(location.href)?.sceneId))
      }
      if (!hasToken) {
        //判断登陆状态
        next({
          path: '/login',
          replace: true
        }) //如果未登录，则跳转到登录页
      } else {
        next()
      }
    } else {
      //其他的无需登陆的页面不做验证
      next({
        path: '/login',
        replace: true
      })
    }
  } catch (error) {
    console.log('' + error)
  }
})

export default router
