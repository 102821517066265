<template>
  <div>
    <XyzTransition xyz="fade">
      <div class="openmodal-shadow" v-show="$store.state.openModalShow" @click="close">
        <div
          class="openmodal"
          @click.stop=""
          :style="{
            'text-align': $store.state.openModalShowInfo.function === 'agree' ? 'left' : 'center'
          }"
        >
          <div class="openmodal-span">{{ $store.state.openModalShowInfo.title }}</div>

          <div class="openmodal-btns">
            <button
              class="openmodal-btn"
              :class="{
                cancel: hover.cancel
              }"
              @click="close"
              @touchstart="hover.cancel = true"
              @touchend="hover.cancel = false"
            >
              {{ $store.state.openModalShowInfo.cancel }}
            </button>
            <button
              class="openmodal-btn openmodal-btn2"
              :class="{
                agree: $store.state.openModalShowInfo.function === 'agree',
                agreeclick: $store.state.openModalShowInfo.function === 'agree' && hover.ok,
                other: $store.state.openModalShowInfo.function !== 'agree',
                otherclick: $store.state.openModalShowInfo.function !== 'agree' && hover.ok
              }"
              @click="functions($store.state.openModalShowInfo.function)"
              @touchstart="hover.ok = true"
              @touchend="hover.ok = false"
            >
              {{ $store.state.openModalShowInfo.ok }}
            </button>
          </div>
        </div>
      </div>
    </XyzTransition>
  </div>
</template>

<script>
import { edit } from '@assets/js/api'

export default {
  name: 'loginTips',

  data() {
    return {
      hover: {
        ok: false,
        cancel: false
      }
    }
  },

  mounted() {},

  methods: {
    functions(fun) {
      this[fun]()
    },

    agree() {
      this.$store.commit('changeAgree', true)
      this.$store.commit('changeOpenModalShow', false)
    },
    close() {
      this.$store.commit('changeCenterShow', false)
      this.$store.commit('changeOpenModalShow', false)
    },
    logout() {
      this.$store.commit('changeOpenModalShow', false)
      setTimeout(() => {
        this.$store.commit('clearToken')
      }, 200)
    },
    async confirm() {
      try {
        if (document.o2.role) {
          try {
            let config = { id: this.$store.state.chat.role.id }

            config.black = this.$store.state.currentAvatarSelect
            config.info = window.btoa(
              JSON.stringify({
                modelIdx: this.$store.state.currentAvatarSelect
              })
            )

            config.name = this.$store.state.name

            const res = await edit(config)

            if (res.code == 0) {
              let myInfo = { ...this.$store.state.chat.role }

              myInfo.black = this.$store.state.currentAvatarSelect

              myInfo.name = this.$store.state.name

              const changeInfo = `${this.$store.state.chat.connectInfo.CMD_SET_PLAYER},${
                this.$store.state.name
              },${window.btoa(
                JSON.stringify({
                  modelIdx: this.$store.state.currentAvatarSelect
                })
              )}`

              this.$store.commit('chat/send', changeInfo)
              this.$store.commit('chat/setRole', myInfo)
              this.$message.success('修改成功')

              console.log(this.$store.state.chat.role)
            } else {
              throw res.msg ? res.msg : 'edit code error'
            }
          } catch (error) {
            throw 'edit: ' + error
          }
        } else {
          console.log('%cdocument.o2.role not found', 'color:red')
          throw 'document.o2.role not found'
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    async save() {
      await this.confirm()
      this.$store.commit('changeCenterShow', false)
      this.$store.commit('changeOpenModalShow', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.openmodal-shadow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba($color: #000000, $alpha: 0.7);

  .openmodal {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: calc(50% - 356px / 2);
    width: 356px;
    top: calc(46% - 80px);
    height: 177px;
    background: linear-gradient(to bottom, #0e253f, #0e3f3f);
    border: 1px solid rgba(57, 198, 176, 0.7);
    border-radius: 12px;

    .openmodal-span {
      margin: 32px 14px 0 14px;
      color: #fff;
      font-size: 16px;
    }

    .openmodal-btns {
      position: absolute;
      left: 14px;
      bottom: 14px;
      width: calc(100% - 28px);
      display: flex;

      .openmodal-btn {
        flex: 1;
        border: none;
        border-radius: 8px;
        height: 42px;
        font-size: 14px;
        background: rgba($color: #fff, $alpha: 0.2);
        color: #fff;
      }

      .openmodal-btn2 {
        margin-left: 16px;
      }

      .cancel {
        background: rgba($color: #fff, $alpha: 0.08);
      }

      .agree {
        background: linear-gradient(to left, rgba(35, 111, 198, 0.6), rgba(57, 198, 176, 0.6));
      }

      .agreeclick {
        background: linear-gradient(to left, #236fc6, #39c6b0);
      }

      .other {
        background: #39c6b0;
      }

      .otherclick {
        background: #4aa294;
      }
    }
  }
}

// 移动端 竖屏
@media screen and (max-width: 767px) and (max-height: 999px) and (orientation: portrait) {
  .openmodal-shadow {
    .openmodal {
      left: calc(50% - 347px / 2);
      width: 347px;
    }
  }
}
</style>
