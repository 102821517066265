const basicConfig = {
  // websocket: 'wss://api.wh12345.net:9970', // 测试环境
  websocket: 'wss://api.libraverse.cn:9000', // 生产环境
  roomId: 100, // 必须为 number 类型
  roomArr: {
    phoenix: [102, 103, 104, 105, 106, 107]
  }, // 语音房间号列表
  clientType: 'o2report2',
  // api: 'https://api.wh12345.net/libraverse/', // 测试环境
  api: 'https://api.libraverse.cn/', // 生产环境
  // api: '/dianxin',
  timeout: 10000, // 请求超时
  contentType: 'application/x-www-form-urlencoded; charset=UTF-8'
}

const gifCfg = {
  height: 0,
  time: 3000,
  gifUrls: [
    'https://o2public.oss-cn-beijing.aliyuncs.com/电信土壤/点赞.zip',
    'https://o2public.oss-cn-beijing.aliyuncs.com/电信土壤/鼓掌.zip',
    'https://o2public.oss-cn-beijing.aliyuncs.com/电信土壤/反对.zip'
  ]
}

const sceneConfig = {
  sceneCurrentIndex: 0, // 当前场景索引
  currentSound: 0, // 当前music下sound索引
  initConfig: {
    containerId: 'container',
    sceneList: [
      {
        sceneId: 'home',
        sceneName: 'BIBF主题岛',
        open: true, // 是否开放
        url:
          'https://o2res.oss-cn-beijing.aliyuncs.com/%E7%94%B5%E4%BF%A1/Main_Island.zip?OSSAccessKeyId=LTAI4G4zQbTdurvEbr1rR9zS&Expires=36001684118790&Signature=uulnCjTgJW0aErPowAi4ogqRQ4A%3D',
        cameraTarget: [-5840, 1500, -2817],
        cameraPosition: [-5808, 1704, 1464],
        rolePosition: [-5840, 0, -2817],
        ground_height: -10000,
        maxDistance: 5000, // 相机最远距离
        minDistance: 1000, // 相机最近距离
        moveSpeed: 3000, // 人物默认移速
        view_length: 3000, // 摄像机碰撞检测距离
        fov: 70,
        bLookAtForward: true, // 朝向是否自动跟随镜头
        roomId: 100,
        src: require('@assets/img/bibf.png'),
        music: {
          bgm:
            'https://o2public.oss-cn-beijing.aliyuncs.com/电信土壤/Audio/BIBF主岛音效/bibf主岛背景音效.mp3',
          sound: [
            {
              name: '发布广场',
              bgm:
                'https://o2public.oss-cn-beijing.aliyuncs.com/电信土壤/Audio/BIBF主岛音效/主岛发布广场鸟语花香环境声.mp3'
            }
          ]
        }
      },
      {
        sceneId: 'meeting1',
        sceneName: '传统文化岛',
        open: true, // 是否开放
        url:
          'https://o2res.oss-cn-beijing.aliyuncs.com/%E7%94%B5%E4%BF%A1/Culture_Island.zip?OSSAccessKeyId=LTAI4G4zQbTdurvEbr1rR9zS&Expires=36001684724976&Signature=UJj%2FzJwWk4541HkiFDY%2F1GBkb%2BU%3D',
        cameraTarget: [10647, 1500, 28327],
        cameraPosition: [11587, 1638, 31985],
        rolePosition: [10647, 0, 28327],
        ground_height: -50000,
        maxDistance: 5000, // 相机最远距离
        minDistance: 1000, // 相机最近距离
        moveSpeed: 3000, // 人物默认移速
        view_length: 3000, // 摄像机碰撞检测距离
        fov: 70,
        bLookAtForward: true, // 朝向是否自动跟随镜头
        roomId: 101,
        id: 8,
        src: require('@assets/img/custom.png'),
        music: {
          bgm:
            'https://o2public.oss-cn-beijing.aliyuncs.com/电信土壤/Audio/传统文化岛/传统文化岛背景音乐.mp3',
          sound: [
            {
              name: '流水',
              bgm:
                'https://o2public.oss-cn-beijing.aliyuncs.com/电信土壤/Audio/传统文化岛/传统文化岛流水声.mp3'
            }
          ]
        }
      },
      {
        sceneId: 'old',
        sceneName: '红色党建岛',
        open: true, // 是否开放
        url:
          'https://o2res.oss-cn-beijing.aliyuncs.com/%E7%94%B5%E4%BF%A1/Red_Island.zip?OSSAccessKeyId=LTAI4G4zQbTdurvEbr1rR9zS&Expires=36001684118814&Signature=ZYTiv7840vERwZrDDBsayb1YVRM%3D',
        cameraTarget: [-21368, 962, -18029],
        cameraPosition: [-22027, 1188, -13093],
        rolePosition: [-21368, -762, -18029],
        ground_height: -50000,
        maxDistance: 5000, // 相机最远距离
        minDistance: 1000, // 相机最近距离
        moveSpeed: 3000, // 人物默认移速
        view_length: 3000, // 摄像机碰撞检测距离
        fov: 70,
        bLookAtForward: true, // 朝向是否自动跟随镜头
        roomId: 102,
        id: 5,
        src: require('@assets/img/dangj.png'),
        music: {
          bgm:
            'https://o2public.oss-cn-beijing.aliyuncs.com/电信土壤/Audio/党建岛/党建岛背景音乐.mp3'
        }
      },
      {
        sceneId: 'art',
        sceneName: '少儿通识岛',
        open: true, // 是否开放
        url:
          'https://o2res.oss-cn-beijing.aliyuncs.com/%E7%94%B5%E4%BF%A1/Child_Island.zip?OSSAccessKeyId=LTAI4G4zQbTdurvEbr1rR9zS&Expires=36001684289158&Signature=eGZBM2cDEmsFui4dv7qPt1gUP48%3D',
        cameraTarget: [-15384, 2103, 9903],
        cameraPosition: [-19412, 2194, 10491],
        rolePosition: [-15384, 603, 9903],
        ground_height: -50000,
        maxDistance: 5000, // 相机最远距离
        minDistance: 1000, // 相机最近距离
        moveSpeed: 3000, // 人物默认移速
        view_length: 3000, // 摄像机碰撞检测距离
        fov: 70,
        bLookAtForward: true, // 朝向是否自动跟随镜头
        roomId: 103,
        id: 6,
        src: require('@assets/img/shaoer.png'),
        music: {
          bgm:
            'https://o2public.oss-cn-beijing.aliyuncs.com/电信土壤/Audio/少儿岛音效/少儿背景音乐.mp3'
        }
      },
      {
        sceneId: 'exhibition',
        sceneName: '科幻漫画岛',
        open: true, // 是否开放
        url:
          'https://o2res.oss-cn-beijing.aliyuncs.com/%E7%94%B5%E4%BF%A1/Science_Island.zip?OSSAccessKeyId=LTAI4G4zQbTdurvEbr1rR9zS&Expires=36001685080919&Signature=%2BtjziAG5NmobB2B6HeZdpqqC5fg%3D',
        cameraTarget: [0, -7496, 10988],
        cameraPosition: [0, -7475, 14261],
        rolePosition: [0, -9136, 10988],
        ground_height: -10000,
        maxDistance: 5000, // 相机最远距离
        minDistance: 1000, // 相机最近距离
        moveSpeed: 3000, // 人物默认移速
        view_length: 3000, // 摄像机碰撞检测距离
        fov: 70,
        bLookAtForward: true, // 朝向是否自动跟随镜头
        roomId: 104,
        id: 7,
        src: require('@assets/img/kehuan.png'),
        music: {
          bgm:
            'https://o2public.oss-cn-beijing.aliyuncs.com/电信土壤/Audio/科幻岛/科幻岛科幻背景音乐.wav',
          sound: [
            {
              name: '科幻岛舱内环环境声',
              bgm:
                'https://o2public.oss-cn-beijing.aliyuncs.com/电信土壤/Audio/科幻岛/科幻岛舱内环环境声.mp3'
            }
          ]
        }
      },
      {
        sceneId: 'army',
        sceneName: '军事主题岛',
        open: false, // 是否开放
        url:
          'https://o2res.oss-cn-beijing.aliyuncs.com/%E7%94%B5%E4%BF%A1/%E7%94%B5%E4%BF%A1%E5%9D%A6%E5%85%8B.zip?OSSAccessKeyId=LTAI4G4zQbTdurvEbr1rR9zS&Expires=36001685005815&Signature=QuStTipwFpOTMYNX9d3p0Pawh38%3D',
        cameraTarget: [13127, 1500, 1505],
        cameraPosition: [19743, 1602, 2094],
        rolePosition: [13127, 0, 1505],
        ground_height: -50000,
        maxDistance: 5000, // 相机最远距离
        minDistance: 1000, // 相机最近距离
        moveSpeed: 3000, // 人物默认移速
        view_length: 3000, // 摄像机碰撞检测距离
        fov: 70,
        bLookAtForward: true, // 朝向是否自动跟随镜头
        roomId: 105,
        id: 7,
        src: require('@assets/img/junbo2.png'),
        intro: `军事主题岛屿主要以军事题材为主。整个岛屿以功臣号坦克为原型设计，通过陈列可交互的有纪念意义的坦克、飞机、枪炮等模型，让读者可操可感的沉浸在军事历史文化中。`
      },
      {
        sceneId: 'x1',
        sceneName: '图书馆岛',
        open: false, // 是否开放
        url:
          'https://o2res.oss-cn-beijing.aliyuncs.com/%E7%94%B5%E4%BF%A1/%E7%94%B5%E4%BF%A1%E5%9D%A6%E5%85%8B.zip?OSSAccessKeyId=LTAI4G4zQbTdurvEbr1rR9zS&Expires=36001685005815&Signature=QuStTipwFpOTMYNX9d3p0Pawh38%3D',
        cameraTarget: [13127, 1500, 1505],
        cameraPosition: [19743, 1602, 2094],
        rolePosition: [13127, 0, 1505],
        ground_height: -50000,
        maxDistance: 5000, // 相机最远距离
        minDistance: 1000, // 相机最近距离
        moveSpeed: 3000, // 人物默认移速
        view_length: 3000, // 摄像机碰撞检测距离
        fov: 70,
        bLookAtForward: true, // 朝向是否自动跟随镜头
        roomId: 106,
        id: 7,
        src: require('@assets/img/国图岛-小图.png'),
        intro: `图书馆主题岛屿由一个主岛和三个附岛组成。读者通过乘坐飞翔的图书，可在不同的岛屿中参加社交活动、沉浸式阅读和文化论坛等。`
      },
      // {
      //   sceneId: 'x2',
      //   sceneName: '荣宝斋岛',
      //   open: false, // 是否开放
      //   url:
      //     'https://o2res.oss-cn-beijing.aliyuncs.com/%E7%94%B5%E4%BF%A1/%E7%94%B5%E4%BF%A1%E5%9D%A6%E5%85%8B.zip?OSSAccessKeyId=LTAI4G4zQbTdurvEbr1rR9zS&Expires=36001685005815&Signature=QuStTipwFpOTMYNX9d3p0Pawh38%3D',
      //   cameraTarget: [13127, 1500, 1505],
      //   cameraPosition: [19743, 1602, 2094],
      //   rolePosition: [13127, 0, 1505],
      //   ground_height: -50000,
      //   maxDistance: 5000, // 相机最远距离
      //   minDistance: 1000, // 相机最近距离
      //   moveSpeed: 3000, // 人物默认移速
      //   view_length: 3000, // 摄像机碰撞检测距离
      //   fov: 70,
      //   bLookAtForward: true, // 朝向是否自动跟随镜头
      //   roomId: 107,
      //   id: 7,
      //   src: require('@assets/img/荣宝斋-小图.png'),
      //   intro: `荣宝斋主题岛屿由传统文化元素组成。读者在古色古香的建筑中体验跨时空漫游，可在不同画风的岛屿中沉浸式观赏中国文化历史和参与线上文化活动等。`
      // },
      {
        sceneId: 'x3',
        sceneName: '科技主题岛',
        open: false, // 是否开放
        url:
          'https://o2res.oss-cn-beijing.aliyuncs.com/%E7%94%B5%E4%BF%A1/%E7%94%B5%E4%BF%A1%E5%9D%A6%E5%85%8B.zip?OSSAccessKeyId=LTAI4G4zQbTdurvEbr1rR9zS&Expires=36001685005815&Signature=QuStTipwFpOTMYNX9d3p0Pawh38%3D',
        cameraTarget: [13127, 1500, 1505],
        cameraPosition: [19743, 1602, 2094],
        rolePosition: [13127, 0, 1505],
        ground_height: -50000,
        maxDistance: 5000, // 相机最远距离
        minDistance: 1000, // 相机最近距离
        moveSpeed: 3000, // 人物默认移速
        view_length: 3000, // 摄像机碰撞检测距离
        fov: 70,
        bLookAtForward: true, // 朝向是否自动跟随镜头
        roomId: 108,
        id: 7,
        src: require('@assets/img/科技馆-小图.png'),
        intro: `科技岛屿以科技文化内容为主。通过展示不同历史时期有代表性的科技产物、有趣的交互体验，让读者沉浸在科学技术的宇宙中，体验科学技术的独特魅力。`
      },
      {
        sceneId: 'x4',
        sceneName: '山海经岛',
        open: false, // 是否开放
        url:
          'https://o2res.oss-cn-beijing.aliyuncs.com/%E7%94%B5%E4%BF%A1/%E7%94%B5%E4%BF%A1%E5%9D%A6%E5%85%8B.zip?OSSAccessKeyId=LTAI4G4zQbTdurvEbr1rR9zS&Expires=36001685005815&Signature=QuStTipwFpOTMYNX9d3p0Pawh38%3D',
        cameraTarget: [13127, 1500, 1505],
        cameraPosition: [19743, 1602, 2094],
        rolePosition: [13127, 0, 1505],
        ground_height: -50000,
        maxDistance: 5000, // 相机最远距离
        minDistance: 1000, // 相机最近距离
        moveSpeed: 3000, // 人物默认移速
        view_length: 3000, // 摄像机碰撞检测距离
        fov: 70,
        bLookAtForward: true, // 朝向是否自动跟随镜头
        roomId: 109,
        id: 7,
        src: require('@assets/img/山海经-小图.png'),
        intro: `山海经主题岛屿主要以《山海经》为蓝图，通过提炼《山海经》中的地点、植物、动物、怪兽、矿物、神话等，展现《山海经》中描述的上古世界观。`
      }
    ],
    roleConfig: {
      account_id: 0,
      name: 'Guest',
      info: {
        modelIdx: 5
      }
    }
  }
}

export default basicConfig
export { sceneConfig, gifCfg }
