const intro = {
  三岔口: `作品通过VR剧场的的形式，再现了一段传统京剧短打武生剧目：北宋年间，三关上将焦赞因打死奸臣谢金吾被发配沙门岛，杨延昭命任堂惠暗地里保护。押解焦赞行至三岔口，夜宿旅店，任堂惠赶至店中住下。入夜，店主刘利华为救焦赞而与任堂惠发生误会，在黑暗中产生了一场争斗......
 作品充分利用“追光”的舞台艺术形式，并借助VR叙事声色形兼备的独特优势，通过“焦赞发配、旅店夜斗、冰释前嫌”三段紧凑、激烈的剧情安排，逐一让京剧中的花脸、武生、武丑、武旦等细节生动的三维角色于观众面前亮相，最大限度展现出京剧中不同家门行当的特点，极尽京剧艺术的可看性和程式美。`,
  丝绸之路: `敦煌，中国文化史上的一座巨大宝库，还是古代社会生活的百科全书，仰之弥高，钻之弥坚。《敦煌：中国历史地理绘本》VR全景作品即是对这一文化瑰宝的立体再现。通过5G高清还原技术和CG动画的精彩演绎，与敦煌相关的那些震撼于世、流光溢彩的历史故事、人文气息、艺术魅力、地理风光“活”起来，为观众打造出“一眼入敦煌，大梦初方醒”的沉浸式体验，同时，全新的VR呈现方式，是促进了青少年儿童欣赏敦煌艺术之美、汲取敦煌文化精神营养的创新应用。其中：《敦煌：丝绸之路》VR全景作品将全景展示与小剧场演绎巧妙融合，以敦煌城门为中心，生动再现了丝绸之路的特色贸易商品和途径地区。同时，作品巧妙的将敦煌城门同时设为起点和终点，延伸出一条折返路线，借此突出敦煌当时作为贸易进口和出口要塞的经济意义。`,
  乃粒: `该项目以2021年桂冠童书《天工开物》为创作蓝本，通过手绘风格的3D中国风漫游动画和VR形式，全景再现了中国17世纪农业和手工业的丰富经验和先进工艺。作品借助一副充满中国手工器物元素的卷轴凸显主题，通过先古后今的叙事逻辑以及 “城市翻转”的巧妙设计，使内容和场景深度融合，在穿古越今的对比中为观众提供了中国传统文化与现代智慧融合的的沉浸式体验。其中：《天工开物：乃粒》 分述了古今粮食作物的种植技术，尤其是南方的水稻栽培以及多项农具的使用方法。`,
  光明: `“童心向党 百年辉煌”之《光明》是在中国共产党百年华诞之际特别推出的红色主题全景视频，讲述了中国年龄最小的烈士——“小萝卜头”宋振中的狱中生活。
  作品采用电影的叙事方式，开篇即将“我们”拉到主人公身边，作为“小萝卜头”的狱友“亲历”他的生活环境，将“临场感”发挥到极致；其次，通过小场景调度的方式，在牢房内外 “黑暗”与“光明”的强烈反差中，凸显狱中环境的逼仄压抑。作品结尾更通过“墙上的鸟儿活了起来”的细节改编，完成了主题的升华，让“儿童绘本”真正地“活”起来。`,
  壮族: `《了不起的中华服饰：壮族》VR体验项目以中国民族服饰研究会会长杨源教授编撰的“中华文化启蒙”通俗百科绘本为创作蓝本。作品将壮族服饰的细节展示和地域特色的全景展示相结合，以通俗易懂的语言介绍了壮族的历史、节日、风俗等知识，为孩子打造了沉浸式的民族文化认知体验。`,
  天眼: `“童心向党 百年辉煌”之《中国天眼》是在中国共产党百年华诞之际特别推出的红色主题全景视频。作品以九岁男孩“阿天”和工程师爷爷之间的互动为切入点，通过点线结合的叙事方式，再现了贵州省克度镇500米口径球面射电望远镜 “天眼”建设的艰辛过程。 
  作品在形式和内容上高度迎合了儿童的审美情趣和认知能力，在细腻叙事与沉浸体验结合过程中，把共产党人坚定的信仰和无私奉献的情怀浸润到每个孩子的心灵，为他们成长输送了宝贵养料。`,
  小放牛: `《小放牛》为昆曲中的吹腔曲牌，是一段载歌载舞的男女对唱。作品通过VR全景的表现方式，通过“村野邂逅、对唱问答、牧童发难”三个篇章再现了村姑向牧童问路，俏皮的牧童故意留难对答的精彩故事。其中，“村野邂逅”作为全片的引子，以载歌载舞的方式突出体现戏曲艺术“以歌舞演故事”的形式美；“对唱问答”作为全片高潮部分，以小剧场形式，体现出“戏中戏”的效果；“牧童发难”则以俏皮的喜剧形式，着重展现了轻松、幽默的喜剧氛围。整部作品在演绎过程中，为观众提供了极富变化的视觉冲击和场景体验，实现了知识输出、寓教于乐的目的。`,
  彝族: `《了不起的中华服饰：彝族》VR体验项目以中国民族服饰研究会会长杨源教授编撰的“中华文化启蒙”通俗百科绘本为创作蓝本。作品通过民族历史、社会生产、节庆习俗综合展现了彝族服饰的丰富内涵，为孩子近距离感受中华民族文化的博大精深、绚烂多彩提供了生动素材。`,
  彩绘壁画: `《敦煌：彩绘壁画》VR全景作品通过氛围起稿、上色、描线三个步骤展示了彩绘壁画九色鹿的绘制过程，凸出了中国传统“粉本法”的应用特点，并通过生长动画的形式，逐渐展示出敦煌壁画古朴大气却又色彩绚丽的艺术风格。`,
  彰施: `该项目以2021年桂冠童书《天工开物》为创作蓝本，通过手绘风格的3D中国风漫游动画和VR形式，全景再现了中国17世纪农业和手工业的丰富经验和先进工艺。作品借助一副充满中国手工器物元素的卷轴凸显主题，通过先古后今的叙事逻辑以及 “城市翻转”的巧妙设计，使内容和场景深度融合，在穿古越今的对比中为观众提供了中国传统文化与现代智慧融合的的沉浸式体验。其中：《天工开物：彰施》分述了古今面料的染色技术，并对靛蓝和红花的提取做了重点叙`,
  杀青: `该项目以2021年桂冠童书《天工开物》为创作蓝本，通过手绘风格的3D中国风漫游动画和VR形式，全景再现了中国17世纪农业和手工业的丰富经验和先进工艺。作品借助一副充满中国手工器物元素的卷轴凸显主题，通过先古后今的叙事逻辑以及 “城市翻转”的巧妙设计，使内容和场景深度融合，在穿古越今的对比中为观众提供了中国传统文化与现代智慧融合的的沉浸式体验。其中：《天工开物：杀青》分述了古今的造纸技术，尤其对竹纸的工序、设备作了论述。`,
  珠玉: `该项目以2021年桂冠童书《天工开物》为创作蓝本，通过手绘风格的3D中国风漫游动画和VR形式，全景再现了中国17世纪农业和手工业的丰富经验和先进工艺。作品借助一副充满中国手工器物元素的卷轴凸显主题，通过先古后今的叙事逻辑以及 “城市翻转”的巧妙设计，使内容和场景深度融合，在穿古越今的对比中为观众提供了中国传统文化与现代智慧融合的的沉浸式体验。其中：《天工开物：珠玉》分述了古今采珠、采玉及其加工方法。`,
  瑶族: `《了不起的中华服饰：瑶族》VR体验项目以中国民族服饰研究会会长杨源教授编撰的“中华文化启蒙”通俗百科绘本为创作蓝本。从瑶族服饰切入，见微知著，以小见大，通过科普性细节还原、沉浸式场景再现，全景展现了与瑶族紧密相关的历史、地理、音乐、艺术、民俗、节庆、民居等内容。`,
  红船红船: `“童心向党 百年辉煌”之《红船，红船》是在中国共产党百年华诞之际特别推出的红色主题全景视频。作品从中国共产党梦想起航的地方出发，通过孩子视角的引导和“守船人”的回忆，讲述了中国共产党在嘉兴南湖小船上完成第一次全国代表大会议程，宣告中国共产党成立的感人故事。作品在内容呈现上首次尝试将手绘艺术、二维线稿动画形式与“5G+VR”技术融为一体，在现实与回忆的交融中，彰显了中国共产党人开天辟地、敢为人先的首创精神，坚定理想、百折不挠的奋斗精神，立党为公、忠诚为民的奉献精神。`,
  绿色的塞罕坝: `塞罕坝，一个拥有世界最大的人工森林的天然名苑。谁能想到，这片奇迹绿洲在上世纪60年代却是只有一棵树的沙地荒原。从黄沙漫漫到绿意葱茏，从“一棵树”到“一片海”，是塞罕坝几代务林人用青春热血铸就的“绿色长城”。
  作品在讲述三代塞罕坝务林人建林、护林故事的基础上，通过“5G”技术把时光“逆转”至上世纪，让观众参与到塞罕坝务林人的绿洲重建工作中，见证书写绿色传奇的艰辛过程，重温“终于使命、艰苦创业、科学求实、绿色发展”的塞罕坝精神。`,
  苗族: `作品根据中国民族服饰研究会会长杨源教授编撰的“中华文化启蒙”通俗百科绘本系列《了不起的中华服饰》改编而成，再现了苗族的服饰文化，涵盖历史、音乐、艺术、民俗、节庆等丰富内容。观众通过VR，可以收获带银饰、穿苗服、过苗年的沉浸式体验，进一步加深对祖国文化多样性和各民族共同创造灿烂中华文明的理解。`,
  贵妃醉酒: `5G新出版《贵妃醉酒》，面向青少年读者，依据中国人民大学出版社《“戏开始了”京剧经典美绘系列》而创作。以3D场景建模复刻的东苑戏楼为背景舞台，以原著中可爱灵动的Q版人物形象为主角，融入诙谐幽默的对白和优美唱段，并加入角色行当、服饰道具等专业戏曲知识，使传统平面绘本创新升级为5G全景新出版作品，在科技与国粹碰撞出的火花中，带给读者寓教于乐的戏曲文化盛宴。`,
  雅丹地貌: `《敦煌：雅丹地貌》VR全景作品采用一镜到底的形式，从湖面下潜到湖底，完整呈现雅丹地貌的形成过程，同时，通过大小场景对比和独特的VR视角最大限度实现观众的“下沉”体验、表现了沙漠的广袤无边。`,
  中华传统节日七夕: `该作品紧扣《中国传统文化绘本·中华传统节日》中“七夕节”的经典故事，通过手绘风格的3D中国风动画、二维分层动画以及VR叙事相结合的全新方式为儿童讲述了牛郎织女的爱情传说。作品开篇在众多女子月下乞巧的场景中引出七夕传说，将现世和传说两个时空维度自然过渡；主体部分通过唯美的人文意境，展现了牛郎织女相见相知相爱的温馨和被迫分开的强烈戏剧冲突；结尾与开头时空呼应，再次回到七夕节当晚，以交相辉映、两两相望的牛郎、织女星定格，给人留下无限遐想。在完整流畅、充满趣味的故事中，七夕观星、穿针乞巧、晒书、晒衣以及吃巧果等七夕风俗予以鲜活地展现，不仅为为当代少年儿童深刻理解“七夕节”美好的情感愿景、深厚的文化底蕴并增强文化认同提供生动素材，还为更多、中华优秀传统文化创造性转化和创新性发展提供了全新思路。`,
  中华传统节日春节: `该作品以《中国传统文化绘本·中华传统节日》中的原创神话故事“春节”为创作蓝本，用手绘风格的3D中国风动画、二维分层动画以及VR叙事相结合的全新方式为儿童讲述了春节的由来历史和风俗习惯。“春节是怎么来的？”“为何要到处挂满红布条和红灯笼”“为何要放鞭炮”这些让孩子好奇的问题在生动的故事中被一一解答。作品饱满丰富的画面、生动可爱的人物形象为孩子打造了一场充满震撼力的传统文化体验，让孩子在潜移默化中培养审美意识，能够读懂、讲述真正的中国故事。`,
  华山云海图: `华山自古以险峻秀美著称，奇峰林立如刀削斧劈，云海微茫似波涛翻涌，亭台楼阁点缀其中使人神往。荣宝斋文化发展与中图云创合作推出的这幅《华山云海图》5G全景画卷，运用超高清3D全景技术和独特的VR叙述脉络，将空间与时间艺术完美结合。观众置身画中世界，可体验沉浸式和流动式的中国山水长卷。5G+VR技术，将画坛巨匠张大千笔下华山万千瑰丽的景象，鲜活地再现于虚拟空间之中，画家以金线勾勒山形的“金碧山水”技法，也首次超越纸本，虚实相映，打造出充满古意而又崭新的超时空文化体验。`,
  永乐大典: `“5G全景文化典籍”是以“5G+VR”集成4K/8K全景视频拍摄、三维动画制作等技术手段，对中国国家图书馆的珍贵馆藏，进行数字化、科技化、全景化的追溯与再现，并制作完成适用于大屏、中屏、小屏多终端的“传统文化系列全景视频作品”。《永乐大典》——《旷世宏编 文献大成》聚焦中国典籍史上的灿烂瑰宝《永乐大典》，从典藏价值、修典始末、典籍渊薮、韵字编排、品貌非凡、传承守望六大部分，对神秘的古代典籍进行多线索、多角度的现代化眩目视觉解读，深入浅出地梳理和解析《永乐大典》的文化底蕴、历史脉络和学术价值。追溯文脉，深奥的文献珍品不再遥远和陌生；跨越时空，沉寂数百年的书香古韵鲜活地与读者对话。`,
  燕京岁时记: `《燕京岁时记》VR全景作品以首都图书馆《北京风俗图谱》《耕织图》等内容为创作蓝本，采用全景视频拍摄以及三维动画制作等多种技术手段再现了老北京春耕夏种、秋收冬藏的田园牧歌式的生活方式。同时，3D技术复刻的场景为观众提供了穿越时空，“漫步”老北京的沉浸式体验：白墙黛瓦的特色建筑、琳琅满目的风味小吃、丰富多彩的民俗活动，熙攘生动的街巷市集，让那些已经消失或即将消失的城市记忆重新焕发生机。作品始于热闹的迎亲队伍，终于喜庆的春节庙会，在高潮中收尾、尽显四季轮回归于春的崭新希望。`,
  皆山原图卷: `《皆山园图卷》由清代著名画家王原祁、王敬铭所作，青山蔼蔼，碧水粼粼，飞瀑直泄，鸟儿欢鸣，行人穿梭其中，悠然自得，仿若世外桃源。全景版《皆山园图卷》由荣宝斋出版社与中图云创联合打造，运用5G+VR技术，让观者穿越时空，在虚拟实境中，置身“可行、可望、可游、可居”的中国山水画作，感受“有声、有色、有全景”的超高清沉浸式视听体验。`,
  '顾闳中与韩熙载夜宴图(上)': `《韩熙载夜宴图》VR体验项目分别从“夜罢聆音、击鼓伴舞、画屏小栖、玉人清吹、夜阑余兴”五个场景再现了传世画作的恢弘全景。开篇，观众从水晶球进入画中世界，走进这场盛大的夜宴。作为夜宴的参与者，观众逐一了解到宾客的关系与背景，体验到钟鸣鼎食、觥筹交错的奢靡生活。除了逼真的夜宴全景呈现，作品还借助VR叙事方式，从文史与艺术的角度对作品进行了深度剖析，折射出时代的特色风貌、展露了夜宴背后暗流涌动的政治风云，让历史走出画卷“活”起来。结尾，韩熙载将“观众”作为最后一位客人送出画卷，重新回到现实。至此，流畅的节奏和完整的故事脉络设置，为观众留下无限深思与回味。`,
  '顾闳中与韩熙载夜宴图(下)': `《韩熙载夜宴图》VR体验项目分别从“夜罢聆音、击鼓伴舞、画屏小栖、玉人清吹、夜阑余兴”五个场景再现了传世画作的恢弘全景。开篇，观众从水晶球进入画中世界，走进这场盛大的夜宴。作为夜宴的参与者，观众逐一了解到宾客的关系与背景，体验到钟鸣鼎食、觥筹交错的奢靡生活。除了逼真的夜宴全景呈现，作品还借助VR叙事方式，从文史与艺术的角度对作品进行了深度剖析，折射出时代的特色风貌、展露了夜宴背后暗流涌动的政治风云，让历史走出画卷“活”起来。结尾，韩熙载将“观众”作为最后一位客人送出画卷，重新回到现实。至此，流畅的节奏和完整的故事脉络设置，为观众留下无限深思与回味。`,
  攻克锦州_攻克锦州: `锦州战役是1948年10月在辽沈战役战役第一阶段中，东北人民解放军对国民党军坚固设防的锦州进行的攻坚战役。锦州攻坚战共歼灭国民党军10余万人，使国民党军在东北的防御体系完全被打乱，对辽沈战役的胜利起决定性作用。《攻克锦州》VR体验项目即是对这一重大历史题材的全景再现。作品通过5G技术打破时空壁垒，让观众亲历昔日炮火硝烟的战场、见证决战胜利的光辉时刻！`,
  歌声中的百年风华_万水千山: `《歌声中的百年风华：万水千山》本集采用“雕塑”这一艺术形式作为主视觉引导，依次通过《突破封锁线》《四渡赤水出奇兵》《义勇军进行曲》回顾了长征途中的几次重要战斗，再现了红军战士在革命征程中的钢铁意志。同时，通过3D技术还原宏大、震撼的战斗场景，为当代人沉浸式体悟“长征精神”提供了生动、有效的素材。`,
  歌声中的百年风华_中流砥柱: `本集作品采用“油画”这一艺术形式作为主视觉引导，依次通过《义勇军进行曲》《八路军军歌》《没有共产党就没有新中国》讲述了中国共产党面对民族危亡，克服重重困难、领导抗日战争取得胜利的艰苦历程。作品宏大的历史背景通过全景视角中立体声、色、形的加持，让历史事件和纸本上的音符“活起来”，实现了形式美与主题美的完美融合，为当代人身临其境追寻红色记忆提供了创新思路。`,
  歌声中的百年风华_奋发图强: `《歌声中的百年风华—奋发图强》全景体验项目参考权威党史图文资料，通过三维生长动画创意展示出新中国成立后，我国经历改革开放，进入新世纪新阶段在农业、工业、交通、文化、科技等方面取得的辉煌成就。同时，伴随流动的音符特效，依次通过《我们走在大路上》《春天的故事》《走进新时代》《江山》四首耳熟能详的歌曲串联，展现出我党面对世情、国情、党情变化，领导人民奋发图强建设社会主义的精神面貌以及在执政能力建设和先进性建设方面取得的理论成果。独特的VR语言和先进的3D制作技术，为观众身临其境感受党史峥嵘岁月提供创新内容载体，也为新时代党史学习增添深度、温度和力度。`,
  歌声中的百年风华_开天辟地: `《歌声中的百年风华》是以中国音乐家协会、中国人民音乐出版社、中共党史出版社联袂编著的《中国共产党历史歌典——歌声中的百年风华》为蓝本，在中国共产党建党百年之际开发制作的六集全景视频。作品“以歌叙史、以史串歌”，集思想性与艺术性于一体，融党史与音乐于一身，通过具有划时代意义的经典革命旋律，生动再现中国共产党矢志践行初心使命、筚路蓝缕奠基立业的百年历史，让党史学习教育“声入人心”。`,
  歌声中的百年风华_星火燎原: `《歌声中的百年风华：星火燎原》本集采用“黑白连环画”的形式作为主视觉引导，充分发挥VR语言的宏大叙事优势，通过《共产儿童团歌》《娘子军连歌》《五月的花朵》三首耳熟能详的歌曲将少年儿童、妇女和革命志士三个不同群体串联，全景展现了在中国革命史上无数仁人志士前仆后继，通过顽强抗争，使我党的星星之火最终成为了燎原之势的奋斗历程。`,
  永恒的初心_开天辟地: `中国共产党成立是开天辟地的大事变，深刻改变了近代以后中华民族发展的方向和进程，深刻改变了中国人民和中华民族的前途和命运，深刻改变了世界发展的趋势和格局。《永恒的初心》之《开天辟地》VR体验项目是在中国共产党建党百年之际特别推出的红色主题全景视频，旨在通过全新的方式讲好红色故事，传达红色精神。作品通过“5G+VR”技术打造“沉浸式的”场景体验，观众不仅能重回中共一大现场，见证中国共党的“伟大开端”，还能亲临“北伐”战场前线，聆听雄壮而嘹亮的胜利号角！`,
  永恒的初心_征途漫漫: `《永恒的初心·征途漫漫》VR作品是中国共产党百年华诞之际特别推出的红色主题全景视频之一，再现了中央红军从出发长征到胜利抵达陕甘革命根据地的伟大历史。
  作品将“5G+VR”与先进的三维动画渲染和制作技术相融合，生动呈现了长征途中红军过雪山、草地，缺医少药的艰难经历，全景还原了“飞夺泸定桥”“巧度金沙江”等关键战役的恢弘场面，展现出红军战士的智慧。`,
  永恒的初心_星火燎原: `秋收起义后，毛泽东率领起义军向敌人统治力量薄弱的农村转移，创建了中国历史上第一个农村革命根据地——井冈山革命根据地，点燃了中国革命的星星之火。随后，全国十几块革命根据地像雨后春笋般地相继建立，形成燎原之势。《永恒的初心》之《星火燎原》VR体验项目即是对这一历史时期的全景再现。作品将生动的革命故事与现代科技技术融为一体，让观众“沉浸”畅游红色革命根据地的同时，重温井冈山精神的精髓，感受中国共产党薪火相传的强大精神动力。`,
  永恒的初心_民族脊梁: `该作品再现了中国共产党通过创建敌后抗日根据地，积极开展敌后抗日游击战争，成为全面抗日战争的主战场和中流砥柱，最终赢得抗日战争胜利的光辉战斗历程。
  作品将二维分层动画和三维场景相结合，通过三维建模、声光电特效等多种技术手段，让观在在战争的临场感以及沉浸式的党史学习体验中重温共产党人跨越时空的初心使命。`,
  永恒的初心_浴血悲歌: `土地革命战争时期，面对蒋介石国民党反动派的大规模“围剿”，红一方面军在毛泽东、朱德、周恩来等领导和指挥下，取得了中央革命根据地连续四次反“围剿”的胜利。而后，王明 “左”倾教条主义错误致使中央革命根据地第五次反“围剿”失败，中央红军主力被迫举行战略转移，即举世闻名的长征。
  《永恒的初心·浴血悲歌》VR作品即是对这一历史时期的全景再现。“5G+VR”技术打造的“沉浸式”视听体验为为观众了解长征背景，学习党史、感怀红色江山来之不易提供了生动而有效的素材。`,
  永恒的初心_胜利属于人民: `该作品再现了中国人民解放军通过辽沈、淮海、平津三大战役，最终取得全国解放战争的历史征程。作品将“小剧场”演绎的战争与宏大的背景巧妙对接，使场景体验和故事讲述相得益彰。同时，流畅的VR叙事将中国共产党取得最终胜利的故事娓娓道来，让当代人获得情感共鸣，潜移默化中传续红色基因。`,
  红流星光_南方谈话: `《百年红流星光：南方谈话》VR全景视频是中图云创在中国共产党百年华诞之际，联合国家开放大学精心打造的红色专题课堂之一。授课内容以“南方谈话”为切入点，参考珍贵历史资料展现了邓小平同志改革开放的历史背景以及取得的丰硕成果。在课程讲解过程中，主讲人穿越时代洪流，带领观众重新回到邓小平同志南巡列车、国贸大厦等关键场景中，沉浸式感受伟人振聋发聩的时代强音。同时，作品还融入了邓小平故居、邓小平陈列馆和缅怀馆的全景实拍内容，在虚实结合中打造出丰富、生动的课堂学习体验。`,
  红流星光_开天辟地: `以党的百年发展历程为顺序，以党的重大事件为线索，以不同时期的典型事例、历史人物、精彩故事为主干，采用CG+4K/8K全景实拍制作标准，结合历史资料、真人讲师，全景式回顾党的伟大历程和辉煌成就。讲述者围绕每期主题做授课式讲演，围绕讲述者和话题节奏来制作全景内容，主讲人与场景环境深度融合，观看者沉浸式全景学习，人与场景深度融合。每期话题由巧妙的“摘星”动作引出，寓意：脚踏时代红流，肩顶历史星光。`,
  红流星光_遵义会议: `《百年红流星光：遵义会议》VR全景视频是中图云创在中国共产党百年华诞之际，联合国家开放大学精心打造的红色专题课堂之一。授课内容以中国共产党发展史上的关键事件“遵义会议”为主题，通过CG+4K/8K全景实拍技术将真人讲师授课和三维虚拟课堂相结合，生动还原了这一历史事件的始末。作品不仅有新颖的形式，还依次通过放映厅、遵义会场、长卷浮雕等三维意象极大地丰富了观众的视听体验，为新时代党史学习树立了生动范本。`,
  红色家书_亲情篇: `《红色家书》以老一辈革命家写给亲人的书信为载体，通过“明志”“正己”“亲情”“教子”四个主题讲述了革命年代的动人故事。VR技术打造的“沉浸式”场景，将纸上的家书还原成一段段刻骨铭心的记忆。《红色家书·亲情篇》以反映作者与家人之间温暖情感的书信为载体，全景展现了老一辈革命家的家庭建设风貌，为当代家风建设提供指引。`,
  红色家书_教子篇: `《红色家书》以老一辈革命家写给亲人的书信为载体，通过“明志”“正己”“亲情”“教子”四个主题讲述了革命年代的动人故事。VR技术打造的“沉浸式”场景，将纸上的家书还原成一段段刻骨铭心的记忆。《红色家书·教子篇》以反映老一代革命家教育后代理念的书信为载体，塑造了有血有肉的革命先烈形象，是当代人走进共产党人的精神家园、理解不忘初心的的重要史料。`,
  红色家书_明志篇: `《红色家书》以老一辈革命家写给亲人的书信为载体，通过“明志”“正己”“亲情”“教子”四个主题讲述了革命年代的动人故事。VR技术打造的“沉浸式”场景，将纸上的家书还原成一段段刻骨铭心的记忆。《红色家书·明志篇》以彰显作者坚定理想信念的家书为载体，通过VR技术还原老一辈革命家的品格风范，为新时代传承爱国好家风提供了生动素材。`,
  红色家书_正己篇: `《红色家书》以老一辈革命家写给亲人的书信为载体，通过“明志”“正己”“亲情”“教子”四个主题讲述了革命年代的动人故事。VR技术打造的“沉浸式”场景，将纸上的家书还原成一段段刻骨铭心的记忆。《红色家书·正己篇》以体现作者修身正己的严格要求的家书为载体，通过“沉浸式”的体验再现了共产党人为信仰而奋斗、为国家美好未来而牺牲的强烈愿望。`
}

export { intro }
