import Vue from 'vue'
import vue from '@/main'
import Vuex from 'vuex'
import router from './router'
import basicConfig, { sceneConfig, gifCfg } from './config'
import chat from './chat'
// import client from '../../../common/js/commonClient'
import { users, privates, aboutus, guide } from '@assets/config/xieyi.js'
import { intro } from '@assets/config/videoIntroduce.js'

Vue.use(Vuex)

// 数据
const state = {
  ...basicConfig, // 基础配置
  ...sceneConfig, // 场景配置
  ...gifCfg, // gif配置
  token: localStorage.getItem('dianxin_token') ? localStorage.getItem('dianxin_token') : '', // 登录凭证
  guestTip: false, // 游客登录提示
  openModalShow: false, // 通用提示
  openModalShowInfo: {
    title: '',
    ok: '',
    cancel: '',
    function: ''
  }, // 通用提示
  agree: false, // 是否同意协议
  setShow: false, // 设置弹出
  mapShow: false, // 地图界面
  mapShow2: false, // 地图界面shadow
  notFirst: false, // 已不是第一次加载
  centerShow: false, // 形象设置
  shotShow: false, // 拍照模式
  actionShow: false, // 动作显示
  actionCurrent: 0, // 当前动画
  frames: [], // 序列帧logo
  xieyi: null,
  xieyiShow: false,
  currentAvatarSelect: 1, // 当前选中的
  xulie: {
    boy1: [],
    boy2: [],
    boy3: [],
    girl1: [],
    girl2: [],
    girl3: []
  }, // 图片集
  xulieCurrent: {
    boy1: 35, // 当前显示的图片的索引
    boy2: 35, // 当前显示的图片的索引
    boy3: 35, // 当前显示的图片的索引
    girl1: 35,
    girl2: 35,
    girl3: 35
  },
  name: '', // 编辑姓名
  playVideoName: '', // 视频名称
  videoplayState: 0, // 视频播放状态 0停止 1播放 2暂停
  duration: '', // 时长
  played: false, //视频播放过
  intro, // 视频简介内容
  videoSlowShow: false, //视频暂缓关闭
  videoIntroShow: false, // 视频简介
  touchBgm:
    'https://o2public.oss-cn-beijing.aliyuncs.com/电信土壤/Audio/其他音效/所有能被触发物体触发时提示音.mp3'
}

const actions = {
  getToken() {
    if (localStorage.getItem('dianxin_token')) return true
    else return false
  }
}

const mutations = {
  changexulieCurrent(state, value) {
    state.xulieCurrent[value.type] = value.val
  },
  iniyxulieCurrent(state) {
    state.xulieCurrent = {
      boy1: 35,
      boy2: 35,
      boy3: 35,
      girl1: 35,
      girl2: 35,
      girl3: 35
    }
  },
  async setToken(state, value) {
    try {
      state.token = value
      await localStorage.setItem('dianxin_token', value)
    } catch (error) {
      throw 'settoken: ' + error
    }
  },
  async clearToken(state) {
    state.token = ''
    await localStorage.removeItem('dianxin_token')
    router.replace('login')
    setTimeout(() => {
      location.reload()
    }, 500)
  },
  changeScene(state, value) {
    state.sceneCurrentIndex = value
    state.currentSound = 0
  },
  setroomId(state, value) {
    state.roomId = value
  },
  changeGuestTip(state, value) {
    let bgm = document.getElementById('touchbgm')
    if (bgm) {
      bgm.currentTime = 0
      bgm.play()
    }
    state.guestTip = value
  },
  changeOpenModalShow(state, value) {
    let bgm = document.getElementById('touchbgm')
    if (bgm) {
      bgm.currentTime = 0
      bgm.play()
    }
    state.openModalShow = value
  },
  changeOpenModalShowInfo(state, value) {
    state.openModalShowInfo.title = value.title
    state.openModalShowInfo.ok = value.ok
    state.openModalShowInfo.cancel = value.cancel
    state.openModalShowInfo.function = value.function
  },
  changeAgree(state, value) {
    state.agree = value
  },
  changeSetShow(state, value) {
    let bgm = document.getElementById('touchbgm')
    if (bgm) {
      bgm.currentTime = 0
      bgm.play()
    }
    state.setShow = value
  },
  changeMapShow(state, value) {
    let bgm = document.getElementById('touchbgm')
    if (bgm) {
      bgm.currentTime = 0
      bgm.play()
    }
    state.mapShow = value
  },
  changeMapShow2(state, value) {
    state.mapShow2 = value
  },
  changeNotFirst(state, value) {
    state.notFirst = value
  },
  changeCenterShow(state, value) {
    let bgm = document.getElementById('touchbgm')
    if (bgm) {
      bgm.currentTime = 0
      bgm.play()
    }
    state.centerShow = value
    if (value) {
      state.currentAvatarSelect = vue.$store.state.chat.role.black
        ? vue.$store.state.chat.role.black
        : 0
    }
  },
  changeActionShow(state, value) {
    let bgm = document.getElementById('touchbgm')
    if (bgm) {
      bgm.currentTime = 0
      bgm.play()
    }
    state.actionShow = value
    if (value) {
      state.actionCurrent = -1
    }
  },
  changeActionCurrent(state, value) {
    state.actionCurrent = value
  },
  setCurrentAvatarSelect(state, value) {
    state.currentAvatarSelect = value
  },
  changeShotShow(state, value) {
    let bgm = document.getElementById('touchbgm')
    if (bgm) {
      bgm.currentTime = 0
      bgm.play()
    }
    state.shotShow = value
    document.o2play.move_ctrl.bLookAtForward = !value
  },
  initFrames(state) {
    for (let index = 1; index <= 36; index++) {
      state.xulie.boy1.push(
        require(`@assets/img/role/boy1/boy_01${index < 10 ? '000' + index : '00' + index}.png`)
      )
      state.xulie.girl1.push(
        require(`@assets/img/role/girl1/Girl_01${index < 10 ? '000' + index : '00' + index}.png`)
      )
      state.xulie.boy2.push(
        require(`@assets/img/role/boy2/Boy_02${index < 10 ? '000' + index : '00' + index}.png`)
      )
      state.xulie.boy3.push(
        require(`@assets/img/role/boy3/Boy_03${index < 10 ? '000' + index : '00' + index}.png`)
      )
      state.xulie.girl2.push(
        require(`@assets/img/role/girl2/Girl_02${index < 10 ? '000' + index : '00' + index}.png`)
      )
      state.xulie.girl3.push(
        require(`@assets/img/role/girl3/Girl_03${index < 10 ? '000' + index : '00' + index}.png`)
      )
    }

    // state.frames.push(require('@assets/img/logog.gif'))
    // state.frames.push(require('@assets/img/云雾.png'))
    // state.frames.push(require('@assets/img/云雾2.png'))
    // state.frames.push(require('@assets/img/sanjiao.png'))
    // state.frames.push(require('@assets/img/sanjiao-hen.png'))
  },
  setxieyi(state, value) {
    state.xieyiShow = value.show
    let bgm = document.getElementById('touchbgm')
    switch (value.data) {
      case 0:
        state.xieyi = privates
        break
      case 1:
        state.xieyi = users
        break
      case 2:
        if (bgm) {
          bgm.currentTime = 0
          bgm.play()
        }
        state.xieyi = guide
        break
      case 3:
        state.xieyi = aboutus
        if (bgm) {
          bgm.currentTime = 0
          bgm.play()
        }
        break
    }
  },
  setName(state, value) {
    state.name = value
  },
  setPlayVideoName(state, value) {
    state.playVideoName = value
    state.videoSlowShow = true
    setTimeout(() => {
      state.videoSlowShow = false
    }, 2000)
  },
  setPlayVideoState(state, value) {
    state.videoplayState = value
  },
  setDuration(state, value) {
    state.duration = value
  },
  resetVideoSlowShow(state, value) {
    state.videoSlowShow = true
    setTimeout(() => {
      state.videoSlowShow = false
    }, 2000)
  },
  setVideoIntroShow(state, value) {
    state.videoIntroShow = value
  },
  setPlayed(state, value) {
    state.played = value
  }
}

export default new Vuex.Store({
  mutations,
  actions,
  state,
  modules: {
    chat
    // client
  }
})
