export var wsClient = {
  curUrl: '',
  mapEvent: {},
  connect: connect,
  close: close,
  send: send,
  on: on,
  remove: remove
}

function connect(url) {
  return new Promise((resolve, reject) => {
    if(wsClient.ws)wsClient.close();
    let ws = new WebSocket(url)
    wsClient.curUrl = url
    wsClient.ws = ws
    ws.onopen = () => {
      setHeatBeat()
      wsClient.onclose = onWSClose
      resolve()
    }
    ws.onmessage = (evt) => {
      if (evt.data instanceof Blob) {
        evt.data.arrayBuffer().then((buffer) => {
          let dataView = new DataView(buffer)
          let head = dataView.getUint8(0)
          if (head == 0xbf) {
            let cmd = dataView.getUint8(1)
            if (wsClient.mapEvent[cmd]) {
              let arrEvent = wsClient.mapEvent[cmd]
              arrEvent.forEach((e) => {
                e(dataView)
              })
            }
          }
        })
      } else {
        let pos = evt.data.indexOf(',')
        let cmd = evt.data.substr(0, pos)
        if (wsClient.mapEvent[cmd]) {
          let netMsg = evt.data.substr(pos + 1)
          let arrEvent = wsClient.mapEvent[cmd]
          arrEvent.forEach((e) => {
            e(netMsg)
          })
        }
      }
    }
    ws.onerror = (err) => {
      console.log('wsClient:', err)
      reject(err)
    }
  })
}

function close() {
  if(wsClient.ws){
    wsClient.ws.close();
  }
  
}

//WS关闭
function onWSClose() {
  wsClient?.send(`${wsClient.requestCmd.CMD_USER_QUIT}`)
}

//设置websocket心跳链接
function setHeatBeat() {
  if (!wsClient.bSetHeatBeat) {
    wsClient.bSetHeatBeat = true
    setInterval(() => {
      setTimeout(() => {
        if (wsClient.ws.readyState == 1) {
          // 已经链接并且可以通讯
          wsClient.send(`${wsClient.requestCmd.CMD_COMMON_HEARTBEAT},ping`)
        } else if (wsClient.ws.readyState == 2) {
          wsClient.ws.onclose = (e) => {
            wsClient.connect(wsClient.curUrl)
            wsClient.onclose = onWSClose
          }
        } else if (wsClient.ws.readyState == 3) {
          // 连接已关闭或者没有链接成功
          wsClient.connect(wsClient.curUrl)
        }
      }, 500)
    }, 30000)
  }
}

function send(msg) {
  if(wsClient.ws && wsClient.ws.readyState == 1){
    wsClient.ws?.send(msg)
  }
}

function on(cmd, action) {
  if (!wsClient.mapEvent[cmd]) {
    wsClient.mapEvent[cmd] = []
  }
  wsClient.mapEvent[cmd].push(action)
}
function remove(cmd, action) {
  if (wsClient.mapEvent[cmd]) {
    let idx = wsClient.mapEvent[cmd].indexOf(action)
    if (idx >= 0) {
      wsClient.mapEvent[cmd].splice(idx, 1)
    }
  }
}

wsClient.requestCmd = {
  //请求消息头
  CMD_VISITOR_LOGIN: 1059,
  CMD_COMMON_HEARTBEAT: 1000, //心跳 1000,ping
  CMD_COMMON_POSITION: 3, //实时同步位置信息 3,positions
  CMD_BUFFER_POSITION: 4, //缓存位置信息 4,positions
  CMD_COMMON_DEBUG: 1100, //用来调试
  CMD_USER_INFO: 1049, //同步玩家信息,1049,info,account_id,project_id
  CMD_USER_LOGIN: 1050, //登录 1050,name,id,info
  CMD_USER_CHATS: 1051, //公屏聊天 1051,messaged
  CMD_USER_CHAT: 1052, //私聊 1052,rev_id,message
  CMD_USER_QUIT: 1053, //退出 1053,null
  CMD_USER_SIT_DOWN: 1054, //坐下 1054,sitname,id
  CMD_USER_STAND_UP: 1055, //起立 1055,sitname,id
  CMD_USER_CUSTOM: 1056, //用户广播自定义消息 1056,custom_msg
  CMD_USER_CUSTOM_SEND: 1057, //用户向指定id自定义消息 1057,rev_id,custom_msg
  CMD_SCENE_STATE: 1058, //场景同步 object 
  CMD_ITEM_DISPLAY:1070,//隐藏显示物体 1070,itemname
}

wsClient.responseCmd = {
  //响应消息头
  MSG_RELOGIN: 198, //重复登录,现有账户退出。198,id
  MSG_NULL: 200, //无消息头
  MSG_LOGIN_SUCCESS: 201, //登录成功 201,id
  MSG_NEWPLAYER: 202, //新用户加入 202,playerinfo
  MSG_QUIT: 203, //退出 203,id
  MSG_POS: 204, //位置信息  204,id,positions
  MSG_BUF_POS: 205, //缓存位置信息 205,positions0,positions1,....
  MSG_PLAYERS: 206, //用户列表 202,json_encode(players)  $player = array("fd" => $this->fd,"id" => $pid,"name" => $cur_name,"info" => $info);
  MSG_CHATS: 207, //公屏聊天 207,id,msg
  MSG_CHAT: 208, //私聊 208,send_id,rev_id,msg
  MSG_SITS: 209, //当前所有座位信息 209,json_encode(sits) $sit[$sitname] = id;
  MSG_SIT_DOWN: 210, //坐下 210,sitname,id
  MSG_STAND_UP: 211, //起立 211,sitname,id
  MSG_CUSTOM: 212, //用户自定义消息 212,id,custom_msg
  MSG_CLEAR_SITDOWNS: 214, //制清理入座信息 214,
  MSG_USER_INFO: 215, //同步玩家信息 215,success_flag
  MSG_DEBUG: 300, //用来调试
  MSG_ALL_CHATS: 221, //历史群聊记录 221,json_string
  MSG_MY_CHAT: 222, //历史私聊记录 221,json_string
  MSG_SCENE_STATE:213,//场景状态信息 
  MSG_ITEM_DISPLAY: 220,//用来调试 220,itemname,0; //hide 0 /display 1
}
