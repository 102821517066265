// import './build/three.js';
// import './build/three.module.js';
// import './js/nodes/THREE.Nodes.js';
// import './js/objects/Reflector.js';
// import './js/objects/ReflectorRTT.js';
// import './js/loaders/NodeMaterialLoader.js';
// import './js/controls/OrbitControls.js';
// import './js/controls/TransformControls.js';
import { Ammo } from 'three/examples/js/libs/ammo.wasm.js'
import * as THREE from 'three/build/three.module'
//import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { OrbitControls } from './OrbitControls'
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls'

import { TransformControls } from 'three/examples/jsm/controls/TransformControls'
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial'
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry'
import { Line2 } from 'three/examples/jsm/lines/Line2'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js'
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js'
import { o2animation } from './animation.js'
import { UnrealBloomPass } from 'three/examples//jsm/postprocessing/UnrealBloomPass.js'
import { LUTPass } from 'three/examples/jsm/postprocessing/LUTPass.js'
import { LUTCubeLoader } from 'three/examples/jsm/loaders/LUTCubeLoader.js'
import { LuminosityShader } from 'three/examples/jsm/shaders/LuminosityShader.js'
import { SobelOperatorShader } from 'three/examples/jsm/shaders/SobelOperatorShader.js'
import { FBXLoader } from 'three/examples//jsm/loaders/FBXLoader.js'
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass.js'
import { ReflectorRTT } from 'three/examples/jsm/objects/ReflectorRTT.js'
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper.js'
import { SSAOPass } from 'three/examples/jsm/postprocessing/SSAOPass.js'
import { SAOPass } from 'three/examples/jsm/postprocessing/SAOPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass.js'

import { SSAARenderPass } from 'three/examples/jsm/postprocessing/SSAARenderPass.js'
import BMF from 'browser-md5-file'
//import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer';

var SobelOperatorShader2 = {
  uniforms: {
    tDiffuse: { value: null },
    resolution: { value: new THREE.Vector2() }
  },

  vertexShader: ['varying vec2 vUv;', 'void main() {', '	vUv = uv;', '	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );', '}'].join('\n'),

  fragmentShader: [
    'uniform sampler2D tDiffuse;',
    'uniform vec2 resolution;',
    'varying vec2 vUv;',

    'void main() {',
    '	vec2 texel = vec2( 1.0 / resolution.x, 1.0 / resolution.y );',
    // kernel definition (in glsl matrices are filled in column-major order)
    '	const mat3 Gx = mat3( -1, -2, -1, 0, 0, 0, 1, 2, 1 );', // x direction kernel
    '	const mat3 Gy = mat3( -1, 0, 1, -2, 0, 2, -1, 0, 1 );', // y direction kernel
    // fetch the 3x3 neighbourhood of a fragment

    // first column

    '	float tx0y0 = texture2D( tDiffuse, vUv + texel * vec2( -1, -1 ) ).r;',
    '	float tx0y1 = texture2D( tDiffuse, vUv + texel * vec2( -1,  0 ) ).r;',
    '	float tx0y2 = texture2D( tDiffuse, vUv + texel * vec2( -1,  1 ) ).r;',

    // second column

    '	float tx1y0 = texture2D( tDiffuse, vUv + texel * vec2(  0, -1 ) ).r;',
    '	float tx1y1 = texture2D( tDiffuse, vUv + texel * vec2(  0,  0 ) ).r;',
    '	float tx1y2 = texture2D( tDiffuse, vUv + texel * vec2(  0,  1 ) ).r;',

    // third column

    '	float tx2y0 = texture2D( tDiffuse, vUv + texel * vec2(  1, -1 ) ).r;',
    '	float tx2y1 = texture2D( tDiffuse, vUv + texel * vec2(  1,  0 ) ).r;',
    '	float tx2y2 = texture2D( tDiffuse, vUv + texel * vec2(  1,  1 ) ).r;',

    // gradient value in x direction

    '	float valueGx = Gx[0][0] * tx0y0 + Gx[1][0] * tx1y0 + Gx[2][0] * tx2y0 + ',
    '		Gx[0][1] * tx0y1 + Gx[1][1] * tx1y1 + Gx[2][1] * tx2y1 + ',
    '		Gx[0][2] * tx0y2 + Gx[1][2] * tx1y2 + Gx[2][2] * tx2y2; ',

    // gradient value in y direction

    '	float valueGy = Gy[0][0] * tx0y0 + Gy[1][0] * tx1y0 + Gy[2][0] * tx2y0 + ',
    '		Gy[0][1] * tx0y1 + Gy[1][1] * tx1y1 + Gy[2][1] * tx2y1 + ',
    '		Gy[0][2] * tx0y2 + Gy[1][2] * tx1y2 + Gy[2][2] * tx2y2; ',

    // magnitute of the total gradient

    '	float G = 1.0-sqrt( ( valueGx * valueGx ) + ( valueGy * valueGy ) );',
    '	gl_FragColor = texture2D( tDiffuse, vUv)*(G/2.0+0.5);',
    'gl_FragColor.w=1.0;',
    '}'
  ].join('\n')
}
//;
import {
  NodeFrame,
  ExpressionNode,
  PhongNodeMaterial,
  MathNode,
  OperatorNode,
  TextureNode,
  BlurNode,
  FloatNode,
  ReflectorNode,
  SwitchNode,
  NormalMapNode,
  ColorNode,
  UVTransformNode
} from 'three/examples/jsm/nodes/Nodes.js'

import { Reflector } from 'three/examples/jsm/objects/Reflector.js'
//import { JSZip } from 'jszip/dist/jszip.min.js';
import * as JSZip from 'jszip'
var idx = 0

class o2vr {
  constructor() {
    this.cubemaps = []
    this.play_setting = {}
    this.frame_move_recall = []
    this.all_files = {}
    this.gif_anis = []
    this.script = {}
    this.cameras = []
    this.animation = new o2animation()
    this.animation.o2 = this
    this.camera_switch_count = 0
    this.skeletons = [] //声明骨骼动画
    this.tree = []
    this.postEffect = {}
    this.postEffect.bDefaultRender = true
    this.postEffect.glow = false
    this.postEffect.sobel = false
    this.postEffect.fxaa = false
    this.postEffect.lut = false
    this.postEffect.film = false
    this.postEffect.outline = false
    this.postEffect.ao = false
    this.postEffect.glow_strength = 0.5
    this.postEffect.glow_threshold = 0
    this.postEffect.glow_radius = 0

    this.postEffect.aoIntensity = 0.002
    this.postEffect.aoRadius = 20
    this.postEffect.aoScale = 1000

    this.camera_move_dir = new THREE.Vector3()
    this.switch_camera_finish = [] //相机移动结束回调

    this.is_mobile = true

    this.total_time = 0
    this.last_frame_time = 0
    //this.arr_frame_time = [];
    this.frame_total_count = 0
    // navigator.userAgent
    //   .toLowerCase()
    //   .match(
    //     /(ipod|ipad|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i
    //   ) != null
  }
  gen_uuid = () => {
    let obj = new THREE.Object3D()
    return obj.uuid
  }
  translate_camera(x, y, z) {
    this.camera_move_dir.set(x, y, z)
    //this.cameraControls.enableZoom = false;
    this.stop_switch_camera()
  }
  move_camera = (x, y, z, xx, yy, zz, t) => {
    this.camera_target2 = new THREE.Vector3(x, y, z)
    this.camera_eye2 = new THREE.Vector3(xx, yy, zz)
    this.begin_eye2 = this.camera.position.clone()
    let camDir = new THREE.Vector3()
    this.camera.getWorldDirection(camDir)
    this.begin_target2 = this.camera.position.clone().add(camDir.multiplyScalar(this.cameraControls.minDistance))
    this.camera_switch_count2 = Math.floor(t * 100)
    this.camera_switch_total2 = this.camera_switch_count2
    this.cameraControls.target.set(x, y, z)
    //this.cameraControls.enableZoom = false;
    //console.log("move camera:", this.camera.position);
  }
  stop_switch_camera() {
    this.camera_switch_count2 = 0
    this.camera_switch_total2 = 0
  }

  collect_colli_objects = () => {
    let sets = new Set()
    function collect1(object) {
      if (object.bColliTest) sets.add(object)
    }

    this.scene.traverse(collect1)

    let result = []
    for (let item of sets.keys()) {
      result.push(item)
    }
    return result
  }
  switch_camera_ex = (x, y, z, xx, yy, zz, t) => {
    if (this.model) {
      this.model.visible = false
    }
    let dir = new THREE.Vector3()
    dir = this.camera
      .getWorldDirection(dir)
      .clone()
      .multiplyScalar(1000)
    this.cameraControls.target = this.camera.position.clone().add(dir)
    let targetPos = new THREE.Vector3(x, y, z)
    let cameraPos = new THREE.Vector3(xx, yy, zz)
    let dis = targetPos.distanceTo(cameraPos)
    if (dis < 1000) {
      let lastDir = new THREE.Vector3()
      lastDir
        .set(x - xx, y - yy, z - zz)
        .normalize()
        .multiplyScalar(1000)
      this.switch_camera2(xx + lastDir.x, yy + lastDir.y, zz + lastDir.z, xx, yy, zz, t)
    } else {
      this.switch_camera2(x, y, z, xx, yy, zz, t)
    }

    //this.cameraControls.enableZoom = true;
  }
  switch_camera = (x, y, z, xx, yy, zz, t) => {
    this.bSwitchFinish = false
    this.switch_camera2(x, y, z, xx, yy, zz, t)
    // this.cameraControls.enableZoom = true;
    // this.camera_target = new THREE.Vector3(x,y,z);
    // this.camera_eye = new THREE.Vector3(xx,yy,zz);
    // this.camera_switch_count=100;
  }
  switch_camera2 = (x, y, z, xx, yy, zz, t) => {
    console.log('move_camera')
    //begin
    this.begin_target2 = this.cameraControls.target.clone()
    this.begin_eye2 = this.camera.position.clone()
    let verz = new THREE.Vector3(0, 0, 1)
    let dst1 = this.begin_target2.clone()
    dst1.sub(this.begin_eye2)
    this.begin_length = dst1.length()
    dst1.normalize()
    this.begin_quaterion = new THREE.Quaternion()
    this.begin_quaterion.setFromUnitVectors(verz, dst1)

    //end
    this.camera_target2 = new THREE.Vector3(x, y, z)
    this.camera_eye2 = new THREE.Vector3(xx, yy, zz)
    let dst2 = this.camera_target2.clone()
    dst2.sub(this.camera_eye2)
    this.end_length = dst2.length()
    dst2.normalize()
    this.end_quaterion = new THREE.Quaternion()
    this.end_quaterion.setFromUnitVectors(verz, dst2)

    if (!t) t = 1
    this.camera_switch_count2 = 100 * t
    this.camera_switch_total2 = 100 * t
  }

  collect_visible_object = (obj, objs) => {
    if (!obj.visible) return
    if (obj.bLock) return
    if (obj.type == 'Mesh' || obj.type == 'Sprite' || obj.type == 'Reflector') objs.push(obj)
    for (let i = 0, l = obj.children.length; i < l; i++) {
      this.collect_visible_object(obj.children[i], objs)
    }
  }
  collect_visible_object2 = (obj, objs) => {
    if (!obj.visible) return
    if (obj.bSkeleton) return
    if (obj.bLock) return
    if (obj.type == 'Sprite') return
    if (obj.type == 'Mesh' || obj.type == 'Sprite' || obj.type == 'Reflector') objs.push(obj)
    for (let i = 0, l = obj.children.length; i < l; i++) {
      this.collect_visible_object2(obj.children[i], objs)
    }
  }
  ray_test = (event) => {
    let mouse = new THREE.Vector2()
    mouse.x = (event.offsetX / this.WIDTH) * 2 - 1
    mouse.y = -(event.offsetY / this.HEIGHT) * 2 + 1
    let raycaster = new THREE.Raycaster()
    raycaster.setFromCamera(mouse, this.camera)
    let objects = []
    for (let i = 0; i < this.scene.children.length; i++) {
      if (this.scene.children[i] != this.gizmo) {
        if (this.scene.children[i].name == 'no_pick') continue
        this.collect_visible_object(this.scene.children[i], objects)
      }
    }
    var intersects = raycaster.intersectObjects(objects)
    return intersects
  }

  ray_test2 = (object, event) => {
    let mouse = new THREE.Vector2()
    mouse.x = (event.offsetX / this.WIDTH) * 2 - 1
    mouse.y = -(event.offsetY / this.HEIGHT) * 2 + 1
    let raycaster = new THREE.Raycaster()
    raycaster.setFromCamera(mouse, this.camera)
    var intersects = raycaster.intersectObjects(object, true)
    return intersects
  }

  ray_test3 = (raycaster) => {
    let objects = []
    for (let i = 0; i < this.scene.children.length; i++) {
      if (this.scene.children[i] != this.gizmo) {
        if (this.scene.children[i].name == 'no_pick') continue
        this.collect_visible_object(this.scene.children[i], objects)
      }
    }
    var intersects = raycaster.intersectObjects(objects)
    return intersects
  }

  ray_test4 = (raycaster) => {
    let objects = []
    for (let i = 0; i < this.scene.children.length; i++) {
      let obj = this.scene.children[i]
      if (obj.bSkeleton) continue
      if (this.scene.children[i] != this.gizmo) {
        this.collect_visible_object2(this.scene.children[i], objects)
      }
    }
    var intersects = raycaster.intersectObjects(objects)
    return intersects
  }

  apply_saved_skdata (obj,data)
  {
    obj.name = data.name;
    obj.uuid = data.uuid;
    if (data.bColliTest) obj.bColliTest = true
    if (data.bLock) obj.bLock = true
    if (data.bPickable != null) obj.bPickable = data.bPickable
    obj.matrixWorldNeedsUpdate = true
    obj.visible = data.visible
    obj.bLock = data.bLock;
    {
      let mat = data.wts
      obj.position.set(0,0,0);
      obj.scale.set(1,1,1);
      obj.rotation.set(0,0,0);
      obj.applyMatrix4(mat)
    }
  }
  apply_skeleton_objects (obj, depth, objects){
    let target = objects[depth];
    if (target)
    {
      this.apply_saved_skdata(obj,target);
    }
    for (let i = 0; i < obj.children.length; i++) {
      let depth2 = depth + '_' + i
      this.apply_skeleton_objects(obj.children[i], depth2, objects)
    }
  }

  apply_skeleton_mtl = (obj, depth, mtls, from_zip, zip_info) => {
    let o2 = this
    let mtl = mtls[depth]
    if (mtl) {
      let material2 = this.load_phong_mtl(mtl, from_zip, zip_info)
      // if (!material2.envMap || material2.envMap == this.scene.background)
      material2.reflectivity = 0
      obj.material = material2
    }
    for (let i = 0; i < obj.children.length; i++) {
      let depth2 = depth + '_' + i
      this.apply_skeleton_mtl(obj.children[i], depth2, mtls, from_zip, zip_info)
    }
  }
  animate = () => {
    requestAnimationFrame(this.animate)
    let time2 = Date.now()
    this.update_frame_rate(time2)
    let time_passed = (time2 - this.last_time) / 1000
    if (this.is_mobile && time_passed < 0.03) return
    //刷新渲染状态
    if (this.rendererStats && this.rendererStats.bShow) {
      this.rendererStats.update(this.renderer)
    }

    // //gif动画
    if (this.gif_anis) {
      for (let i = 0; i < this.gif_anis.length; i++) {
        let gifani = this.gif_anis[i]
        let tex = gifani.texture
        gifani.count++
        if (gifani.count >= gifani.frame) {
          //更新uv
          gifani.count = 0
          gifani.current_frame++
          let count = gifani.width * gifani.height
          if (gifani.current_frame >= count) gifani.current_frame = 0
          tex.repeat.set(1 / gifani.width, 1 / gifani.height)
          let x = gifani.current_frame % gifani.width
          let y = gifani.height - Math.ceil((gifani.current_frame + 1) / gifani.width)
          //console.log(gifani.current_frame,x,y);
          tex.offset.set(x / gifani.width, y / gifani.height)
          tex.wrapS = tex.wrapT = THREE.RepeatWrapping
        }
      }
    }
    if (this.camera_switch_count > 0) {
      this.camera_switch_count -= 1
      this.cameraControls.target.lerp(this.camera_target, 0.05)
      this.camera.position.lerp(this.camera_eye, 0.05)
      if (this.camera_switch_count == 0) {
        this.cameraControls.target.set(this.camera_target.x, this.camera_target.y, this.camera_target.z)
        this.camera.position.set(this.camera_eye.x, this.camera_eye.y, this.camera_eye.z)
      }
    }
    if (this.camera_switch_count2 > 0) {
      this.camera_switch_count2 -= 1
      let target = this.begin_target2.clone()
      let eye = this.begin_eye2.clone()
      let alpha = 1 - this.camera_switch_count2 / this.camera_switch_total2
      let alpha_old = alpha
      let ease = -100
      if (ease != 0) {
        let p = Math.exp(Math.abs(ease / 100.0))
        if (ease < 0) {
          alpha = 1 - Math.pow(1 - alpha, p)
        } else {
          alpha = Math.pow(alpha, p)
        }
      }

      target.lerp(this.camera_target2, alpha)
      let eyelen2 = this.begin_length * (1 - alpha) + this.end_length * alpha
      let quat2 = this.begin_quaterion.clone()
      quat2.slerp(this.end_quaterion, alpha)
      let eyedir = new THREE.Vector3(0, 0, 1)
      eyedir.applyQuaternion(quat2)
      eyedir.multiplyScalar(-eyelen2)
      eye.set(target.x, target.y, target.z)
      eye.add(eyedir)
      // eye.lerp(this.camera_eye2, alpha);
      this.cameraControls.target.set(target.x, target.y, target.z)
      this.camera.position.set(eye.x, eye.y, eye.z)
      //console.log(this.cameraControls.target);
    }
    //切换镜头完成回调
    else if (!this.bSwitchFinish) {
      //console.log("switch_camera finish");
      this.bSwitchFinish = true
      let arr = this.switch_camera_finish
      let len = arr.length
      if (len > 0) {
        for (let i = 0; i < len; i++) {
          arr[i](this.cameraControls.target.x, this.cameraControls.target.y, this.cameraControls.target.z, this.camera.position.x, this.camera.position.y, this.camera.position.z)
        }
      }
    }

    if (time_passed > 0.1) time_passed = 0.1 //间隔不要超过0.1秒
    for (let i = 0; i < this.frame_move_recall.length; i++) {
      this.frame_move_recall[i](time_passed)
    }

    // if (this.camera_move_dir.length() > 0) {
    //     this.b_camera_move = true;
    //     let originPos = this.camera.position.clone();
    //     let move_dir = this.camera_move_dir.clone().multiplyScalar(time_passed);
    //     this.fpsControls.moveForward(move_dir.z);
    //     this.fpsControls.moveRight(move_dir.x);
    //     this.camera.position.y += move_dir.y;
    //     // let dir = new THREE.Vector3();
    //     // this.camera.getWorldDirection(dir);
    //     // dir.multiplyScalar(this.cameraControls.distance);
    //     let targetDir = this.camera.position.clone().sub(originPos);
    //     this.cameraControls.target.add(targetDir);
    //     //this.cameraControls.target = this.camera.position.clone().add(dir);
    // }
    // else {
    //     this.b_camera_move = false;
    // }
    // if (this.script && this.script["frame_move"]) {
    //     this.script["frame_move"](time_passed);
    // }
    this.last_time = time2
    this.animation.frame_move(time_passed)
    this.cameraControls.update()

    let time4 = Date.now()
    if (this.custom_render) {
      this.custom_render()
    } else {
      this.render()
    }
    let time3 = Date.now()
    if (this.div_bind.length > 0) {
      let viewMatrix = new THREE.Matrix4()
      let viewProjectionMatrix = new THREE.Matrix4()
      viewMatrix.copy(this.camera.matrixWorldInverse)
      viewProjectionMatrix.multiplyMatrices(this.camera.projectionMatrix, viewMatrix)

      for (let i = 0; i < this.div_bind.length; i++) {
        let bind = this.div_bind[i]
        let pos = bind.position.clone()
        pos.applyMatrix4(viewProjectionMatrix)
        pos.x = (pos.x * this.WIDTH) / 2 + this.WIDTH / 2
        pos.y = this.HEIGHT / 2 - (pos.y * this.HEIGHT) / 2
        bind.div.style.position = 'absolute'
        let posx = Math.round(pos.x)
        let posy = Math.round(pos.y)
        bind.div.style.left = posx.toString() + 'px'
        bind.div.style.top = posy.toString() + 'px'
      }
    }
    this.frame_count++
    if (!this.render_cost) this.render_cost = 0
    let passed = time3 - time4
    this.render_cost += passed
    // if (this.frame_count%60==0)
    // {
    //   console.log("cost:",this.render_cost," t:",this.renderer.info.render.triangles," c:",this.renderer.info.render.calls);
    //   this.render_cost=0;
    // }
  }

  new_object = (obj) => {
    if (obj.guid) {
      var obj3 = new THREE.Object3D()
      obj3.guid = obj.guid
      this.objects[obj.guid] = obj
    }
  }
  select_object = (obj) => {
    if (obj.guid) {
      var obj3 = this.objects[obj.guid]
      if (obj3) {
        this.enable_gizmo(obj3)
      }
    }
  }
  add_gifani(ga)
  {
    for (let i=0;i<this.gif_anis.length;i++)
    {
      let gifani = this.gif_anis[i];
      if (gifani.texture==ga.texture) return;
    }
    this.gif_anis.push(ga);
  }
  load_phong_mtl = (saved_object, from_zip, zip_info) => {
    //if(saved_object.map.gifani) console.log("load_phong_mtl",saved_object.map);
    let mtl = saved_object
    let o2 = this
    let map = o2.map_white
    if (saved_object.map) {
      map = o2.create_map_with_default(saved_object.map, from_zip, zip_info)
      if (map == null) {
        map = o2.map_white
      }
      if (!saved_object.map.o2_default) {
        map.offset.set(saved_object.map.offset.x, saved_object.map.offset.y)
        map.repeat.set(saved_object.map.repeat.x, saved_object.map.repeat.y)
        map.rotation = saved_object.map.rotation
        map.wrapS = THREE.RepeatWrapping
        map.wrapT = THREE.RepeatWrapping
      }

      if (saved_object.map.gifani) {
        // let map2 = map.clone();
        // if (!map.copyed)
        // {
        //   map.copyed=[];
        // }
        // map.copyed.push(map2);
        // map=map2;
        if (!map.gifani) {
          let gifani = {}
          gifani.count = 0
          gifani.frame = saved_object.map.gifani.frame
          gifani.begin = saved_object.map.gifani.begin
          gifani.current_frame = 0
          gifani.width = saved_object.map.gifani.width
          gifani.height = saved_object.map.gifani.height
          gifani.texture = map
          map.gifani = gifani
        }
        if (map.gifani) o2.add_gifani(map.gifani);
      }

      // if (saved_object.map.gifani) {

      //   let map2 = map.clone();
      //   if (!map.copyed)
      //   {
      //     map.copyed=[];
      //   }
      //   map.copyed.push(map2);
      //   map=map2;

      //   let gifani = {}
      //   gifani.count = 0
      //   gifani.frame = saved_object.map.gifani.frame
      //   gifani.width = saved_object.map.gifani.width
      //   gifani.height = saved_object.map.gifani.height
      //   gifani.begin = saved_object.map.gifani.begin
      //   if (saved_object.map.gifani.begin) gifani.current_frame =  saved_object.map.gifani.begin;
      //   else gifani.current_frame = 0;

      //   gifani.texture = map
      //   map.gifani = gifani;
      //   o2.gif_anis.push(gifani)
      // }
    }

    let material = new THREE.MeshPhongMaterial({ map: map })
    if (saved_object.lightMap) {
      let map2 = o2.create_map_with_default(saved_object.lightMap, from_zip, zip_info)
      material.lightMap = map2
    }
    material.side = mtl.side
    material.transparent = mtl.transparent
    if (mtl.opacity) material.opacity = mtl.opacity
    if (mtl.alphaTest != null) material.alphaTest = mtl.alphaTest
    if (mtl.blending) material.blending = mtl.blending
    if (mtl.shininess) material.shininess = mtl.shininess
    material.o2id = mtl.o2id

    if (mtl.depthTest != null) material.depthTest = mtl.depthTest
    if (mtl.depthWrite != null) material.depthWrite = mtl.depthWrite
    if (mtl.color != null) material.color.setHex(mtl.color)

    if (mtl.type == 'MeshPhongMaterial') {
      if (mtl.combine != null) material.combine = mtl.combine
      if (mtl.reflectivity != null) material.reflectivity = mtl.reflectivity
      if (mtl.lightMapIntensity != null) material.lightMapIntensity = mtl.lightMapIntensity
      if (mtl.emissiveIntensity != null) material.emissiveIntensity = mtl.emissiveIntensity
      if (mtl.aoMapIntensity != null) material.aoMapIntensity = mtl.aoMapIntensity
      if (mtl.normalScale != null) material.normalScale = mtl.normalScale
      if (saved_object.alphaMap != null) material.alphaMap = o2.create_map_with_default(saved_object.alphaMap, from_zip, zip_info)
      if (saved_object.emissiveMap != null) material.emissiveMap = o2.create_map_with_default(saved_object.emissiveMap, from_zip, zip_info)
      if (saved_object.normalMap != null) material.normalMap = o2.create_map_with_default(saved_object.normalMap, from_zip, zip_info)
      if (saved_object.specularMap != null) material.specularMap = o2.create_map_with_default(saved_object.specularMap, from_zip, zip_info)
      if (saved_object.aoMap != null) material.aoMap = o2.create_map_with_default(saved_object.aoMap)
      material.specular.setHex(mtl.specular)
      material.emissive.setHex(mtl.emissive)
      if (mtl.shininess) material.shininess = mtl.shininess
      material.needsUpdate = true
    }

    o2.materials.push({ id: material.o2id, mtl: material })
    if (mtl.cube_info) {
      //名称查找
      for (let i = 0; i < o2.cubemaps.length; i++) {
        let cube = o2.cubemaps[i]
        if (cube.name == mtl.cube_info.name) {
          material.envMap = cube.texture
          break
        }
      }
      ////类型查找
      if (!material.envMap) {
        for (let i = 0; i < o2.cubemaps.length; i++) {
          let cube = o2.cubemaps[i]
          if (cube.type == mtl.cube_info.type) {
            material.envMap = cube.texture
            break
          }
        }
      }
    }
    if (!material.envMap) material.envMap = o2.create_envMap(mtl.envMap, from_zip, zip_info)
    return material
  }
  load_mesh_data = (geometry, saved_object, from_zip, zip_info) => {
    let o2 = this
    geometry.meshid = saved_object.meshid
    let material = this.load_phong_mtl(saved_object.material, from_zip, zip_info)

    var mesh = new THREE.Mesh(geometry, material)

    mesh.castShadow = saved_object.castShadow
    mesh.receiveShadow = saved_object.receiveShadow

    mesh.matrixWorldNeedsUpdate = true
    mesh.name = saved_object.name
    mesh.uuid = saved_object.uuid
    var mat = saved_object.wts
    mesh.applyMatrix4(mat)
    mesh.visible = saved_object.visible
    if (saved_object.common_mesh) mesh.common_mesh = saved_object.common_mesh
    return mesh
    // let map = o2.map_white
    // if (saved_object.material.map) {
    //   map = o2.create_map_with_default(saved_object.material.map)
    //   if (map == null) {
    //     map = o2.map_white
    //   }
    //   if (!saved_object.material.map.o2_default) {
    //     map.offset.set(
    //       saved_object.material.map.offset.x,
    //       saved_object.material.map.offset.y
    //     )
    //     map.repeat.set(
    //       saved_object.material.map.repeat.x,
    //       saved_object.material.map.repeat.y
    //     )
    //     map.rotation = saved_object.material.map.rotation
    //     map.wrapS = THREE.RepeatWrapping
    //     map.wrapT = THREE.RepeatWrapping
    //   }
    //   if (saved_object.material.map.gifani) {
    //     let gifani = {}
    //     gifani.count = 0
    //     gifani.frame = saved_object.material.map.gifani.frame
    //     gifani.current_frame = 0
    //     gifani.width = saved_object.material.map.gifani.width
    //     gifani.height = saved_object.material.map.gifani.height
    //     gifani.texture = map
    //     map.gifani = saved_object.material.map.gifani
    //     let found = false
    //     for (let i = 0; i < o2.gif_anis.length; i++) {
    //       let ga = o2.gif_anis[i]
    //       if (ga.texture === map) {
    //         found = true
    //         break
    //       }
    //     }
    //     if (!found) o2.gif_anis.push(gifani)
    //   }
    // }
    // let material = new THREE.MeshPhongMaterial({ map: map })
    // if (saved_object.material.lightMap) {
    //   let map2 = o2.create_map_with_default(saved_object.material.lightMap)
    //   material.lightMap = map2
    // }
    // let mtl = saved_object.material
    // material.side = mtl.side
    // material.transparent = mtl.transparent
    // if (mtl.opacity) material.opacity = mtl.opacity
    // if (mtl.alphaTest) material.alphaTest = mtl.alphaTest
    // if (mtl.blending) material.blending = mtl.blending
    // if (mtl.shininess) material.shininess = mtl.shininess
    // material.o2id = mtl.o2id

    // material.depthTest = mtl.depthTest
    // material.depthWrite = mtl.depthWrite
    // material.alphaTest = mtl.alphaTest
    // // mtl.reflectivity=0;
    // if (mtl.type == 'MeshPhongMaterial') {
    //   material.color.setHex(mtl.color)
    //   if (mtl.combine != null) material.combine = mtl.combine
    //   if (mtl.reflectivity != null) material.reflectivity = mtl.reflectivity
    //   if (mtl.lightMapIntensity != null)
    //     material.lightMapIntensity = mtl.lightMapIntensity
    //   if (mtl.emissiveIntensity != null)
    //     material.emissiveIntensity = mtl.emissiveIntensity
    //   if (mtl.aoMapIntensity != null)
    //     material.aoMapIntensity = mtl.aoMapIntensity
    //   if (mtl.normalScale != null) material.normalScale = mtl.normalScale
    //   if (saved_object.material.alphaMap != null)
    //     material.alphaMap = o2.create_map_with_default(
    //       saved_object.material.alphaMap
    //     )
    //   if (saved_object.material.emissiveMap != null)
    //     material.emissiveMap = o2.create_map_with_default(
    //       saved_object.material.emissiveMap
    //     )
    //   if (saved_object.material.normalMap != null)
    //     material.normalMap = o2.create_map_with_default(
    //       saved_object.material.normalMap
    //     )
    //   if (saved_object.material.specularMap != null)
    //     material.specularMap = o2.create_map_with_default(
    //       saved_object.material.specularMap
    //     )
    //   if (saved_object.material.aoMap != null)
    //     material.aoMap = o2.create_map_with_default(saved_object.material.aoMap)
    //   material.specular.setHex(mtl.specular)
    //   material.emissive.setHex(mtl.emissive)
    //   if (mtl.shininess) material.shininess = mtl.shininess
    //   material.needsUpdate = true
    // }

    // o2.materials.push({ id: material.o2id, mtl: material })
    // material.envMap = o2.create_envMap(mtl.envMap, from_zip)
    // var mesh = new THREE.Mesh(geometry, material)

    // mesh.castShadow = saved_object.castShadow
    // mesh.receiveShadow = saved_object.receiveShadow

    // mesh.matrixWorldNeedsUpdate = true
    // mesh.name = saved_object.name
    // mesh.uuid = saved_object.uuid
    // var mat = saved_object.wts
    // mesh.applyMatrix4(mat)
    // mesh.visible = saved_object.visible
    // if (saved_object.common_mesh) mesh.common_mesh = saved_object.common_mesh;
    // return mesh
  }
  load_saved_object = (saved_object, from_zip, zip_info) => {
    let o2 = this
    return new Promise((resolve, reject) => {
      if (saved_object.encode_type&&saved_object.encode_type=="Skeleton2.0")
      {
        let promise = o2.load_saved_skeleton(saved_object.url2,zip_info);
        promise.then((obj3) => {
          if (obj3) {
            
            if (saved_object.mtls) {
              o2.apply_skeleton_mtl(obj3, '0', saved_object.mtls, from_zip, zip_info)
            }
            if (saved_object.objects) {
              o2.apply_skeleton_objects(obj3, '0', saved_object.objects)
            }
            if (saved_object.clips&&saved_object.clips.length>0)
            {
              //console.log(saved_object.clips);
              let promises=[];
              let total=0;
              for (let i=0;i<saved_object.clips.length;i++)
              {
                let clip=saved_object.clips[i];
                if (clip.version!=2) continue;
                total++;
                let promise = o2.load_animation_clip(clip.url,zip_info);
                promise.then((ani)=>{
                  let ac = ani.animations[0];
                  ac.name = clip.name;
                  obj3.animations.push(ac);
                  obj3.clips.push(clip);
                  total=total-1;
                  if (total<=0)
                  {
                    o2.play_skeleton_clip(obj3,saved_object.clip_name,true);
                    resolve(obj3);
                  }
                })
              }
            }else{
              
            resolve(obj3);
            }
          }
        },()=>{reject()});
        return;
      }
      if (saved_object.type == 'Mesh') {
        let geometry
        if (zip_info) {
          if (saved_object.common_mesh) {
            if (saved_object.common_mesh == 'MeshBox') {
              geometry = new THREE.BoxGeometry(1000, 1000, 1000, 1, 1, 1)
            }
            if (saved_object.common_mesh == 'MeshPlane') {
              geometry = new THREE.PlaneGeometry(1000, 1000, 1, 1)
            }
            if (saved_object.common_mesh == 'MeshSphere') {
              geometry = new THREE.SphereGeometry(1000, 24, 24)
            }
          } else {
            let xxx = zip_info.all_files[saved_object.meshid]
            geometry = o2.load_mesh2(xxx)
          }

          if (geometry) {
            o2.meshes[saved_object.meshid] = geometry
            let mesh = o2.load_mesh_data(geometry, saved_object, from_zip, zip_info)
            let xy = saved_object.xy
            if (xy && geometry.attributes.uv2) {
              mesh.xy = mesh
              let fStep = o2.scene.bake_step
              let buffer = geometry.attributes.uv2.array
              for (let i = 0; i < buffer.length / 2; i++) {
                buffer[i * 2] = fStep * xy.ix + fStep * buffer[i * 2]
                buffer[i * 2 + 1] = 1 - (fStep * xy.iy + fStep * (1 - buffer[i * 2 + 1]))
              }
              mesh.xy = xy
              geometry.attributes.uv2.needsUpdate = true
            }

            resolve(mesh)
          } else {
            reject('create geometry fail!')
          }
        } else if (from_zip) {
          if (saved_object.common_mesh) {
            if (saved_object.common_mesh == 'MeshBox') {
              geometry = new THREE.BoxGeometry(1000, 1000, 1000, 1, 1, 1)
            }
            if (saved_object.common_mesh == 'MeshPlane') {
              geometry = new THREE.PlaneGeometry(1000, 1000, 1, 1)
            }
            if (saved_object.common_mesh == 'MeshSphere') {
              geometry = new THREE.SphereGeometry(1000, 24, 24)
            }
          } else {
            let xxx = this.all_files[saved_object.meshid]
            geometry = o2.load_mesh2(xxx)
          }
          if (geometry) {
            o2.meshes[saved_object.meshid] = geometry
            let mesh = o2.load_mesh_data(geometry, saved_object, from_zip, zip_info)

            let xy = saved_object.xy
            if (xy && geometry.attributes.uv2) {
              mesh.xy = mesh
              let fStep = o2.scene.bake_step
              let buffer = geometry.attributes.uv2.array
              for (let i = 0; i < buffer.length / 2; i++) {
                buffer[i * 2] = fStep * xy.ix + fStep * buffer[i * 2]
                buffer[i * 2 + 1] = 1 - (fStep * xy.iy + fStep * (1 - buffer[i * 2 + 1]))
              }
              mesh.xy = xy
              geometry.attributes.uv2.needsUpdate = true
            }

            resolve(mesh)
          } else {
            reject('create geometry fail!')
          }
        } else {
          if (saved_object.common_mesh) {
            let geometry
            if (saved_object.common_mesh == 'MeshBox') {
              geometry = new THREE.BoxGeometry(1000, 1000, 1000, 1, 1, 1)
            }
            if (saved_object.common_mesh == 'MeshPlane') {
              geometry = new THREE.PlaneGeometry(1000, 1000, 1, 1)
            }
            if (saved_object.common_mesh == 'MeshSphere') {
              geometry = new THREE.SphereGeometry(1000, 24, 24)
            }

            if (geometry) {
              let mesh = o2.load_mesh_data(geometry, saved_object, from_zip, zip_info)
              let xy = saved_object.xy
              if (xy && geometry.attributes.uv2) {
                mesh.xy = xy
                let fStep = o2.scene.bake_step
                let buffer = geometry.attributes.uv2.array
                for (let i = 0; i < buffer.length / 2; i++) {
                  buffer[i * 2] = fStep * xy.ix + fStep * buffer[i * 2]
                  buffer[i * 2 + 1] = 1 - (fStep * xy.iy + fStep * (1 - buffer[i * 2 + 1]))
                }
                mesh.xy = xy
                geometry.attributes.uv2.needsUpdate = true
              }
              resolve(mesh)
            } else {
              reject('create geo fail!')
            }
          } else {
            let mdl_url = o2.project_url + saved_object.meshid
            let xhr = new XMLHttpRequest()
            xhr.open('get', mdl_url, true)
            xhr.responseType = 'arraybuffer'
            xhr.send()
            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status >= 200 && xhr.status < 300) {
                  var xxx = xhr.response
                  let geometry = o2.load_mesh2(xxx)
                  if (geometry) {
                    let mesh = o2.load_mesh_data(geometry, saved_object, from_zip, zip_info)
                    let xy = saved_object.xy
                    if (xy && geometry.attributes.uv2) {
                      mesh.xy = xy
                      let fStep = o2.scene.bake_step
                      let buffer = geometry.attributes.uv2.array
                      for (let i = 0; i < buffer.length / 2; i++) {
                        buffer[i * 2] = fStep * xy.ix + fStep * buffer[i * 2]
                        buffer[i * 2 + 1] = 1 - (fStep * xy.iy + fStep * (1 - buffer[i * 2 + 1]))
                      }
                      mesh.xy = xy
                      geometry.attributes.uv2.needsUpdate = true
                    }
                    resolve(mesh)
                  } else {
                    reject('create geo fail!')
                  }
                } else {
                  reject(xhr.status)
                }
              }
              if (xhr.status === 404) {
                reject(xhr.status)
              }
            }
          }
        }
      } else if (saved_object.type == 'LineSegments') {
        if (zip_info) {
          let xxx = zip_info.all_files[saved_object.file]
          if (xxx) {
            let line = o2.load_mesh_line(xxx)
            if (line) {
              line.name = saved_object.name
              line.file = saved_object.file
              let mat = saved_object.wts
              line.applyMatrix4(mat)
              line.material.color.setHex(saved_object.material.color)
              resolve(line)
            } else {
              reject('create geo fail!')
            }
          } else {
            reject('create geometry fail!')
          }
        } else if (from_zip) {
          let xxx = this.all_files[saved_object.file]
          if (xxx) {
            let line = o2.load_mesh_line(xxx)
            if (line) {
              line.name = saved_object.name
              line.file = saved_object.file
              let mat = saved_object.wts
              line.applyMatrix4(mat)
              line.material.color.setHex(saved_object.material.color)
              resolve(line)
            } else {
              reject('create geo fail!')
            }
          } else {
            reject('create geometry fail!')
          }
        } else {
          //console.log("Lineseg load",saved_object);
          let mdl_url = o2.project_url + saved_object.file
          let xhr = new XMLHttpRequest()
          xhr.open('get', mdl_url, true)
          xhr.responseType = 'arraybuffer'
          xhr.send()
          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status >= 200 && xhr.status < 300) {
                var xxx = xhr.response
                let line = o2.load_mesh_line(xxx)
                if (line) {
                  line.name = saved_object.name
                  line.file = saved_object.file
                  let mat = saved_object.wts
                  line.applyMatrix4(mat)
                  line.material.color.setHex(saved_object.material.color)
                  resolve(line)
                } else {
                  reject('create geo fail!')
                }
              } else {
                reject(xhr.status)
              }
            }
            if (xhr.status === 404) {
              console.log('not found:' + saved_object)
              reject(xhr.status)
            }
          }
        }
      } else if (saved_object.type == 'Object3D') {
        if (saved_object.name=="展厅B")
        {
          console.log(saved_object);
        }
        let obj = new THREE.Object3D()
        obj.matrixWorldNeedsUpdate = true
        obj.name = saved_object.name
        obj.uuid = saved_object.uuid
        let mat = saved_object.wts
        obj.applyMatrix4(mat)
        obj.visible = saved_object.visible
        if (saved_object.children && saved_object.children.length > 0) {
          const allRequest = []
          if (1) {
            var mdls = saved_object.children
            for (let index = 0; index < mdls.length; index++) {
              const model_obj = mdls[index]
              var promise = o2.load_saved_object(model_obj, from_zip, zip_info)
              promise.then((obj3) => {
                o2.copy_saved_param(obj3, model_obj)
                if (model_obj.lib_uuid) obj3.lib_uuid = model_obj.lib_uuid
                if (obj3) {
                  obj.add(obj3)
                }
              })
              allRequest.push(promise)
            }
            Promise.all(allRequest).then(
              () => {
                resolve(obj)
              },
              () => {
                console.log("加载错误",saved_object.name);
                resolve(obj)
                //reject()
              }
            )
          }
        } else {
          resolve(obj)
        }
      } else if (saved_object.type == 'Sprite') {
        let mtl = saved_object.material
        let map = null
        if (mtl.map) {
          map = o2.create_map_with_default(mtl.map, from_zip, zip_info)
          if (map) {
            map.offset.set(saved_object.material.map.offset.x, saved_object.material.map.offset.y)
            map.repeat.set(saved_object.material.map.repeat.x, saved_object.material.map.repeat.y)
            map.rotation = saved_object.material.map.rotation
            map.wrapS = THREE.RepeatWrapping
            map.wrapT = THREE.RepeatWrapping
            if (saved_object.material.map.gifani) {
              // let map2 = map.clone();
              // if (!map.copyed)
              // {
              //   map.copyed=[];
              // }
              // map.copyed.push(map2);
              // map=map2;
              if (!map.gifani) {
                let gifani = {}
                gifani.count = 0
                gifani.frame = saved_object.material.map.gifani.frame
                gifani.begin = saved_object.material.map.gifani.begin
                gifani.current_frame = 0
                gifani.width = saved_object.material.map.gifani.width
                gifani.height = saved_object.material.map.gifani.height
                gifani.texture = map
                map.gifani = gifani
                //o2.gif_anis.push(gifani)
              }
              if (map.gifani) o2.add_gifani(map.gifani);
            }
          }
        }
        if (map == null) {
          map = o2.map_white
        }

        let sprite = new THREE.Sprite(new THREE.SpriteMaterial({ map: map, color: '#69f' }))
        sprite.material.color.setHex(mtl.color)
        if (mtl.opacity) sprite.material.opacity = mtl.opacity
        sprite.material.uuid = mtl.uuid
        sprite.material.uuid = mtl.uuid
        sprite.material.uuid = mtl.uuid
        sprite.material.side = mtl.side
        sprite.material.o2id = mtl.o2id
        if (mtl.alphaTest) sprite.material.alphaTest = mtl.alphaTest
        sprite.material.transparent = mtl.transparent
        sprite.material.depthWrite = mtl.depthWrite
        sprite.material.blending = mtl.blending
        sprite.material.depthTest = mtl.depthTest

        sprite.position.set(saved_object.position.x, saved_object.position.y, saved_object.position.z)
        sprite.scale.set(saved_object.scale.x, saved_object.scale.y, saved_object.scale.z)
        sprite.uuid = saved_object.uuid
        sprite.name = saved_object.name
        sprite.visible = saved_object.visible
        resolve(sprite)
      } else if (saved_object.type == 'AmbientLight') {
        let obj1 = new THREE.AmbientLight(saved_object.color)
        obj1.intensity = saved_object.intensity
        obj1.matrixWorldNeedsUpdate = true
        obj1.name = saved_object.name
        obj1.uuid = saved_object.uuid
        let mat = saved_object.wts
        obj1.applyMatrix4(mat)
        obj1.visible = saved_object.visible
        resolve(obj1)
      } else if (saved_object.type == 'Group' && saved_object.bSkeleton) {
        let o2 = this
        //console.log("load skeleton", saved_object);
        let url = this.project_url + saved_object.url
        if (zip_info) {
          let f1 = zip_info.all_files[saved_object.url]
          let blob = new Blob([f1])
          url = URL.createObjectURL(blob)
        } else if (from_zip) {
          let f1 = o2.all_files[saved_object.url]
          let blob = new Blob([f1])
          url = URL.createObjectURL(blob)
        }

        this.load_skeleton_animate(url, saved_object.name, (skeleton) => {
          skeleton.clips = saved_object.clips
          skeleton.bSkeleton = true
          skeleton.url = saved_object.url
          skeleton.clip_name = saved_object.clip_name
          let obj = skeleton
          obj.matrixWorldNeedsUpdate = true
          obj.name = saved_object.name
          obj.uuid = saved_object.uuid
          let mat = saved_object.wts
          obj.applyMatrix4(mat)
          obj.visible = saved_object.visible
          obj.traverse(function(child) {
            if (child.material && child.material.type == 'MeshPhongMaterial') {
              child.material.color.setRGB(1, 1, 1)
              child.material.lightMap = o2.map_white
            }
          })
          //加载材质
          if (saved_object.mtls) {
            o2.apply_skeleton_mtl(obj, '0', saved_object.mtls, from_zip, zip_info)
          }
          //加载动作
          let count = 0
          if (skeleton.clips?.length > 0) {
            for (let i = 0; i < skeleton.clips.length; i++) {
              let clip = skeleton.clips[i]
              let url = o2.project_url + clip.url
              if (zip_info) {
                let f2 = zip_info.all_files[clip.url]
                let blob2 = new Blob([f2])
                url = URL.createObjectURL(blob2)
              }
              o2.load_skeleton_clip(url, skeleton, clip.name, () => {
                count++
                if (count == skeleton.clips.length) {
                  o2.play_skeleton_clip(skeleton, skeleton.clip_name, true)
                  resolve(obj)
                }
              })
            }
          } else {
           resolve(obj)
          }
        })
      } else if (saved_object.type == 'DirectionalLight') {
        let obj1 = new THREE.DirectionalLight(saved_object.color, 0.5)
        obj1.intensity = saved_object.intensity
        obj1.matrixWorldNeedsUpdate = true
        obj1.name = saved_object.name
        obj1.uuid = saved_object.uuid
        let mat = saved_object.wts
        obj1.applyMatrix4(mat)
        obj1.visible = saved_object.visible
        if (true) {
          obj1.castShadow = true
          obj1.shadow.camera.near = 100
          obj1.shadow.camera.far = 1000000
          obj1.shadow.camera.right = 200000
          obj1.shadow.camera.left = -200000
          obj1.shadow.camera.top = 200000
          obj1.shadow.camera.bottom = -200000
          obj1.shadow.mapSize.width = 1024
          obj1.shadow.mapSize.height = 1024
        }
        resolve(obj1)
      } else if (saved_object.type == 'PointLight') {
        //console.log("PointLight");
        let obj1 = new THREE.PointLight(saved_object.color, 1, 1000)
        obj1.intensity = saved_object.intensity
        obj1.matrixWorldNeedsUpdate = true
        obj1.name = saved_object.name
        obj1.uuid = saved_object.uuid
        let mat = saved_object.wts
        obj1.applyMatrix4(mat)
        obj1.decay = saved_object.decay
        obj1.distance = saved_object.distance
        obj1.visible = saved_object.visible
        obj1.castShadow = true
        if (obj1.castShadow) {
          obj1.shadow.camera.near = 100
          obj1.shadow.camera.far = 60000
          obj1.shadow.bias = 0.0001
          obj1.shadow.mapSize.width = 1024
          obj1.shadow.mapSize.height = 1024
        }
        resolve(obj1)
      } else if (saved_object.type == 'SpotLight') {
        let obj1 = new THREE.SpotLight(saved_object.color)
        obj1.intensity = saved_object.intensity
        obj1.matrixWorldNeedsUpdate = true
        obj1.name = saved_object.name
        obj1.uuid = saved_object.uuid
        let mat = saved_object.wts
        obj1.applyMatrix4(mat)
        obj1.decay = saved_object.decay
        obj1.penumbra = saved_object.penumbra
        obj1.distance = saved_object.distance
        obj1.angle = saved_object.angle
        obj1.visible = saved_object.visible
        obj1.castShadow = saved_object.castShadow
        if (obj1.castShadow) {
          obj1.shadow.camera.near = 100
          obj1.shadow.camera.far = 60000
          obj1.shadow.bias = 0.0001
          obj1.shadow.mapSize.width = 1024
          obj1.shadow.mapSize.height = 1024
        }
        obj1.target_uuid = saved_object.target_uuid
        resolve(obj1)
      } else if (saved_object.type == 'Reflector') {
        if (saved_object.material == null) {
          resolve(null)
        } else {
          let uv = new UVTransformNode()
          let masktex = this.create_map_with_default(saved_object.material.alphaMap, from_zip, zip_info)
          const mask = new SwitchNode(new TextureNode(masktex), 'w')
          const planeGeo = new THREE.PlaneGeometry(10000, 10000)
          let geometry
          geometry = new THREE.PlaneGeometry(10000, 10000)
          let groundMirror = new ReflectorRTT(geometry, {
            clipBias: 0.003,
            textureWidth: this.WIDTH,
            textureHeight: this.HEIGHT
          })
          const mirror = new ReflectorNode(groundMirror)

          let map_normal = this.create_map_with_default(saved_object.material.normalMap, from_zip, zip_info)
          const normalMap = new TextureNode(map_normal)
          normalMap.uv = uv
          const normalXY = new SwitchNode(normalMap, 'xy')
          const normalXYFlip = new MathNode(normalXY, MathNode.INVERT)
          const offsetNormal = new OperatorNode(normalXYFlip, new FloatNode(0.5), OperatorNode.SUB)
          mirror.offset = new OperatorNode(
            offsetNormal, // normal
            new FloatNode(saved_object.material.XnormalScale), // scale
            OperatorNode.MUL
          )
          let diffusemap = this.create_map_with_default(saved_object.material.map, from_zip, zip_info)
          let light_map = this.create_map_with_default(saved_object.material.lightMap, from_zip, zip_info)
          const map = new TextureNode(diffusemap)
          let color_node = new ColorNode(saved_object.material.color)
          const diffuseNode = new OperatorNode(map, color_node, OperatorNode.MUL)

          const lightmap = new TextureNode(light_map)
          //console.log(saved_object);
          map.uv = uv
          map.uv.setUvTransform(
            saved_object.material.map.offset.x,
            saved_object.material.map.offset.y,
            saved_object.material.map.repeat.x,
            saved_object.material.map.repeat.y,
            saved_object.material.map.rotation
          )
          map.uv._u = saved_object.material.map.offset.x
          map.uv._v = saved_object.material.map.offset.y
          map.uv._sx = saved_object.material.map.repeat.x
          map.uv._sy = saved_object.material.map.repeat.y
          map.uv._rotation = saved_object.material.map.rotation

          let groundMirrorMaterial = new PhongNodeMaterial()
          groundMirrorMaterial.environment = mirror
          groundMirrorMaterial.environmentAlpha = mask
          groundMirrorMaterial.color = diffuseNode
          groundMirrorMaterial.light = lightmap

          let mtl = saved_object.material
          let material = groundMirrorMaterial
          material.uuid = mtl.uuid
          material.side = mtl.side
          material.transparent = mtl.transparent
          if (mtl.opacity) material.opacity = mtl.opacity
          if (mtl.alphaTest) material.alphaTest = mtl.alphaTest
          if (mtl.blending) material.blending = mtl.blending
          if (mtl.shininess) material.shininess = mtl.shininess
          material.o2id = mtl.o2id

          material.depthTest = mtl.depthTest
          material.depthWrite = mtl.depthWrite
          material.alphaTest = mtl.alphaTest

          let mirrorMesh = new THREE.Mesh(planeGeo, groundMirrorMaterial)
          groundMirror.add(mirrorMesh)
          let obj = groundMirror
          obj.matrixWorldNeedsUpdate = true
          obj.name = saved_object.name
          obj.uuid = saved_object.uuid
          let mat = saved_object.wts
          obj.applyMatrix4(mat)
          obj.visible = saved_object.visible
          const ua = navigator.userAgent.toLowerCase()
          let isIos = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/i)
          //console.log("是不是IOS" + isIos);
          if (isIos) {
            var ver = ua.match(/cpu iphone os (.*?) like mac os/)
            //console.log("ios版本:" + ver[1]);
            if (!ver) {
              console.log('获取不到IOS版本')
            } else {
              var arrStr = ver[1].split('_')
              if (parseInt(arrStr[0]) >= 15) {
                console.log('ios版本:' + ver[1])
                let panelMtl = new THREE.MeshPhongMaterial()
                if(diffusemap){
                  diffusemap.offset.x = mtl.map.offset.x
                  diffusemap.offset.y = mtl.map.offset.y
                  diffusemap.repeat.x = mtl.map.repeat.x
                  diffusemap.repeat.y = mtl.map.repeat.y
                }
                if(masktex){
                  masktex.offset.x = mtl.map.offset.x
                  masktex.offset.y = mtl.map.offset.y
                  masktex.repeat.x = mtl.map.repeat.x
                  masktex.repeat.y = mtl.map.repeat.y
                }
                if(map_normal){
                  map_normal.offset.x = mtl.map.offset.x
                  map_normal.offset.y = mtl.map.offset.y
                  map_normal.repeat.x = mtl.map.repeat.x
                  map_normal.repeat.y = mtl.map.repeat.y
                }
                if (mtl.opacity) panelMtl.opacity = mtl.opacity
                if (mtl.alphaTest) panelMtl.alphaTest = mtl.alphaTest
                if (mtl.blending) panelMtl.blending = mtl.blending
                if (mtl.shininess) panelMtl.shininess = mtl.shininess
                panelMtl.lightMap = light_map
                panelMtl.map = diffusemap
                panelMtl.alphaMap = masktex
                panelMtl.normalMap = map_normal
                let panelMesh = new THREE.Mesh(planeGeo, panelMtl)
                panelMesh.applyMatrix4(mat)
                resolve(panelMesh)
                return
              }
            }
          }
          resolve(obj)
        }
      } else {
        resolve(null)
      }
    })
  }
  load_mesh_line_url = (mdl_url, model_obj) => {
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest()
      xhr.open('get', mdl_url, true)
      xhr.responseType = 'arraybuffer'
      let o2 = this
      xhr.send()
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status >= 200 && xhr.status < 300) {
            var xxx = xhr.response
            let p = o2.load_mesh_line(xxx)
            if (p) {
              var mat = new THREE.Matrix4()
              mat.elements = model_obj.wts
              p.applyMatrix4(mat)
              p.name = model_obj.name
              p.file = model_obj.mesh
              resolve(p)
            } else {
              console.log("线框加载错误");
              reject()
            }
          } else {
            reject(xhr.status)
          }
        }
      }
    })
  }
  load_mesh_url = (mdl_url, model_obj) => {
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest()
      xhr.open('get', mdl_url, true)
      xhr.responseType = 'arraybuffer'
      let o2 = this
      xhr.send()
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status >= 200 && xhr.status < 300) {
            var xxx = xhr.response
            let p = o2.load_mesh(xxx, model_obj)
            p.then((val) => {
              resolve(xhr.response)
            })
          } else {
            reject(xhr.status)
          }
        }
      }
    })
  }

  _process_after_load = (object) => {
    if (object.type == 'SpotLight') {
      if (object.target_uuid) {
        let obj = this.search_obj_by_guid2(object.target_uuid)
        if (obj) {
          object.target = obj
        }
      }
    }
    //////////////
    for (let i = 0; i < object.children.length; i++) {
      this._process_after_load(object.children[i])
    }
  }

  process_after_load = () => {
    this.collect_syn_materials()
    for (let i = 0; i < this.scene.children.length; i++) {
      this._process_after_load(this.scene.children[i])
    }
    ///exhibition绑定
    if (this.exhibition && this.exhibition.objects_ids) {
      this.exhibition.objects = []
      for (let i = 0; i < this.exhibition.objects_ids.length; i++) {
        let obj = this.search_obj_by_guid2(this.exhibition.objects_ids[i].uuid)
        if (obj) this.exhibition.objects.push(obj)
      }
    }
  }

  load_model = (mdl_url) => {
    var xhr = new XMLHttpRequest()
    xhr.open('get', mdl_url, true)
    xhr.responseType = 'json'
    var o2 = this
    xhr.onload = function() {
      if (this.status == 200) {
        var model_obj = this.response
        o2.load_mesh_url(model_obj['mesh'], model_obj)
      }
    }
    xhr.send()
  }

  renew_all_uuid = (obj) => {
    let obj2 = new THREE.Object3D()
    obj.uuid = obj2.uuid
    for (let i = 0; i < obj.children.length; i++) {
      this.renew_all_uuid(obj.children[i])
    }
  }
  _dispose_object(object) {
    if (object) {
      if (object.material) {
        if (object.material.map) object.material.map.dispose()
        if (object.material.lightMap) object.material.lightMap.dispose()
        if (object.material.alphaMap) object.material.alphaMap.dispose()
        if (object.material.aoMap) object.material.aoMap.dispose()
        if (object.material.envMap) object.material.envMap.dispose()
        object.material.dispose()
      }
      if (object.geometry) object.geometry.dispose()
      for (let i = 0; i < object.children.length; i++) {
        this._dispose_object(object.children[i])
      }
    }
  }
  dispose_all = () => {
    if (this.scene) {
      for (let i = 0; i < this.scene.children.length; i++) {
        this._dispose_object(this.scene.children[i])
      }
    }
  }
  reset_scene() {
    // scene
    //var JSZip = require("jszip");
    this.dispose_all()
    this.cubemaps = []
    this.gif_anis = [] //序列帧动画
    this.same_texture_count = 0
    this.same_mesh_count = 0
    this.div_bind = []
    this.cameras = []
    var scene = this.scene
    this.scene.children = []
    this.objects = []
    this.materials = []
    this.material_buffer = null //材质刷
    this.maps = {}
    this.envmaps = {}
    this.selection = null
    this.import_root = null
    this.skeletons = [] //声明骨骼动画
    this.renderer.toneMapping = 0
    this.renderer.toneMappingExposure = 1
    //light
    // var ambientLight = new THREE.PointLight( 0xffffff, 1,1000 );
    // ambientLight.name="AmbientLight";
    // scene.add( ambientLight );

    const grid = new THREE.GridHelper(10000, 40, 0x000000, 0x000000)
    grid.name = 'grid'
    grid.material.opacity = 0.3
    grid.material.transparent = true
    this.grid = grid
    this.scene.add(grid)
    // this.grid = grid;
    //env
    // var urls = [
    //     './static/textures/cube/Park2/posx.jpg', './static/textures/cube/Park2/negx.jpg',
    //     './static/textures/cube/Park2/posy.jpg', './static/textures/cube/Park2/negy.jpg',
    //     './static/textures/cube/Park2/posz.jpg', './static/textures/cube/Park2/negz.jpg'
    // ];
    // var reflectionCube = new THREE.CubeTextureLoader().load( urls );
    // reflectionCube.format = THREE.RGBFormat;
    scene.background = new THREE.Color(0.98, 0.98, 0.98)
    this.cubemap = null

    // //lights
    // const width = 500;
    // const height = 500;
    // const intensity = 10;
    // const blueRectLight = new THREE.RectAreaLight( 0xf3aaaa, intensity, width, height );
    // blueRectLight.position.set( 0, 500, 0 );
    // blueRectLight.lookAt( 0, 5, 0 );
    // this.scene.add( blueRectLight );

    // const blueRectLightHelper = new RectAreaLightHelper( blueRectLight );
    // blueRectLight.add( blueRectLightHelper );
  }

  import_object_url = (url) =>
    new Promise((resolve, reject) => {
      let zip_info = {}
      zip_info.zip = new JSZip()
      zip_info.from_zip = true
      zip_info.all_files = {}
      let o2 = this
      let obj
      let item_file
      var on_unzipped = function() {
        if (item_file != undefined) {
          o2.from_zip = true
          var rt = item_file
          var xxx = JSON.parse(rt)
          //  console.log(xxx);
          /////////////////
          const allRequest = []
          let model_obj = xxx
          model_obj.from_zip = true
          var promise = o2.load_saved_object(model_obj, true, zip_info)
          let timelines = []
          if (model_obj.timelines) {
            timelines = o2.animation.from_json_export(model_obj.timelines)
          }
          allRequest.push(promise)
          promise.then((obj3) => {
            if (obj3) {
              if (model_obj.lib_uuid) obj3.lib_uuid = model_obj.lib_uuid

              obj3.timelines = timelines
              //绑定元素
              o2.animation.bind_objects_export(timelines, obj3)
              o2.renew_all_uuid(obj3)
              obj = obj3
            }
          })
          Promise.all(allRequest).then(
            () => {
              o2.from_zip = false
              resolve(obj)
            },
            () => {
              o2.from_zip = false
              reject()
            }
          )
        }
      }

      var xhr = new XMLHttpRequest()
      xhr.open('get', url, true)
      xhr.responseType = 'blob'
      xhr.onload = function() {
        if (this.status == 200) {
          var xxx = this.response
          zip_info.zip.loadAsync(xxx).then(function(zip) {
            var counts = 0
            zip.forEach(function(v) {
              counts++
              var type_read = 'arraybuffer'
              if (v.substr(v.length - 4, v.length) != 'json') {
                type_read = 'arraybuffer'
              }
              if (v == 'item.json') {
                type_read = 'string'
              }
              if (zip_info.zip.file(v)) {
                zip_info.zip
                  .file(v)
                  .async(type_read)
                  .then(function(content) {
                    if (v == 'item.json') {
                      item_file = content
                    }
                    zip_info.all_files[v] = content
                    counts--
                    if (counts == 0) {
                      on_unzipped()
                    }
                  })
              } else {
                counts--
              }
            })
          })
        }
      }
      xhr.send()
    })

  load_common_scene = (xxx, from_zip, zip_info) => {
    //全屏特效
    let o2 = this
    //全景图
    if (xxx.scene.cubemaps) {
      console.log('cubemaps')
      for (let i = 0; i < xxx.scene.cubemaps.length; i++) {
        let c = xxx.scene.cubemaps[i]
        let cube = {}
        cube.name = c.name
        cube.type = c.type
        if (c.url) {
          cube.texture = o2.create_envMap(c.url, from_zip, zip_info)
          if (cube.texture) {
            cube.texture.cube_info = c
          }
        }
        o2.cubemaps.push(cube)
      }
    }
    //全屏特效
    if (xxx.scene.postEffect) {
      o2.postEffect = xxx.scene.postEffect
      o2.postEffect.from_zip = from_zip
      if (zip_info && o2.postEffect.lutFile && o2.postEffect.lutFile != '') {
        o2.postEffect.lutBlobFile = zip_info.all_files[o2.postEffect.lutFile]
        if (!o2.postEffect.lutBlobFil) {
          let arr = Object.keys(zip_info.all_files)
          var name = arr.filter((item) => item.includes('CUBE'))[0]
          if (!name) o2.postEffect.lut = false
          else o2.postEffect.lutBlobFile = zip_info.all_files[name]
        }
        o2.postEffect.lutBlob = new Blob([o2.postEffect.lutBlobFile])
        o2.postEffect.lutFile = URL.createObjectURL(o2.postEffect.lutBlob)
      }
      o2.create_render_pass()
    }
    if (xxx.scene.camera) {
      let scene = xxx.scene
      o2.camera.near = scene.camera.near
      o2.camera.far = scene.camera.far
      o2.camera.fov = scene.camera.fov
      o2.camera.position.set(scene.camera.position.x, scene.camera.position.y, scene.camera.position.z)
      o2.cameraControls.target.set(scene.camera.target.x, scene.camera.target.y, scene.camera.target.z)
      o2.camera.updateProjectionMatrix()
    }
    if (xxx.scene.toneMappingExposure != null) {
      o2.renderer.toneMappingExposure = xxx.scene.toneMappingExposure
    }
    //scene_id
    o2.scene.scene_id = xxx.scene.scene_id
    if (o2.scene.scene_id == null) {
      let abc = new THREE.Object3D()
      o2.scene.scene_id = abc.uuid
    }
    if (xxx.scene.bake_step) {
      o2.scene.bake_step = xxx.scene.bake_step
    } else {
      o2.scene.bake_step = 1
    }
    //场景背景
    let obj = xxx.scene.background
    if (obj.type == 0) {
      if (o2.scene.background.constructor == THREE.Color) {
        o2.scene.background.setHex(obj.color)
      } else {
        o2.scene.background = new THREE.Color()
        o2.scene.background.setHex(obj.color)
      }
    }
    if (obj.type == 1) {
      let map = o2.create_map(obj.map, zip_info)
      if (map != null) {
        map.wrapS = THREE.RepeatWrapping
        map.wrapT = THREE.RepeatWrapping
        o2.scene.background = map
      }
    }
    if (obj.type == 2) {
      o2.scene.background = o2.create_envMap(obj.envMap, from_zip, zip_info)
      if (o2.scene.background && !o2.scene.background.cube_info) {
        let cube = {}
        cube.name = '天空盒'
        cube.type = '正常'
        cube.texture = o2.scene.background
        o2.cubemaps.push(cube)
      }
      o2.cubemap = o2.scene.background
    }
    //动画模块
    o2.animation.from_json(xxx.scene.animation)
    //展馆模块
    if (xxx.scene.exhibition) {
      o2.exhibition = {}
      o2.exhibition.objects_ids = xxx.scene.exhibition.objects
    }
  }
  // load_zip_scene_old = (url) =>
  //   new Promise((resolve, reject) => {
  //     this.reset_scene();
  //     this.grid.visible = false;
  //     this.project_url = url.substring(0, url.lastIndexOf('/') + 1)
  //     if (!this.zip) {
  //       this.zip = new JSZip()
  //     }
  //     this.from_zip = true
  //     this.all_files = {}
  //     let o2 = this
  //     var on_unzipped = function () {
  //       //console.log("解压完成，加载模型...");
  //       //console.log(o2.all_files);
  //       if (o2.all_files['scene.json'] != undefined) {
  //         var rt = o2.all_files['scene.json']
  //         var xxx = JSON.parse(rt)
  //         o2.load_common_scene(xxx, true)
  //         /////////////////
  //         const allRequest = []
  //         var mdls = xxx['children']
  //         for (let index = 0; index < mdls.length; index++) {
  //           const model_obj = mdls[index]
  //           model_obj.from_zip = true
  //           var promise = o2.load_saved_object(model_obj, true)
  //           promise.then((obj3) => {
  //             if (obj3) o2.scene.add(obj3)
  //           })
  //           allRequest.push(promise)
  //         }
  //         if (o2.on_loading_msg) {
  //           o2.on_loading_msg('加载完成', 100)
  //         }
  //         if (o2.onload) {
  //           o2.onload()
  //         }

  //         Promise.all(allRequest).then(
  //           () => {
  //             resolve()
  //             o2.animation.bind_objects()
  //             o2.process_after_load()
  //             if (o2.script['init']) {
  //               o2.script['init']()
  //               o2.cameraControls.minDistance = Math.max(
  //                 o2.cameraControls.minDistance,
  //                 10
  //               )
  //             }
  //           },
  //           () => {
  //             reject()
  //           }
  //         )
  //       }
  //     }
  //     // let script_url = this.project_url+"/script.js";
  //     // loadJs(script_url,function(){console.log('script load done');});
  //     var xhr = new XMLHttpRequest()
  //     xhr.open('get', url, true)
  //     xhr.responseType = 'blob'
  //     xhr.addEventListener(
  //       'progress',
  //       function (event) {
  //         // 响应头要有Content-Length
  //         if (event.lengthComputable) {
  //           let percentComplete = event.loaded / event.total
  //           //console.log(percentComplete); // 最后输出1
  //           if (o2.on_loading_msg) {
  //             o2.on_loading_msg(
  //               '正在下载:',
  //               String(Math.ceil(percentComplete * 99)),
  //               '%'
  //             )
  //           }
  //         }
  //       },
  //       false
  //     )
  //     xhr.onload = function () {
  //       if (this.status == 200) {
  //         var xxx = this.response
  //         o2.zip.loadAsync(xxx).then(function (zip) {
  //           //console.log(zip);
  //           var counts = 0
  //           zip.forEach(function (v) {
  //             //console.log(v);
  //             counts++
  //             var type_read = 'arraybuffer'
  //             if (v.substr(v.length - 4, v.length) != 'json') {
  //               //console.log(v);
  //               type_read = 'arraybuffer'
  //             }
  //             if (v == 'scene.json') {
  //               type_read = 'string'
  //             }
  //             if (o2.zip.file(v)) {
  //               o2.zip
  //                 .file(v)
  //                 .async(type_read)
  //                 .then(function (content) {
  //                   //console.log(v,content);
  //                   o2.all_files[v] = content
  //                   counts--

  //                   if (counts == 0) {
  //                     on_unzipped()
  //                   }
  //                 })
  //             } else {
  //               counts--
  //               //console.log(v)
  //             }
  //           })
  //         })
  //       }
  //     }
  //     xhr.send()
  //   })

  copy_saved_param(obj, param) {
    if (param.bColliTest) obj.bColliTest = true
    if (param.bLock) obj.bLock = true
    if (param.bPickable != null) obj.bPickable = param.bPickable
    if (param.common_mesh) obj.common_mesh=param.common_mesh;
  }
  load_zip_scene = (url) =>
    new Promise((resolve, reject) => {
      this.reset_scene()
      this.grid.visible = false
      this.project_url = url.substring(0, url.lastIndexOf('/') + 1)
      let zip_info = {}
      zip_info.zip = new JSZip()
      zip_info.from_zip = true
      zip_info.all_files = {}
      let o2 = this
      var on_unzipped = function() {
        if (zip_info.all_files['scene.json'] != undefined) {
          var rt = zip_info.all_files['scene.json']
          var xxx = JSON.parse(rt)
          o2.load_common_scene(xxx, true, zip_info)
          /////////////////
          const allRequest = []
          var mdls = xxx['children']
          for (let index = 0; index < mdls.length; index++) {
            const model_obj = mdls[index]
            model_obj.from_zip = true
            var promise = o2.load_saved_object(model_obj, true, zip_info)
            promise.then((obj3) => {
              o2.copy_saved_param(obj3, model_obj)
              if (obj3) o2.scene.add(obj3)
            })
            allRequest.push(promise)
          }
          if (o2.on_loading_msg) {
            o2.on_loading_msg('加载完成', 100)
          }
          if (o2.onload) {
            o2.onload()
          }

          Promise.all(allRequest).then(
            () => {
              resolve()
              o2.animation.bind_objects()
              o2.process_after_load()
              if (o2.script['init']) {
                o2.script['init']()
                o2.cameraControls.minDistance = Math.max(o2.cameraControls.minDistance, 10)
              }
            },
            () => {
              reject()
            }
          )
        }
      }

      var xhr = new XMLHttpRequest()
      xhr.open('get', url, true)
      xhr.responseType = 'blob'
      xhr.addEventListener(
        'progress',
        function(event) {
          // 响应头要有Content-Length
          if (event.lengthComputable) {
            let percentComplete = event.loaded / event.total
            //console.log(percentComplete); // 最后输出1
            if (o2.on_loading_msg) {
              o2.on_loading_msg('正在下载:', String(Math.ceil(percentComplete * 99)), '%')
            }
          }
        },
        false
      )
      xhr.onload = function() {
        if (this.status == 200) {
          var xxx = this.response
          zip_info.zip.loadAsync(xxx).then(function(zip) {
            var counts = 0
            zip.forEach(function(v) {
              counts++
              var type_read = 'arraybuffer'
              if (v.substr(v.length - 4, v.length) != 'json') {
                type_read = 'arraybuffer'
              }
              if (v == 'scene.json') {
                type_read = 'string'
              }
              if (zip_info.zip.file(v)) {
                zip_info.zip
                  .file(v)
                  .async(type_read)
                  .then(function(content) {
                    //console.log(v,content);
                    zip_info.all_files[v] = content
                    counts--
                    if (counts == 0) {
                      on_unzipped()
                    }
                  })
              } else {
                counts--
              }
            })
          })
        }
      }
      xhr.send()
    })

  load_o2_scene = (url) =>
    new Promise((resolve, reject) => {
      this.reset_scene()
      this.grid.visible = false
      this.from_zip = false
      this.project_url = url.substring(0, url.lastIndexOf('/') + 1)
      //console.log(this.project_url);
      var xhr = new XMLHttpRequest()
      let o2 = this
      xhr.open('get', url, true)
      xhr.responseType = 'text'
      xhr.send()

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status >= 200 && xhr.status < 300) {
            let aaa = xhr.response
            //console.log(aaa);
            var xxx = JSON.parse(aaa)
            if (xxx.type == 'save_project') {
              /////////////////
              o2.load_common_scene(xxx, false)
              // if (xxx.scene.postEffect) {
              //     o2.postEffect = xxx.scene.postEffect;
              //     o2.create_render_pass();
              // }
              // o2.scene.scene_id = xxx.scene.scene_id;
              // if (o2.scene.scene_id == null) {
              //     let abc = new THREE.Object3D;
              //     o2.scene.scene_id = abc.uuid;
              // }
              // if (xxx.scene.bake_step) {
              //     o2.scene.bake_step = xxx.scene.bake_step;
              // } else {
              //     o2.scene.bake_step = 1;
              // }
              // /////
              // this.animation.from_json(xxx.scene.animation);
              // let obj = xxx.scene.background;
              // if (obj.type == 0) {
              //     if (this.scene.background.constructor == THREE.Color) {
              //         this.scene.background.setHex(obj.color);
              //     } else {
              //         this.scene.background = new THREE.Color();
              //         this.scene.background.setHex(obj.color);
              //     }
              // }
              // if (obj.type == 1) {
              //     let map = this.create_map(obj.map);
              //     if (map != null) {
              //         map.wrapS = THREE.RepeatWrapping;
              //         map.wrapT = THREE.RepeatWrapping;
              //         this.scene.background = map;
              //     }
              // }
              // if (obj.type == 2) {
              //     this.scene.background = this.create_envMap(obj.envMap);
              //     this.cubemap = this.scene.background;
              // }
              /////
              const allRequest = []
              var mdls = xxx['children']
              for (let index = 0; index < mdls.length; index++) {
                const model_obj = mdls[index]
                var promise = o2.load_saved_object(model_obj, false)
                promise.then((obj3) => {
                  o2.copy_saved_param(obj3, model_obj)
                  if (obj3) o2.scene.add(obj3)
                })
                allRequest.push(promise)
              }
              Promise.all(allRequest).then(
                () => {
                  o2.animation.bind_objects()
                  o2.process_after_load()
                  if (o2.script['init']) {
                    o2.script['init']()
                  }
                  resolve()
                },
                () => {
                  reject()
                }
              )
            } else {
              //刚刚导出的scene.json
              o2.scene.background = o2.create_envMap(xxx['evn'].cube, false)
              o2.cubemap = o2.scene.background

              const allRequest = []
              var mdls = xxx['models']
              for (let index = 0; index < mdls.length; index++) {
                const model_obj = mdls[index]
                allRequest.push(o2.load_mesh_url(o2.project_url + model_obj['mesh'], model_obj))
              }
              o2.tree = xxx['tree']
              Promise.all(allRequest).then(
                () => {
                  o2.animation.bind_objects()
                  o2.process_after_load()
                  if (o2.script['init']) {
                    o2.script['init']()
                  }
                  resolve()
                },
                () => {
                  reject()
                }
              )
            }
          } else {
            reject(xhr.status)
          }
        }
      }
    })

  import_url = (url) =>
    new Promise((resolve, reject) => {
      this.project_url = url.substring(0, url.lastIndexOf('/') + 1)
      //console.log(this.project_url);
      var xhr = new XMLHttpRequest()
      let o2 = this
      xhr.open('get', url, true)
      xhr.responseType = 'text'
      xhr.send()
      let import_root = new THREE.Object3D()
      import_root.name = 'import'
      this.scene.add(import_root)
      this.import_root = import_root

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status >= 200 && xhr.status < 300) {
            let aaa = xhr.response
            //console.log(aaa);
            var xxx = JSON.parse(aaa)
            if (xxx.type == 'save_project') {
              /////
              this.animation.from_json(xxx.scene.animation)
              let obj = xxx.scene.background
              if (obj.type == 0) {
                if (this.scene.background.constructor == THREE.Color) {
                  this.scene.background.setHex(obj.color)
                } else {
                  this.scene.background = new THREE.Color()
                  this.scene.background.setHex(obj.color)
                }
              }
              if (obj.type == 1) {
                let map = this.create_map(obj.map)
                if (map != null) {
                  map.wrapS = THREE.RepeatWrapping
                  map.wrapT = THREE.RepeatWrapping
                  this.scene.background = map
                }
              }
              if (obj.type == 2) {
                this.scene.background = this.create_envMap(obj.envMap, false)
                this.cubemap = this.scene.background
              }
              /////
              const allRequest = []
              var mdls = xxx['children']
              for (let index = 0; index < mdls.length; index++) {
                const model_obj = mdls[index]
                var promise = o2.load_saved_object(model_obj, false)
                promise.then((obj3) => {
                  o2.copy_saved_param(obj3, model_obj)
                  if (obj3) import_root.add(obj3)
                })
                allRequest.push(promise)
              }
              Promise.all(allRequest).then(
                () => {
                  o2.animation.bind_objects()
                  if (o2.script['init']) {
                    o2.script['init']()
                  }
                  resolve()
                },
                () => {
                  reject()
                }
              )
            } else {
              const allRequest = []
              var mdls = xxx['models']
              for (let index = 0; index < mdls.length; index++) {
                const model_obj = mdls[index]
                allRequest.push(o2.load_mesh_url(o2.project_url + model_obj['mesh'], model_obj))
              }
              o2.tree = xxx['tree']
              Promise.all(allRequest).then(
                () => {
                  o2.animation.bind_objects()
                  if (o2.script['init']) {
                    o2.script['init']()
                  }
                  resolve()
                },
                () => {
                  reject()
                }
              )
            }
          } else {
            reject(xhr.status)
          }
        }
      }
    })

  create_envMap = (evn, from_zip, zip_info, onload) => {
    if (undefined == evn || evn == 'use_scene') {
      return this.cubemap
    }
    let map;// = this.envmaps[evn]
    if (map == null) {
      if (zip_info) {
        var urls2 = []
        var urls = ['image/' + evn + '/posx.jpg', 'image/' + evn + '/negx.jpg', 'image/' + evn + '/posy.jpg', 'image/' + evn + '/negy.jpg', 'image/' + evn + '/posz.jpg', 'image/' + evn + '/negz.jpg']
        for (let i = 0; i < 6; i++) {
          let fnn = urls[i]
          let f1 = zip_info.all_files[fnn]
          let blob = new Blob([f1], { type: 'image/jpg' })
          let url = URL.createObjectURL(blob)
          urls2.push(url)
        }
        map = new THREE.CubeTextureLoader().load(urls2, onload)
      } else if (from_zip) {
        let o2 = this
        var urls2 = []
        var urls = ['image/' + evn + '/posx.jpg', 'image/' + evn + '/negx.jpg', 'image/' + evn + '/posy.jpg', 'image/' + evn + '/negy.jpg', 'image/' + evn + '/posz.jpg', 'image/' + evn + '/negz.jpg']
        for (let i = 0; i < 6; i++) {
          let fnn = urls[i]
          let f1 = this.all_files[fnn]
          let blob = new Blob([f1], { type: 'image/jpg' })
          let url = URL.createObjectURL(blob)
          urls2.push(url)
        }
        map = new THREE.CubeTextureLoader().load(urls2, onload)
      } else {
        let o2 = this
        var urls = [
          o2.project_url + 'image/' + evn + '/posx.jpg',
          o2.project_url + 'image/' + evn + '/negx.jpg',
          o2.project_url + 'image/' + evn + '/posy.jpg',
          o2.project_url + 'image/' + evn + '/negy.jpg',
          o2.project_url + 'image/' + evn + '/posz.jpg',
          o2.project_url + 'image/' + evn + '/negz.jpg'
        ]
        map = new THREE.CubeTextureLoader().load(urls, onload)
      }
      map.cubename = evn
      map.format = THREE.RGBFormat
      this.envmaps[evn] = map
    }
    if (map == null) {
      map = this.cubemap
    }
    return map
  }

  load_image_to_texture = (map_url, url) => {
    const RGBFormat = 1022
    const RGBAFormat = 1023
    let texture = new THREE.Texture()
    if (this.unload_textures[map_url]) {
      //console.log("same image:" + (this.same_texture_count++) + "(" + map_url + ")");
      texture = this.unload_textures[map_url]
      return texture
    }
    this.unload_textures[map_url] = texture
    let o2 = this

    this.image_loader.load(url, (image) => {
      const isJPEG = url.search(/\.jpe?g($|\?)/i) > 0 || url.search(/^data\:image\/jpeg/) === 0

      if (document.o2.documentName === 'yaoming' && (this.isMobile() || localStorage.getItem('yaoming_mode') == 'low')) {
        console.log('手机 1/2 贴图')
        //11.24 1/2贴图尺寸加载
        let canvas = document.createElement('canvas')
        canvas.width = Math.ceil((image.width * 3) / 4)
        canvas.height = Math.ceil((image.height * 3) / 4)
        const drawingContext = canvas.getContext('2d')
        drawingContext.drawImage(image, 0, 0, canvas.width, canvas.height)
        let url2 = canvas.toDataURL('image/png')
        o2.image_loader.load(url2, function(img) {
          texture.raw_url = url
          texture.image = img
          texture.format = isJPEG ? RGBFormat : RGBAFormat
          texture.needsUpdate = true
          texture.name = map_url
          texture.wrapS = THREE.RepeatWrapping
          texture.wrapT = THREE.RepeatWrapping
          if (texture.copyed) {
            for (let i = 0; i < texture.copyed.length; i++) {
              let tex2 = texture.copyed[i]
              tex2.raw_url = url
              tex2.image = img
              tex2.format = isJPEG ? RGBFormat : RGBAFormat
              tex2.needsUpdate = true
              tex2.name = map_url
              tex2.wrapS = THREE.RepeatWrapping
              tex2.wrapT = THREE.RepeatWrapping
            }
          }
        })
      } else {
        texture.raw_url = url
        URL.revokeObjectURL(url)
        texture.image = image
        texture.format = isJPEG ? RGBFormat : RGBAFormat
        texture.needsUpdate = true
        texture.name = map_url
        texture.wrapS = THREE.RepeatWrapping
        texture.wrapT = THREE.RepeatWrapping
        if (texture.copyed) {
          for (let i = 0; i < texture.copyed.length; i++) {
            let tex2 = texture.copyed[i]
            tex2.raw_url = url
            tex2.image = image
            tex2.format = isJPEG ? RGBFormat : RGBAFormat
            tex2.needsUpdate = true
            tex2.name = map_url
            tex2.wrapS = THREE.RepeatWrapping
            tex2.wrapT = THREE.RepeatWrapping
          }
        }
      }
    })
    return texture
  }
  isMobile() {
    var u = navigator.userAgent
    var browser = {
      versions: {
        //移动终端浏览器版本信息
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
        iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
        iPad: u.indexOf('iPad') > -1, //是否iPad
        weixin: u.indexOf('MicroMessenger') > -1, //是否微信
        qq: u.match(/\sQQ/i) == ' qq' //是否QQ
      }
    }

    let mobile = false

    for (const key in browser.versions) {
      if (browser.versions[key]) {
        mobile = true
      }
    }

    return mobile
  }

  create_map_with_default = (map_obj, from_zip, zip_info) => {
    //新命名normal_map 旧命名map_normal
    if (map_obj && map_obj.o2_default) {
      return this.default_textures[map_obj.o2_default]
      // if (map_obj.o2_default == 'white') return this.map_white
      // if (map_obj.o2_default == 'alpha') return this.map_alpha
      // if (map_obj.o2_default == 'half_alpha') return this.half_alpha
      // if (map_obj.o2_default == 'red') return this.map_red
      // if (map_obj.o2_default == 'normal_map') return this.map_normal
    }
    let map
    if (map_obj) map = this.create_map(map_obj.name, zip_info)
    if (map == null) map = this.map_white
    return map
  }
  create_map = (map_url, zip_info) => {
    if (map_url == null || map_url == '') return null
    let absurl = false
    let url = this.project_url + map_url
    let mapurl = map_url
    mapurl.toLowerCase()
    if (mapurl.indexOf('http') == 0) {
      url = map_url
      absurl = true
    }
    let fnn = map_url
    let f1 = this.all_files[fnn]
    if (zip_info) {
      f1 = zip_info.all_files[fnn]
    }
    if (f1 && !absurl) {
      let blob = new Blob([f1], { type: 'image/jpg' })
      let url = URL.createObjectURL(blob)
      let map = this.load_image_to_texture(map_url, url)
      return map
    }
    let map = this.load_image_to_texture(mapurl, url)
    this.maps.map_url = map
    return map
  }

  create_render_pass() {
    if (this.renderer.customed_lut) {
      //修改过了threejs文件
      if (this.postEffect.lut) {
        this.renderer.toneMapping = 5
        if (this.postEffect.lutFile && this.postEffect.lutFile != '') {
          let url = this.postEffect.lutFile
          if (url.substr(0, 4) != 'http' && url.substr(0, 4) != 'blob') {
            url = this.project_url + url
          }
          let o2 = this
          new LUTCubeLoader().load(url, function(result) {
            o2.renderer.map_lut = result.texture
          })
        }
        this.update_all_mtl()
      } else {
        this.renderer.toneMapping = 0
        this.update_all_mtl()
      }
    }

    if (this.postEffect == null || this.postEffect.bDefaultRender) return

    //基础场景渲染
    const finalComposer = new EffectComposer(this.renderer)

    // const ssaaRenderPassP = new SSAARenderPass( this.scene, this.camera );
    // finalComposer.addPass( ssaaRenderPassP );
    // ssaaRenderPassP.sampleLevel=2;
    // this.ssaaRenderPass=ssaaRenderPassP;

    const renderScene = new RenderPass(this.scene, this.camera)
    finalComposer.addPass(renderScene)

    let postEffect = this.postEffect
    if (postEffect.glow) {
      const bloomPass = new UnrealBloomPass(new THREE.Vector2(this.WIDTH, this.HEIGHT), 1.5, 0.4, 0.85)
      bloomPass.threshold = postEffect.glow_threshold
      bloomPass.strength = postEffect.glow_strength
      bloomPass.radius = postEffect.glow_radius
      this.bloomPass = bloomPass
      finalComposer.addPass(bloomPass)
    }

    //Sobel
    if (this.postEffect.sobel) {
      // const effectGrayScale = new ShaderPass(LuminosityShader);
      // finalComposer.addPass(effectGrayScale);
      this.effectSobel = new ShaderPass(SobelOperatorShader2)
      this.effectSobel.uniforms['resolution'].value.x = this.WIDTH * window.devicePixelRatio
      this.effectSobel.uniforms['resolution'].value.y = this.HEIGHT * window.devicePixelRatio
      finalComposer.addPass(this.effectSobel)
    }

    //film
    if (this.postEffect.film) {
      finalComposer.addPass(new FilmPass(0.35, 0.025, 648, false))
    }

    //FXAA
    if (this.postEffect.fxaa) {
      // const pass = new SMAAPass( this.WIDTH * this.renderer.getPixelRatio(), this.HEIGHT * this.renderer.getPixelRatio() );
      // finalComposer.addPass( pass );

      this.fxaaPass = new ShaderPass(FXAAShader)
      const pixelRatio = this.renderer.getPixelRatio()
      this.fxaaPass.material.uniforms['resolution'].value.x = 1 / (this.WIDTH * pixelRatio)
      this.fxaaPass.material.uniforms['resolution'].value.y = 1 / (this.HEIGHT * pixelRatio)
      finalComposer.addPass(this.fxaaPass)
    }

    var u = navigator.userAgent
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端

    if (!this.renderer.customed_lut) {
      if (this.postEffect.lut) {
        this.lutPass = new LUTPass()
        this.lutPass.enabled = false
        if (!this.postEffect.lutPower) {
          this.postEffect.lutPower = 1
        }
        this.lutPass.intensity = this.postEffect.lutPower
        finalComposer.addPass(this.lutPass)
        let o2 = this
        if (!this.postEffect.lutFile) this.postEffect.lutFile = 'luts/Bourbon.CUBE'
        let url = this.postEffect.lutFile
        if (url.substr(0, 4) != 'http') {
          url = this.project_url + url
          if (postEffect.from_zip) {
            let f1 = this.all_files[this.postEffect.lutFile]
            let blob = new Blob([f1])
            url = URL.createObjectURL(blob)
          }
        }
        new LUTCubeLoader().load(url, function(result) {
          if (!isiOS) o2.lutPass.lut = result.texture
          //暂时全部用2D的，很多设备不支持
          // o2.lutPass.lut = result.texture3D;
          else o2.lutPass.lut = result.texture
          o2.lutPass.enabled = true
        })
      }
    }

    //outline
    if (this.postEffect.outline) {
      this.OutlinePass = new OutlinePass(new THREE.Vector2(this.WIDTH, this.HEIGHT), this.scene, this.camera)
      this.OutlinePass.visibleEdgeColor.set('#ffffff')
      this.OutlinePass.hiddenEdgeColor.set('#190a05')
      this.OutlinePass.edgeStrength = Number(5) //0.01-10
      this.OutlinePass.edgeGlow = Number(0.5) //0-1
      this.OutlinePass.edgeThickness = Number(2) //1-4
      finalComposer.addPass(this.OutlinePass)
    }
    //SSAO
    if (this.postEffect.ao) {
      let saoPass = new SAOPass(this.scene, this.camera, false, true)

      saoPass.params.saoIntensity = 0.002
      saoPass.params.saoKernelRadius = 20
      saoPass.params.saoScale = 1000

      if (this.postEffect.aoIntensity) saoPass.params.saoIntensity = this.postEffect.aoIntensity
      if (this.postEffect.aoRadius) saoPass.params.saoKernelRadius = this.postEffect.aoRadius
      if (this.postEffect.aoScale) saoPass.params.saoScale = this.postEffect.aoScale
      this.postEffect.aoIntensity = saoPass.params.saoIntensity
      this.postEffect.aoRadius = saoPass.params.saoKernelRadius
      this.postEffect.aoScale = saoPass.params.saoScale
      this.ao = saoPass
      finalComposer.addPass(this.ao)
    }

    this.finalComposer = finalComposer
  }
  init(container) {
    this.frame_count = 0
    this.image_loader = new THREE.ImageLoader()
    this.loaded_images = {}
    this.unload_textures = {}
    this.meshes = {}
    this.multi_selection = []
    this.last_time = Date.now()
    this.container = container //document.getElementById( 'container' );
    var WIDTH = this.container.clientWidth
    var HEIGHT = this.container.clientHeight
    this.WIDTH = WIDTH
    this.HEIGHT = HEIGHT
    this.maps = {}
    this.copy_maps = []
    //
    // let map = new THREE.TextureLoader().load( './static/textures/white.png' );
    this.default_textures = {}
    {
      const width = 1
      const height = 1
      const size = 1
      const data = new Uint8Array(3 * size)
      for (let i = 0; i < size; i++) {
        const stride = i * 3
        data[stride] = 255 //r;
        data[stride + 1] = 0 //g;
        data[stride + 2] = 0 //b;
      }
      let map = new THREE.DataTexture(data, width, height, THREE.RGBFormat)
      map.o2_default = 'red'
      this.map_red = map
      this.default_textures.red = map
    }
    {
      const width = 1
      const height = 1
      const size = 1
      const data = new Uint8Array(3 * size)
      for (let i = 0; i < size; i++) {
        const stride = i * 3
        data[stride] = 0 //r;
        data[stride + 1] = 255 //g;
        data[stride + 2] = 0 //b;
      }
      let map = new THREE.DataTexture(data, width, height, THREE.RGBFormat)
      map.o2_default = 'green'
      this.map_green = map
      this.default_textures.green = map
    }
    {
      const width = 1
      const height = 1
      const size = 1
      const data = new Uint8Array(3 * size)
      for (let i = 0; i < size; i++) {
        const stride = i * 3
        data[stride] = 0 //r;
        data[stride + 1] = 0 //g;
        data[stride + 2] = 255 //b;
      }
      let map = new THREE.DataTexture(data, width, height, THREE.RGBFormat)
      map.o2_default = 'blue'
      this.map_blue = map
      this.default_textures.blue = map
    }
    {
      const width = 1
      const height = 1
      const size = 1
      const data = new Uint8Array(3 * size)
      for (let i = 0; i < size; i++) {
        const stride = i * 3
        data[stride] = 0 //r;
        data[stride + 1] = 0 //g;
        data[stride + 2] = 0 //b;
      }
      let map = new THREE.DataTexture(data, width, height, THREE.RGBFormat)
      map.o2_default = 'black'
      this.map_black = map
      this.default_textures.black = map
    }
    {
      let map = new THREE.TextureLoader().load(require('../images/white.png'))
      map.wrapS = THREE.RepeatWrapping
      map.wrapT = THREE.RepeatWrapping
      map.filpX = false
      map.filpY = false
      map.needsUpdate = true
      map.o2_default = 'white'
      this.map_white = map
      this.default_textures.white = map
    }
    {
      let map = new THREE.TextureLoader().load(require('../images/alpha.png'))
      map.wrapS = THREE.RepeatWrapping
      map.wrapT = THREE.RepeatWrapping
      map.filpX = false
      map.filpY = false
      map.needsUpdate = true
      map.o2_default = 'alpha'
      this.map_alpha = map
      this.default_textures.alpha = map
    }
    {
      let map = new THREE.TextureLoader().load(require('../images/half_alpha.png'))
      map.wrapS = THREE.RepeatWrapping
      map.wrapT = THREE.RepeatWrapping
      map.filpX = false
      map.filpY = false
      map.needsUpdate = true
      map.o2_default = 'half_alpha'
      this.half_alpha = map
      this.default_textures.half_alpha = map
    }
    {
      let map = new THREE.TextureLoader().load(require('../images/normalmap.jpg'))
      map.wrapS = THREE.RepeatWrapping
      map.wrapT = THREE.RepeatWrapping
      map.filpX = false
      map.filpY = false
      map.needsUpdate = true
      map.o2_default = 'normal_map'
      this.map_normal = map
      this.default_textures.map_normal = map
      this.default_textures.normal_map = map
    }
    // renderer
    if (this.is_mobile) {
      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
        powerPreference: 'low-power'
      })
    } else {
      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true
      })
    }

    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.shadowMap.enabled = true
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
    //this.renderer.toneMapping = THREE.CustomToneMapping;
    this.renderer.toneMapping = 0
    this.renderer.toneMappingExposure = 1
    this.renderer.map_lut = this.map_white

    THREE.ShaderChunk.tonemapping_pars_fragment = THREE.ShaderChunk.tonemapping_pars_fragment.replace(
      'vec3 CustomToneMapping( vec3 color ) { return color; }',

      `
      uniform sampler2D lut_map;
      vec3 lutLookup2( sampler2D tex, float size, vec3 rgb ) {
        float sliceHeight = 1.0 / size;
        float yPixelHeight = 1.0 / ( size * size );
        // Get the slices on either side of the sample
        float slice = rgb.b * size;
        float interp = fract( slice );
        float slice0 = slice - interp;
        float centeredInterp = interp - 0.5;
  
        float slice1 = slice0 + sign( centeredInterp );
  
        // Pull y sample in by half a pixel in each direction to avoid color
        // bleeding from adjacent slices.
        float greenOffset = clamp( rgb.g * sliceHeight, yPixelHeight * 0.5, sliceHeight - yPixelHeight * 0.5 );
  
        vec2 uv0 = vec2(
          rgb.r,
          slice0 * sliceHeight + greenOffset
        );
        vec2 uv1 = vec2(
          rgb.r,
          slice1 * sliceHeight + greenOffset
        );
        vec3 sample0 = texture2D( tex, uv0 ).rgb;
        vec3 sample1 = texture2D( tex, uv1 ).rgb;
        return mix( sample0, sample1, abs( centeredInterp ) );
      }

      vec3 CustomToneMapping( vec3 color ) {
        
        vec4 val = vec4( color.rgb, 1 );
        vec4 lutVal;
        float pixelWidth = 1.0 / 32.0;
        float halfPixelWidth = 0.5 / 32.0;
        vec3 uvw = vec3( halfPixelWidth ) + val.rgb * ( 1.0 - pixelWidth );
        lutVal = vec4( lutLookup2( lut_map, 32.0, uvw ), val.a );
        return vec3( mix( val, lutVal, toneMappingExposure ).rgb );
      }`
    )

    // this.renderer.toneMapping = 4;
    // this.renderer.toneMappingExposure = 1;

    // this.renderer.outputEncoding = THREE.sRGBEncoding;
    // THREE.ShaderChunk.tonemapping_pars_fragment = THREE.ShaderChunk.tonemapping_pars_fragment.replace(
    //   'vec3 CustomToneMapping( vec3 color ) { return color; }',
    //   `#define Uncharted2Helper( x ) max( ( ( x * ( 0.15 * x + 0.10 * 0.50 ) + 0.20 * 0.02 ) / ( x * ( 0.15 * x + 0.50 ) + 0.20 * 0.30 ) ) - 0.02 / 0.30, vec3( 0.0 ) )
    //   float toneMappingWhitePoint = 1.0;
    //   vec3 CustomToneMapping( vec3 color ) {
    //     color *= toneMappingExposure;
    //     return saturate( Uncharted2Helper( color ) / Uncharted2Helper( vec3( toneMappingWhitePoint ) ) );
    //   }`
    // );

    this.renderer.setSize(WIDTH, HEIGHT)

    //css2drender
    // this.css3dRenderer = new CSS3DRenderer();
    // this.css3dRenderer.setSize(WIDTH,HEIGHT);
    // this.css3dRenderer.domElement.style.position = 'absolute';
    // this.css3dRenderer.domElement.style.top = 0;

    //scene
    var scene = new THREE.Scene()
    this.scene = scene
    // camera
    var VIEW_ANGLE = 60
    var ASPECT = this.WIDTH / this.HEIGHT
    var NEAR = 100
    var FAR = 500000
    this.camera = new THREE.PerspectiveCamera(VIEW_ANGLE, ASPECT, NEAR, FAR)
    this.camera.position.set(0, 3000, -5000)
    // camera control
    this.cameraControls = new OrbitControls(this.camera, this.renderer.domElement)
    this.cameraControls.target.set(0, 0, 0)
    this.cameraControls.enableZoom = true
    this.cameraControls.enableDamping = true
    this.cameraControls.rotateSpeed = 1
    this.cameraControls.minDistance = 100
    this.cameraControls.maxDistance = 500000
    //this.cameraControls.maxPolarAngle = Math.PI / 2 - Math.PI / 15;
    this.cameraControls.update()
    //camera fpscontrols
    this.fpsControls = new PointerLockControls(this.camera, this.renderer.domElement)
    //light

    //clock
    var clock = new THREE.Clock()
    this.clock = clock
    // reset scene
    this.reset_scene()
    this.create_render_pass()
    //FINAL
    if (0) {
      this.renderer2 = new THREE.WebGLRenderer({
        alpha: true
      })
      this.renderer2.setSize(WIDTH, HEIGHT / 2)
      this.container.appendChild(this.renderer2.domElement)
    }
    this.container.appendChild(this.renderer.domElement)

    //this.container.appendChild(this.css3dRenderer.domElement);
    window.addEventListener('resize', this.onWindowResize, false)
    this.animate()
    this.animate_skeleton()
  }

  render = () => {
    if (this.postEffect == null || this.postEffect.bDefaultRender) {
      this.renderer.render(this.scene, this.camera)
      //this.css3dRenderer.render(this.scene,this.camera);
      return
    }
    if (this.postEffect.glow && this.bloomComposer) {
      this.camera.layers.set(1)
      this.bloomComposer.render()
      this.camera.layers.set(0)
    }

    this.finalComposer.render()
  }
  init_cover = (canvas) => {
    let WIDTH = canvas.clientWidth
    let HEIGHT = canvas.clientHeight
    this.renderer2 = new THREE.WebGLRenderer({
      canvas: canvas,
      alpha: true
    })
    this.renderer2.setSize(WIDTH, HEIGHT)
  }
  onWindowResize = (size) => {
    var height = 0
    if (typeof size == 'number') height = size
    var WIDTH = this.container.clientWidth
    var HEIGHT = this.container.clientHeight - height

    this.WIDTH = WIDTH
    this.HEIGHT = HEIGHT
    this.camera.aspect = WIDTH / HEIGHT
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(WIDTH, HEIGHT)
    if (this.renderer2) this.renderer2.setSize(WIDTH, HEIGHT)
    if (this.finalComposer) this.finalComposer.setSize(WIDTH, HEIGHT)
    //this.css3dRenderer.setSize(WIDTH,HEIGHT);

    if (this.effectSobel) {
      this.effectSobel.uniforms['resolution'].value.x = WIDTH * window.devicePixelRatio
      this.effectSobel.uniforms['resolution'].value.y = HEIGHT * window.devicePixelRatio
    }

    if (this.fxaaPass) {
      const pixelRatio = this.renderer.getPixelRatio()
      this.fxaaPass.material.uniforms['resolution'].value.x = 1 / (WIDTH * pixelRatio)
      this.fxaaPass.material.uniforms['resolution'].value.y = 1 / (HEIGHT * pixelRatio)
    }
    if (this.bloomComposer) this.bloomComposer.setSize(WIDTH, HEIGHT)
    if (this.finalComposer) this.finalComposer.setSize(WIDTH, HEIGHT)
  }
  add_empty_object = () => {
    var obj = new THREE.Object3D()
    obj.name = 'Object'
    this.scene.add(obj)
  }
  load_mesh2 = (xxx) => {
    var intarray = new Uint32Array(xxx, 0, 5)
    var num_faces = intarray[0]
    var num_pos = intarray[1]
    var num_nor = intarray[2]
    var num_uv = intarray[3]
    var num_uv2 = intarray[4]
    var limits1 = new Float32Array(xxx, 20, 20)
    var posmin = []
    var posmax = []
    var posstep = []
    posmin[0] = limits1[0]
    posmin[1] = limits1[1]
    posmin[2] = limits1[2]
    posmax[0] = limits1[3]
    posmax[1] = limits1[4]
    posmax[2] = limits1[5]
    posstep[0] = (posmax[0] - posmin[0]) / 65535
    posstep[1] = (posmax[1] - posmin[1]) / 65535
    posstep[2] = (posmax[2] - posmin[2]) / 65535

    var normin = []
    var normax = []
    var norstep = []
    normin[0] = limits1[6]
    normin[1] = limits1[7]
    normin[2] = limits1[8]
    normax[0] = limits1[9]
    normax[1] = limits1[10]
    normax[2] = limits1[11]
    norstep[0] = (normax[0] - normin[0]) / 65535
    norstep[1] = (normax[1] - normin[1]) / 65535
    norstep[2] = (normax[2] - normin[2]) / 65535

    var uvmin = []
    var uvmax = []
    var uvstep = []
    uvmin[0] = limits1[12]
    uvmin[1] = limits1[13]
    uvmax[0] = limits1[14]
    uvmax[1] = limits1[15]
    uvstep[0] = (uvmax[0] - uvmin[0]) / 65535
    uvstep[1] = (uvmax[1] - uvmin[1]) / 65535

    var uv2min = []
    var uv2max = []
    var uv2step = []
    uv2min[0] = limits1[16]
    uv2min[1] = limits1[17]
    uv2max[0] = limits1[18]
    uv2max[1] = limits1[19]
    uv2step[0] = (uv2max[0] - uv2min[0]) / 65535
    uv2step[1] = (uv2max[1] - uv2min[1]) / 65535
    var ppos = new Float32Array(num_pos * 3)
    var pnor = new Float32Array(num_pos * 3)
    var puv = new Float32Array(num_pos * 2)
    var puv2 = new Float32Array(num_pos * 2)
    //console.log(model_json);
    var a1 = new Uint16Array(xxx, 100, num_pos * 3)
    var a2 = new Uint16Array(xxx, 100 + num_pos * 6, num_nor * 3)
    var a3 = new Uint16Array(xxx, 100 + num_pos * 6 + num_nor * 6, num_uv * 2)
    var a4 = new Uint16Array(xxx, 100 + num_pos * 6 + num_nor * 6 + num_uv * 4, num_uv2 * 2)
    var pfaces
    if (num_pos > 65535 && 0) {
      pfaces = new Uint32Array(xxx, 100 + num_pos * 6 + num_nor * 6 + num_uv * 4 + num_uv2 * 4, num_faces * 3)
    } else {
      pfaces = new Uint16Array(xxx, 100 + num_pos * 6 + num_nor * 6 + num_uv * 4 + num_uv2 * 4, num_faces * 3)
    }
    for (var i = 0; i < a1.length; i++) {
      var idx = i % 3
      ppos[i] = posmin[idx] + a1[i] * posstep[idx]
    }
    var snor = []
    snor[0] = 0
    snor[1] = 1
    snor[2] = 0
    for (var i = 0; i < a1.length; i++) {
      var idx = i % 3
      pnor[i] = normin[idx] + a2[i] * norstep[idx]
    }
    for (var i = 0; i < a3.length; i++) {
      var idx = i % 2
      puv[i] = uvmin[idx] + a3[i] * uvstep[idx]
      // puv[i]=a3[i];
    }
    for (var i = 0; i < a4.length; i++) {
      var idx = i % 2
      puv2[i] = uv2min[idx] + a4[i] * uv2step[idx]
      // puv2[i]=a4[i];
    }

    var geometry = new THREE.BufferGeometry()
    geometry.setAttribute('position', new THREE.BufferAttribute(ppos, 3))
    if (pnor.length > 0) geometry.setAttribute('normal', new THREE.BufferAttribute(pnor, 3))
    if (puv.length > 0) geometry.setAttribute('uv', new THREE.BufferAttribute(puv, 2))
    if (puv2.length > 0) geometry.setAttribute('uv2', new THREE.BufferAttribute(puv2, 2))
    var ba = new THREE.BufferAttribute(pfaces, 1, true)
    geometry.setIndex(ba)
    geometry.computeBoundingBox()
    var uvs = geometry.attributes.uv.array
    for (var i = 0; i < uvs.length; i += 2) {
      //uvs[ i ] += 1;
      uvs[i + 1] += 1
    }

    return geometry
  }
  load_mesh_line = (xxx) => {
    var intarray = new Uint32Array(xxx, 0, 1)
    var num_pos = intarray[0]
    var limits1 = new Float32Array(xxx, 4, 6)
    var posmin = []
    var posmax = []
    var posstep = []
    posmin[0] = limits1[0]
    posmin[1] = limits1[1]
    posmin[2] = limits1[2]
    posmax[0] = limits1[3]
    posmax[1] = limits1[4]
    posmax[2] = limits1[5]
    posstep[0] = (posmax[0] - posmin[0]) / 65535
    posstep[1] = (posmax[1] - posmin[1]) / 65535
    posstep[2] = (posmax[2] - posmin[2]) / 65535

    var ppos = new Float32Array(num_pos * 3)
    var a1 = new Uint16Array(xxx, 28, num_pos * 3)
    for (var i = 0; i < a1.length; i++) {
      var idx = i % 3
      ppos[i] = posmin[idx] + a1[i] * posstep[idx]
    }
    var geometry = new THREE.BufferGeometry()
    geometry.setAttribute('position', new THREE.BufferAttribute(ppos, 3))
    geometry.computeBoundingBox()
    var mtl = new THREE.LineBasicMaterial({ color: 0x000000 })
    var mdl = new THREE.LineSegments(geometry, mtl)
    return mdl
  }
  load_mesh = (bb, model_obj) => {
    return new Promise((resolve, reject) => {
      var xxx = bb
      if (xxx == undefined) {
        reject()
      }
      var o2 = this
      let geometry = this.load_mesh2(xxx)
      geometry.meshid = model_obj['mesh']
      let map = o2.map_white
      if (model_obj.mtl && model_obj.mtl.map && model_obj.mtl.map.image && model_obj.mtl.map.image != '') {
        map = o2.create_map(model_obj.mtl.map.image)
        map.wrapS = THREE.RepeatWrapping
        map.wrapT = THREE.RepeatWrapping
        map.repeat.set(model_obj.mtl.map.repeat[0], model_obj.mtl.map.repeat[1])
        map.offset.set(model_obj.mtl.map.offset[0], -model_obj.mtl.map.offset[1])
        map.rotation = model_obj.mtl.map.rotation
      }
      let map2 = o2.map_white
      if (model_obj.bake_tex != '') {
        map2 = o2.create_map(model_obj.bake_tex)
      }
      let material = new THREE.MeshPhongMaterial({ map: map, lightMap: map2 })
      material.o2id = model_obj.mtl.id
      o2.materials.push({ id: material.o2id, mtl: material })
      if (model_obj.cullMode == 1) {
        material.side = THREE.BackSide
      } else if (model_obj.cullMode == 2) {
        material.side = THREE.DoubleSide
      } else {
        material.side = THREE.FrontSide
      }
      material.transparent = model_obj.mtl.transparent
      material.alphaTest = model_obj.mtl.alphaTest
      if (model_obj.mtl.transparent) {
        material.opacity = model_obj.mtl.alpha
      }
      material.reflectivity = 0
      //material.envMap=o2.cubemap;
      material.envMap = o2.create_envMap(model_obj.mtl.envMap)

      if (model_obj.aoTex && model_obj.aoTex != '') {
        let map3 = o2.create_map(model_obj.aoTex)
        if (!model_obj.bake_tex || model_obj.bake_tex == '') {
          material.lightMap = map3
        } else {
          material.aoMap = map3
        }
      }
      material.color = new THREE.Color(model_obj.mtl.dif_color)
      //console.log(model_obj);
      //new THREE.MeshBasicMaterial( { color: 0xff0000 } )
      var mesh = new THREE.Mesh(geometry, material)
      mesh.name = model_obj.name
      mesh.uuid = model_obj.uuid
      var mat = new THREE.Matrix4()
      mat.elements = model_obj.wts
      mesh.applyMatrix4(mat)
      if (o2.import_root != null) {
        o2.import_root.add(mesh)
      } else {
        o2.scene.add(mesh)
      }
      //return geometry;
      resolve(geometry)
    })
  }

  // 单击事件
  // onSceneClick = event => {
  //     event.preventDefault();
  //     // var dx = downx - event.offsetX;
  //     // var dy = downy - event.offsetY;
  //     var dx = event.offsetX;
  //     var dy = event.offsetY;
  //     //if (Math.sqrt(dx * dx + dy * dy) > 4) return;
  //     this.mouse.x = (event.offsetX / this.WIDTH) * 2 - 1;
  //     this.mouse.y = - (event.offsetY / (this.HEIGHT)) * 2 + 1;
  //     this.mouse_down = this.mouse - this.mouse_down;
  //     this.removeEdgeLine();
  //     this.unhover_all();

  //     console.log('3333')
  //     console.log(this)
  //     console.log(event.offsetX)
  //     console.log(this.WIDTH)

  //     this.raycaster.setFromCamera(this.mouse, this.camera);
  //     //pick
  //     let allobjs = this.scene.children;
  //     var intersects = this.raycaster.intersectObjects(allobjs);
  //     console.log(intersects)
  //     if (intersects.length > 0) {
  //         console.log('picked')
  //         var obj = intersects[0].object;
  //         return obj;
  //     }
  // }

  getObjById = (id) => {
    if (this.scene.children == undefined || this.scene.children.length <= 0) {
      return null
    }

    let array = this.scene.children
    for (let index = 0; index < array.length; index++) {
      const element = array[index]
      if (element.id == id) {
        return element
      }
    }
  }
  bind_div_to_world = (posx, posy, posz, earthDiv) => {
    if (earthDiv) {
      let bind = {}
      bind.div = earthDiv
      bind.type = 0
      bind.position = new THREE.Vector3(posx, posy, posz)
      o2.div_bind.push(bind)
    }
  }

  search_obj_by_guid2 = (uuid) => {
    for (let i = 0; i < this.scene.children.length; i++) {
      var found = this.search_obj_by_guid(this.scene.children[i], uuid)
      if (found) {
        return found
      }
    }
    return null
  }
  search_obj_by_guid = (target, uuid) => {
    if (target.uuid == uuid) {
      return target
    }
    for (let i = 0; i < target.children.length; i++) {
      var found = this.search_obj_by_guid(target.children[i], uuid)
      if (found) {
        return found
      }
    }
    return null
  }
  _search_obj_by_name = (target, name) => {
    if (target.name == name) {
      return target
    }
    for (let i = 0; i < target.children.length; i++) {
      var found = this._search_obj_by_name(target.children[i], name)
      if (found) {
        return found
      }
    }
    return null
  }

  search_obj_by_name = (name) => {
    for (let i = 0; i < this.scene.children.length; i++) {
      var found = this._search_obj_by_name(this.scene.children[i], name)
      if (found) {
        return found
      }
    }
    return null
  }
  ///

  //20210927 by wmf
  load_skeleton_animate = (skeleton_url, name, callback) => {
    var loader = new FBXLoader() //创建一个FBX加载器
    var that = this
    loader.load(skeleton_url, function(obj) {
      console.log(obj)
      obj.name = name
      obj.animations = []
      obj.traverse(function(child) {
        if (child.isMesh) {
          child.castShadow = true
          child.receiveShadow = true
        }
        if (child.isLight) child.visible = false
      })

      // obj作为参数创建一个混合器，解析播放obj及其子对象包含的动画数据
      var mixer = new THREE.AnimationMixer(obj)
      obj.mixer=mixer;
      // var skeleton = { name: name, obj: obj, mixer: mixer }
      // that.skeletons.push(skeleton)
      if (callback) {
        callback(obj)
      }
    })
  }
  load_skeleton_clip = (clip_url, skeleton_obj, clipName, callback) => {
    var loader = new FBXLoader()
    loader.load(clip_url, (obj) => {
      if (skeleton_obj && obj.animations && obj.animations.length > 0) {
        if (!skeleton_obj.clips) {
          skeleton_obj.clips = {}
        }
        let anim = obj.animations[0]
        anim.name=clipName;
        //e.clips[clipName] = anim
        skeleton_obj.animations.push(anim)
        if (callback) {
          callback(anim)
        }
      }

      // this.skeletons.forEach((e) => {
      //   if (e.obj == skeleton_obj && obj.animations && obj.animations.length > 0) {
      //     if (!e.clips) {
      //       e.clips = {}
      //     }
      //     obj.animations[0].name=clipName;
      //     let anim = obj.animations[0]
      //     e.clips[clipName] = anim
      //     e.obj.animations.push(anim)
      //     if (callback) {
      //       callback()
      //     }
      //   }
      // })
    })
  }
  set_skeleton = (name, pos, scale, rot) => {
    var that = this
    that.skeletons.forEach((element) => {
      if (element['name'] == name) {
        if (pos) {
          element['obj'].position.set(pos.x, pos.y, pos.z)
        }
        if (scale) {
          element['obj'].scale.set(scale.x, scale.y, scale.z)
        }
        if (rot) {
          element['obj'].rotation.set(rot.x, rot.y, rot.z)
        }
        //
      }
    })
  }
  play_skeleton = (name, id, loop) => {
    var that = this
    that.skeletons.forEach((element) => {
      if (element['name'] == name) {
        // 查看动画数据
        //console.log(element["obj"].animations)
        if (element['mixer'] != null) {
          var AnimationAction = element['mixer'].clipAction(element['obj'].animations[id])
          // console.log(element["mixer"]);
          // AnimationAction.timeScale = 1; //默认1，可以调节播放速度
          if (loop) {
            AnimationAction.loop = THREE.LoopRepeat //不循环播放
          } else {
            AnimationAction.loop = THREE.LoopOnce
          }
          // AnimationAction.loop = THREE.LoopOnce; //不循环播放
          // AnimationAction.clampWhenFinished=true;//暂停在最后一帧播放的状态
          AnimationAction.play() //播放动画
        }
      }
    })
  }
  play_skeleton_clip = (object, clipName, loop) => {
    var that = this
    {
      let ani;
      for (let i=0;i<object.animations.length;i++)
      {
        let a2 = object.animations[i];
        if (a2.name==clipName)
        {
          ani=a2;
          break;
        }
      }

        if (ani) {
          let clip = ani;
          if (object.mixer && clip) {
            var action = object.mixer.clipAction(clip, object)
            if (object.curAction) {
              object.curAction.enabled = true
              object.curAction.crossFadeTo(action, 0.2)
            }
            action.enabled = true
            object.curAction = action
            // AnimationAction.timeScale = 1; //默认1，可以调节播放速度
            if (loop) {
              action.loop = THREE.LoopRepeat //不循环播放
              object.clip_name = clipName
            } else {
              action.loop = THREE.LoopOnce
            }
            action.play() //播放动画
          }
        }
        return;
    }
    that.skeletons.forEach((element) => {
      if (element['obj'] == object) {
        // 查看动画数据
        //console.log(clip)
        if (element.clips && element.clips[clipName]) {
          let clip = element.clips[clipName]
          if (element['mixer'] != null && clip) {
            var action = element['mixer'].clipAction(clip, element.obj)
            if (element.curAction) {
              element.curAction.enabled = true
              element.curAction.crossFadeTo(action, 0.2)
            }
            action.enabled = true
            element.curAction = action
            // AnimationAction.timeScale = 1; //默认1，可以调节播放速度
            if (loop) {
              action.loop = THREE.LoopRepeat //不循环播放
              object.clip_name = clipName
            } else {
              action.loop = THREE.LoopOnce
            }
            // AnimationAction.loop = THREE.LoopOnce; //不循环播放
            // AnimationAction.clampWhenFinished=true;//暂停在最后一帧播放的状态
            action.play() //播放动画
          }
        }
      }
    })
  }

  animate_skeleton = () => {
    requestAnimationFrame(this.animate_skeleton) //请求再次执行渲染函数render，渲染下一帧

    var that = this
    let passTime = that.clock.getDelta()
    // that.skeletons.forEach((element) => {
    //   if (element['mixer'] !== null) {
    //     //clock.getDelta()方法获得两帧的时间间隔
    //     element['mixer'].update(passTime)
    //     //console.log(that.clock.getDelta());
    //   }
    // })
    this.scene.traverse((obj)=>{
      if (obj.mixer) obj.mixer.update(passTime);
    })
  }

  rebuild_list_tree = () => {
    if (this.tree) {
      let _tree = this.tree
      for (var i in _tree) {
        let target = this.search_obj_by_guid(this.scene, _tree[i].uuid)
        if (target == null) {
          target = new THREE.Object3D()
          target.name = _tree[i].parent_name
          target.uuid = _tree[i].uuid
          target.type = 'Object3D'

          let mat0 = new THREE.Matrix4()
          mat0.elements = _tree[i].wts
          if (_tree[i].offset) {
            let offset = new THREE.Matrix4()
            offset.elements = _tree[i].offset
            mat0.multiply(offset)
          }
          target.applyMatrix4(mat0)

          this.scene.add(target)

          let _childs = _tree[i].child_guid
          for (var j in _childs) {
            let guid = _childs[j]
            let obj = this.search_obj_by_guid(this.scene, guid)
            if (obj && target) {
              // let mat1 = obj.matrixWorld.clone();
              // let mat = target.matrixWorld.clone();
              // mat.getInverse(mat);
              // mat.multiply(mat1);

              let mat1 = new THREE.Matrix4()
              mat1.elements = _tree[i].local[j]
              if (_tree[i].offset) {
                let offset = new THREE.Matrix4()
                offset.elements = _tree[i].offset
                var offsetinv = new THREE.Matrix4().getInverse(offset)
                mat1.premultiply(offsetinv)
              }
              obj.parent.remove(obj)
              target.add(obj)
              obj.position.set(0, 0, 0)
              obj.scale.set(1, 1, 1)
              obj.quaternion.set(0, 0, 0, 1)
              obj.applyMatrix4(mat1)
            }
          }
        }
      }

      this.tree = []
    }
  }
  get_video_tex(src, loop) {
    if (!this.video) {
      this.video = document.createElement('video')
      //this.video.setAttribute("type",'video/mp4; codecs="avc1.42E01E, mp4a.40.2"');
      this.video.setAttribute('style', 'display: none')
      this.video.setAttribute('crossOrigin', 'anonymous')
      this.video.preload = 'none'
      this.video.setAttribute('webkit-playsinline', 'true')
      this.video.setAttribute('playsinline', 'true')
      this.video.onload = () => {
        this.video.setAttribute('muted', 'false')
      }
    }
    this.video.setAttribute('src', src)
    this.video.load()
    if (loop) {
      this.video.setAttribute('loop', 'loop')
    } else {
      this.video.setAttribute('loop', '')
    }
    this.video.setAttribute('muted', 'true')
    let tex = new THREE.VideoTexture(this.video)
    tex.wrapS = THREE.RepeatWrapping
    tex.wrapT = THREE.RepeatWrapping
    this.video.play()
    this.videoTex = tex
    tex.needsUpdate = true
    return tex
  }
  shot() {
    let image = new Image()
    this.renderer.render(this.scene, this.camera) //renderer为three.js里的渲染器，scene为场景 camera为相机
    let imgData = this.renderer.domElement.toDataURL('image/jpeg') //这里可以选择png格式jpeg格式
    image.src = imgData
    return imgData
    //document.body.appendChild(image);//这样就可以查看截出来的图片了
  }

  stop_video_tex() {
    if (this.video) {
      this.video.pause()
    }
  }
  create_video_stream_tex(id) {
    if (!this.video_stream_data_list) {
      this.video_stream_data_list = []
    }
    let video_stream_data
    for (let i = 0; i < this.video_stream_data_list.length; i++) {
      let data = this.video_stream_data_list[i]
      if (data.id == id) {
        video_stream_data = data
        break
      }
    }
    if (!video_stream_data) {
      video_stream_data = {}
      video_stream_data.id = id
      video_stream_data.videoPlayer = document.createElement('video')
      video_stream_data.videoPlayer.setAttribute('style', 'display: none')
      video_stream_data.videoPlayer.setAttribute('crossOrigin', 'anonymous')
      video_stream_data.videoPlayer.preload = 'none'
      video_stream_data.videoPlayer.id = id
      video_stream_data.tex = new THREE.VideoTexture(this.video)
      video_stream_data.tex.wrapS = THREE.RepeatWrapping
      video_stream_data.tex.wrapT = THREE.RepeatWrapping
      this.video_stream_data_list.push(video_stream_data)
    }
    video_stream_data.videoPlayer.play()
    return video_stream_data.tex
  }
  stop_video_stream_tex(id) {
    if (this.video_stream_data_list) {
      let video_stream_data
      for (let i = 0; i < this.video_stream_data_list.length; i++) {
        let data = this.video_stream_data_list[i]
        if (data.id == id) {
          video_stream_data = data
          break
        }
      }
      if (video_stream_data) {
        video_stream_data.videoPlayer.pause()
        video_stream_data.tex.dispose()
        document.removeChild(video_stream_data.videoPlayer)
        this.video_stream_data.remove(video_stream_data)
        video_stream_data = undefined
      }
    }
  }
  show_renderer_stats() {
    if (!this.rendererStats) {
      this.create_renderer_stats()
    }
    this.rendererStats.bShow = !this.rendererStats.bShow
    if (this.rendererStats.bShow) {
      this.container.appendChild(this.rendererStats.domElement)
    } else {
      this.container.removeChild(this.rendererStats.domElement)
    }
  }
  create_renderer_stats() {
    //console.log("create renderer stats");
    this.rendererStats = this.RendererStats()
    this.rendererStats.bShow = false
    this.rendererStats.domElement.style.position = 'absolute'
    this.rendererStats.domElement.style.right = '0px'
    this.rendererStats.domElement.style.top = '0px'
  }
  RendererStats = function() {
    var msMin = 100
    var msMax = 0

    var container = document.createElement('div')
    container.style.cssText = 'width:80px;opacity:0.9;cursor:pointer'

    var msDiv = document.createElement('div')
    msDiv.style.cssText = 'padding:0 0 3px 3px;text-align:left;background-color:#200;'
    container.appendChild(msDiv)

    var msText = document.createElement('div')
    msText.style.cssText = 'color:#f00;font-family:Helvetica,Arial,sans-serif;font-size:9px;font-weight:bold;line-height:15px'
    msText.innerHTML = 'WebGLRenderer'
    msDiv.appendChild(msText)

    var msTexts = []
    var nLines = 9
    for (var i = 0; i < nLines; i++) {
      msTexts[i] = document.createElement('div')
      msTexts[i].style.cssText = 'color:#f00;background-color:#311;font-family:Helvetica,Arial,sans-serif;font-size:9px;font-weight:bold;line-height:15px'
      msDiv.appendChild(msTexts[i])
      msTexts[i].innerHTML = '-'
    }

    var lastTime = Date.now()
    return {
      domElement: container,

      update: function(webGLRenderer) {
        // sanity check
        console.assert(webGLRenderer instanceof THREE.WebGLRenderer)

        // refresh only 30time per second
        //if( Date.now() - lastTime < 1000/30 )	return;
        //lastTime	= Date.now()

        var i = 0
        msTexts[i++].textContent = '== Memory ====='
        msTexts[i++].textContent = 'Programs: ' + webGLRenderer.info.memory.programs
        msTexts[i++].textContent = 'Geometries: ' + webGLRenderer.info.memory.geometries
        msTexts[i++].textContent = 'Textures: ' + webGLRenderer.info.memory.textures

        msTexts[i++].textContent = '== Render ====='
        msTexts[i++].textContent = 'Calls: ' + webGLRenderer.info.render.calls
        msTexts[i++].textContent = 'Vertices: ' + webGLRenderer.info.render.vertices
        msTexts[i++].textContent = 'Faces: ' + webGLRenderer.info.render.faces
        //msTexts[i++].textContent = "Points: "	+ webGLRenderer.info.render.points;
        msTexts[i++].textContent = 'frame: ' + Math.floor(1000 / (Date.now() - lastTime))
        lastTime = Date.now()
      }
    }
  }
  _collect_syn_materials(map, object) {
    if (object.material && object.material.o2id && object.material.o2id != '') {
      let old = map.get(object.material.o2id)
      if (old) {
        old.push(object.material)
      } else {
        let news = []
        news.push(object.material)
        map.set(object.material.o2id, news)
      }
    }
    for (let i = 0; i < object.children.length; i++) {
      this._collect_syn_materials(map, object.children[i])
    }
  }
  collect_syn_materials = () => {
    let map1 = new Map()
    for (let i = 0; i < this.scene.children.length; i++) {
      this._collect_syn_materials(map1, this.scene.children[i])
    }
    this.synMaterials = new Map()
    for (let [key, value] of map1) {
      if (value.length > 1) {
        this.synMaterials.set(key, value)
      }
    }
  }
  _update_all_mtl(obj) {
    if (obj && obj.material) obj.material.needsUpdate = true
    for (let i = 0; i < obj.children.length; i++) {
      this._update_all_mtl(obj.children[i])
    }
  }
  update_all_mtl = () => {
    for (let i = 0; i < this.scene.children.length; i++) {
      this._update_all_mtl(this.scene.children[i])
    }
  }
  _load_skeleton_from_buffer(xxx,fun)
  {
    let o2=this;
    let headbuffer = new Uint32Array(xxx,0,3);
    let floatlen = headbuffer[1]/4;
    let intlen = headbuffer[2]/4;
    let float_array = new Float32Array(xxx,12,floatlen);
    let int_array = new Uint32Array(xxx,12+headbuffer[1],intlen);
    let a3 = new Int8Array(xxx,12+headbuffer[1]+headbuffer[2]);
    let decoder = new TextDecoder();
    let str = decoder.decode(a3);
    let jsonobj =JSON.parse(str);
    //恢复数据
    function load_buffer(a,float_array,int_array)
    {
      a.array=[];
      let size = a.data_size;
      if (a.type=="Float32Array")
      {
          for (let i=0;i<size;i++)
          {
              a.array.push(float_array[a.data_pos+i]);
          }
      } else
      if (a.type=="Uint16Array"||a.type=="Uint32Array")
      {
        for (let i=0;i<size;i++)
        {
            a.array.push(int_array[a.data_pos+i]);
        }
      }
    }
    if (jsonobj.geometries)
    {
        for (let i=0;i<jsonobj.geometries.length;i++)
        {
            let geo = jsonobj.geometries[i];
            for (let key in geo.data.attributes)
            {
                let a = geo.data.attributes[key];
                load_buffer(a,float_array,int_array);
            }
            if (geo.data.morphAttributes&&geo.data.morphAttributes.position)
            {
                for (let j=0;j<geo.data.morphAttributes.position.length;j++)
                {
                    let a = geo.data.morphAttributes.position[j];
                    load_buffer(a,float_array,int_array);
                }
            }
        }
    }
    if (jsonobj.skeletons)
    {
        for (let i=0;i<jsonobj.skeletons.length;i++)
        {
            let skeleton = jsonobj.skeletons[i];
            skeleton.boneInverses=[];
            let size = skeleton.bones.length;
            let idx = skeleton.data_pos;
            for (let j=0;j<size;j++)
            {
                let array=[]
                for (let k=0;k<16;k++)
                {
                  array.push(float_array[idx++]);
                }
                skeleton.boneInverses.push(array);
            }
        }
    }
    
    //console.log(jsonobj);
    var loader = new THREE.ObjectLoader();
    loader.parse(jsonobj,(obj3)=>{
      obj3.traverse((objj)=>{
        objj.uuid = o2.gen_uuid();
        if (objj.material)
        {
          obj3.bSkeleton=true;
          objj.material=new THREE.MeshPhongMaterial;
        }
      })
      fun(obj3);
    });
  }
  load_saved_skeleton = (url,zip_info) =>
  new Promise((resolve, reject) => {
    let o2=this;
    
    function loaded(obj3)
    {
      obj3.mixer =  new THREE.AnimationMixer(obj3); 
      obj3.clips=[];
      obj3.bNewSkeleton=true;
      obj3.url2=url;
      resolve(obj3);
    }
    if (zip_info)
    {
      let xxx = zip_info.all_files[url]
      o2._load_skeleton_from_buffer(xxx,loaded);
    }else{
      let mesh_url = o2.project_url+url;
      let xhr = new XMLHttpRequest()
      xhr.open('get', mesh_url, true)
      xhr.responseType = 'arraybuffer'
      xhr.send()
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status >= 200 && xhr.status < 300) {
            var xxx = xhr.response;
            o2._load_skeleton_from_buffer(xxx,loaded);
          }
        }
      }
    }
  })

  load_animation_clip= (url,zip_info) => 
  new Promise((resolve, reject) => {
    let o2=this;
    let mesh_url = o2.project_url+url;
    if (zip_info)
    {
      let f1 = zip_info.all_files[url];
      let blob = new Blob([f1])
      mesh_url = URL.createObjectURL(blob)
    }
      let xhr = new XMLHttpRequest()
      xhr.open('get', mesh_url, true)
      xhr.responseType = 'arraybuffer'
      xhr.send()
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          var xxx = xhr.response;
          if (xhr.status >= 200 && xhr.status < 300) {
              let headbuffer = new Uint32Array(xxx,0,3);
              let all_len = headbuffer[1]/4;
              let a2 = new Float32Array(xxx,12,all_len);
              let a3 = new Int8Array(xxx,12+headbuffer[1]);
              
              let decoder = new TextDecoder();
              let str = decoder.decode(a3);
              let aniobj =JSON.parse(str);
              for (let i=0;i<aniobj.animations.length;i++)
              {
                for (let j=0;j<aniobj.animations[i].tracks.length;j++)
                {
                  let t = aniobj.animations[i].tracks[j];
                  t.times=[];
                  t.values=[];
                  for (let k=0;k<t.size;k++)
                  {
                    t.times.push(a2[t.pos+k]);
                  }
                  if (t.type=="quaternion")
                  {
                    for (let k=0;k<t.size*4;k++)
                    {
                      t.values.push(a2[t.pos+t.size+k]);
                    }
                  }else{
                    for (let k=0;k<t.size*3;k++)
                    {
                      t.values.push(a2[t.pos+t.size+k]);
                    }
                  }
                }
              }
              //console.log(aniobj);
              var loader = new THREE.ObjectLoader();
              loader.parse(aniobj,(obj3)=>{
                resolve(obj3);
              });

            } else {
            reject(xhr.status)
          }
        }
        if (xhr.status === 404) {
          reject(xhr.status)
        }
      }
  })

  import_json_object = (json_object) => 
  new Promise((resolve, reject) => {
    //console.log(json_object);
    let o2=this;
    const allRequest = []
    for (let i=0;i<json_object.geometries.length;i++)
    {
      let pro = new Promise((resolve2, reject2)=> {
      let geo = json_object.geometries[i];
      let mesh_url = o2.project_url+geo.url;
      let xhr = new XMLHttpRequest()
      xhr.open('get', mesh_url, true)
      xhr.responseType = 'arraybuffer'
      xhr.send()
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          var xxx = xhr.response;
          if (xhr.status >= 200 && xhr.status < 300) {
            for (let key in geo.data.attributes)
            {
              let a = geo.data.attributes[key];
              //a.array2=a.array;
              if (a.type=="Float32Array")
              {
                let arr= new Float32Array(xxx, a.pos, a.size);
                a.array=[];
                for (let j=0;j<arr.length;j++)
                {
                    a.array.push(arr[j]);
                }
              }
              if (a.type=="Uint16Array")
              {
                let arr= new Uint16Array(xxx, a.pos, a.size);
                a.array=[];
                for (let j=0;j<arr.length;j++)
                {
                    a.array.push(arr[j]);
                }
              }
              if (a.type=="Uint32Array")
              {                
                let arr= new Uint32Array(xxx, a.pos, a.size);
                a.array=[];
                for (let j=0;j<arr.length;j++)
                {
                    a.array.push(arr[j]);
                }
              }
            }
            
            if (geo.data.morphAttributes&&geo.data.morphAttributes.position)
            {
                for (let j=0;j<geo.data.morphAttributes.position.length;j++)
                {
                    let a = geo.data.morphAttributes.position[j];
                    
                    if (a.type=="Float32Array")
                    {
                      let arr= new Float32Array(xxx, a.pos, a.size);
                      a.array=[];
                      for (let k=0;k<arr.length;k++)
                      {
                          a.array.push(arr[k]);
                      }
                    }
                    if (a.type=="Uint16Array")
                    {
                      let arr= new Uint16Array(xxx, a.pos, a.size);
                      a.array=[];
                      for (let k=0;k<arr.length;k++)
                      {
                          a.array.push(arr[k]);
                      }
                    }
                    if (a.type=="Uint32Array")
                    {                
                      let arr= new Uint32Array(xxx, a.pos, a.size);
                      a.array=[];
                      for (let k=0;k<arr.length;k++)
                      {
                          a.array.push(arr[k]);
                      }
                    }
                }
            }

            resolve2()
          } else {
            reject2(xhr.status)
          }
        }
        if (xhr.status === 404) {
          reject2(xhr.status)
        }
        }
      })
      allRequest.push(pro);
    }

    
    function onload(obj2)
    {
      if (obj2)
      {
        //obj2.geos = json_object.geometries;
        let bSkeleton=false;
        obj2.traverse((obj)=>{
          if (obj.type=="SkinnedMesh")
          {
            bSkeleton=true;
          }
          if (obj.geometry)
          {
            let found=null;
            for (let i=0;i<json_object.geometries.length;i++)
            {
              let g = json_object.geometries[i];
              if (g.uuid==obj.geometry.uuid)
              {
                obj.geometry.data_file=g;
                for (let key in g.data.attributes)
                {
                  let a = g.data.attributes[key];
                  a.array=[];
                }
              }
            }
          }
        })
        if (bSkeleton) obj2.bSkeleton=bSkeleton;
        obj2.bLoadFromJson=true;
        //console.log(obj2);
        resolve(obj2)
      }else{
        reject();
      }
    }
    Promise.all(allRequest).then(
      () => {
        console.log("restore",json_object);
        {
          var loader = new THREE.ObjectLoader();
          loader.parse(json_object,onload);
        }
      },
      () => {
        console.log("加载错误");
        {
          var loader = new THREE.ObjectLoader();
          loader.parse(json_object,onload);
        }
      }
    )
  })
  
  
  syn_material = (object) => {
    if (object && object.material && object.material.o2id && this.synMaterials) {
      let o2id = object.material.o2id
      let arr = this.synMaterials.get(o2id)
      if (arr) {
        let mtls = object.material
        for (let i = 0; i < arr.length; i++) {
          let mtl = arr[i]
          if (mtl.type != mtls.type) continue
          if (mtl == mtls) continue
          mtl.color.setHex(mtls.color.getHex())
          mtl.map = mtls.map
          mtl.envMap = mtls.envMap
          mtl.normalMap = mtls.normalMap
          if (mtl.type == 'MeshPhongMaterial') {
            mtl.emissive.setHex(mtls.emissive.getHex())
            mtl.specular.setHex(mtls.specular.getHex())
            let oldcombine = mtl.combine
            mtl.combine = mtls.combine
            if (oldcombine != mtl.combine) {
              obj2.mtl.needsUpdate = true
            }
            mtl.reflectivity = mtls.reflectivity
            mtl.lightMapIntensity = mtls.lightMapIntensity
            mtl.emissiveIntensity = mtls.emissiveIntensity
            mtl.aoMapIntensity = mtls.aoMapIntensity
            // mtl.normalScale.x = mtls.XnormalScale;
            // mtl.normalScale.y = mtls.YnormalScale;
            mtl.shininess = mtls.shininess
            mtl.transparent = mtls.transparent
            mtl.opacity = mtls.opacity
            mtl.alphaTest = mtls.alphaTest
          }
        }
      }
    }
  }

  update_frame_rate(time) {
    let pass_time = (time - this.last_frame_time) / 1000
    this.total_time += pass_time
    this.frame_total_count++
    this.last_frame_time = time
    //this.arr_frame_time.push(pass_time);
    //每2秒平均帧率
    if (this.total_time >= 2) {
      this.frame_rate = 1 / (this.total_time / this.frame_total_count)
      this.total_time = 0
      this.frame_total_count = 0
      if (this.on_update_frame_rate) {
        this.on_update_frame_rate(this.frame_rate)
      }
      //this.total_time -= this.arr_frame_time[0];
      //this.arr_frame_time.splice(0,1);
    }
    //this.frame_rate = 1/(this.total_time/this.arr_frame_time.length);
    //console.log(frame_rate);
  }
}

export { o2vr }
