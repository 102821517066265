const users = {
  title: '用户协议',
  littleTitle: '图壤元宇宙用户协议',
  text: [
    {
      title: '一、概述',
      content: `本协议是图壤元宇宙服务平台（以下亦称为“本平台”）的经营者中图云创智能科技（北京）有限公司（以下简称为“本公司”），与用户（下亦称为“您”）共同缔结的对双方具有约束力的有效契约。图壤元宇宙向您提供本平台上所展示的产品与服务（下称“图壤元宇宙服务”、“本服务”），并将不断更新服务内容，最新的图壤元宇宙服务以本平台相关产品及服务介绍页面展示的以及向您实际提供的为准。在接受本协议之前，请您仔细阅读并遵守本协议的全部内容。请您务必审慎阅读、充分理解各条款的内容，特别是以粗体下划线标注的条款。您通过本平台网络页面点击确认或以其他方式选择接受本协议或实际已使用图壤元宇宙服务，将视为对本协议的接受，已达成协议并同意接受本协议的全部约定内容。`
    },
    {
      title: '二、总则',
      content: [
        `1. 您确认：您在使用本服务之前，已经充分阅读、理解并接受本协议的全部内容，一旦您使用本服务，即表示您同意遵循本协议之所有约定。`,
        `2.您同意：图壤元宇宙有权随时对本协议及相应的服务规则内容进行单方面的变更，并有权以消息推送、网页公告等方式予以公布，而无需另行单独通知您；若您在本协议内容公告变更后继续使用本服务的，表示您已充分阅读、理解并接受修改后的协议内容，也将遵循修改后的协议内容使用本服务；若您不同意修改后的本协议，您应立即停止使用本服务。`,
        `3.本协议内容同时包括图壤元宇宙可能不断发布的关于所提供服务的相关协议、业务规则、产品文档、保密协议、隐私政策等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。如具体服务的相关协议、业务规则、产品文档、保密协议、隐私政策等约定内容与本协议约定内容存在不一致或冲突，以具体服务的约定内容为准。`,
        `4. 为了便于您使用图壤元宇宙服务平台服务，您理解并接受，本公司可以通过邮件、短信、电话、站内信、弹出消息等形式，向您发送公示规则等各类通知。`
      ]
    },
    {
      title: '三、隐私政策',
      content: `本公司非常重视对用户个人信息的保护。本公司将按照图壤元宇宙服务相关隐私政策（以下统称为“隐私政策”）的规定收集、使用、储存和分享您的个人信息。隐私政策为本协议不可分割的组成部分。`
    },
    {
      title: '四、关于账号',
      content: [
        `1.账号类型`,
        `（1）图壤元宇宙账号分为个人账号和企业账号；`,
        `（2）个人账号是通用账号，使用手机或邮箱注册。主要面向个人用户和开发者；`,
        `（3）企业账号是面向商业产品的用户账号体系。比个人账号有更高的安全等级及享有更多产品选项；`,
        `（4）注册时，您可根据上述注册页面的注册说明，详细了解账号之间的不同。`,
        `2. 注册`,
        `（1）注册者资格`,
        `您确认：在您完成注册程序或以其他图壤元宇宙允许的方式实际使用本服务时，您应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织（以下统称为“法律主体”）。若您是未成年人，建议您请您的监护人仔细阅读本协议，并在征得您的监护人同意的前提下购买或使用我们的服务或向我们提供信息。在您购买及使用相应服务的同时，您也同时承认了您拥有购买及使用相应服务的权利能力和行为能力，并且对您在购买及使用相应服务过程中提供的所有信息的真实性负责。`,
        `（2）注册账号`,
        `在使用本服务前，您必须先注册，取得图壤元宇宙提供给您的账号，您同意并保证：`,
        `• 您确保您正确选择了账号类型。如因账号类型引起的后续问题，图壤元宇宙将不承担任何责任。`,
        `• 您所填写的用户信息是真实、准确、完整、及时的，且保证图壤元宇宙可以通过您所填写的联系方式与您取得联系。`,
        `• 您应根据图壤元宇宙对于服务的要求及时提供相应的身份证明，企业资质等资料，否则图壤元宇宙有权拒绝向您提供相关服务。`,
        `• 若您提供的资料或信息包含有不正确、不真实的信息，图壤元宇宙保留取消您账号资格并随时结束为您提供服务的权利。`,
        `• 您应对您的图壤元宇宙账号负责，只有您本人可以使用您的图壤元宇宙账号，该账号不可转让、不可赠与、不可继承。否则图壤元宇宙有收回该账号的权利。`,
        `• 以代理人身份代理其他自然人或者单位进行注册，您必须向图壤元宇宙提供代理人和被代理人的详细资料和书面授权，未提供上述资料和文件的，图壤元宇宙将视注册者为账号所有人和使用人。`,
        `• 您应当准确填写并及时更新您提供的电子邮件地址、联系电话、联系地址、邮政编码等联系方式，以便图壤元宇宙与您进行有效联系，因通过这些联系方式无法与您取得联系，导致您在使用图壤元宇宙服务过程中产生任何损失或增加费用的，应由您完全独自承担。您了解并同意，您有义务保持您提供的联系方式的有效性，如有变更需要更新的，您应按图壤元宇宙的要求进行操作。`,
        `（3）账号责任`,
        `图壤元宇宙通过您的用户名和密码识别您的指示，请您妥善保管您的用户名和密码，对于因密码泄露或用户名遗忘所致的损失，由您自行承担。若您发现有他人冒用或盗用您的账号及密码或任何其他未经合法授权之情形时，应立即以有效方式通知图壤元宇宙，要求图壤元宇宙暂停相关服务。同时，您理解图壤元宇宙对您的请求采取行动需要合理期限，在此之前，图壤元宇宙对已执行的指令及所导致的您的损失不承担任何责任。`,
        `3.  实名认证`,
        `您了解并同意，为保证账号和交易安全，图壤元宇宙有权随时要求您完成图壤元宇宙账号的实名认证；同时，图壤元宇宙可能会就某些产品或服务的开通，要求您提供更多的身份资料或企业资质等信息，做进一步的身份认证或资格验证，您的账号只有在通过这些认证和验证之后，方可获得使用相关产品或服务的资格。`,
        `4. 通常情况下，您的图壤元宇宙账号是您在本平台进行一切活动的唯一身份识别依据，每一个图壤元宇宙账号都可以在本平台独立开展活动。但在下列情形下，图壤元宇宙有权根据自己的判断，对同一及/或关联法律主体拥有的多个图壤元宇宙账号进行统一处理，包括但不限于：`,
        `（1）多个图壤元宇宙账号之间存在主账号与子账号、授权账号与被授权账号等关联关系，且根据法律法规、本协议、图壤元宇宙各产品协议或其他图壤元宇宙规则的规定，各账号之间互相承担连带责任的；`,
        `（2）多个图壤元宇宙账号使用同一实名认证，且其中两个或两个以上图壤元宇宙账号同时存在恶意欠费及/或违反法律法规、本协议、图壤元宇宙各产品协议或其他图壤元宇宙规则的行为的；`,
        `（3）多个图壤元宇宙账号对应于同一法律实体，且被识别为恶意注册获取不正当利益者；`,
        `（4）其他图壤元宇宙有充足理由需要对多个图壤元宇宙账号进行统一处理的情形。`,
        `5.  您理解并同意，图壤元宇宙有权了解您使用本平台产品及服务的真实背景及目的，有权要求您如实提供真实、全面、准确的信息；如果图壤元宇宙有合理理由怀疑您提供的信息不真实、您进行虚假交易，或您的行为违反图壤元宇宙服务平台规则的，图壤元宇宙有权暂时或永久限制您账号下所使用的所有产品及/或服务的部分或全部功能。`,
        `6. 您理解并同意，图壤元宇宙有权按照国家司法、行政、军事、安全等机关（包括但不限于公安机关、检察机关、法院、海关、税务机关、安全部门等）的要求对您的个人信息及在本平台的资金、交易及账号等进行查询、冻结或扣划。`,
        `7.账号注销`,
        `图壤元宇宙保留在您违反国家、地方法律法规规定或违反本协议的情况下，中止或终止为您提供部分或全部服务、直至注销图壤元宇宙账号的权利。`
      ]
    },
    {
      title: '五、资质材料',
      content: [
        `1. 您保证具备使用本服务、运营产品或提供服务等的相关合法资质或经过了相关政府部门的审核批准，您的行为不违反任何相关法律法规及协议、规则，也不会侵犯任何人的合法权益。`,
        `2.  您应按照图壤元宇宙要求提交相关主体资质材料，包括但不限于身份证明文件、营业执照、组织机构代码证、法人代表身份证明等文件、联系人姓名（名称）、联系地址、电子邮箱等相关资料。您保证提供的资料真实、合法、准确、完整，并在相关信息发生变更后，及时进行更新。`,
        `3. 您若使用本服务进行网站运营、应用运营等任何经营性或非经营性活动，应根据提供产品及服务的不同种类，满足包括但不限于以下要求（具体内容可能依照相关法律规定的变更而调整）：`,
        `（1）若您在使用图壤元宇宙服务开办、接入或运营了多个网站，须保证所开办的全部网站均获得国家有关部门的许可或批准；`,
        `（2）若您提供经营性互联网信息服务，您应依法取得互联网信息服务增值电信业务经营许可证；`,
        `（3）若您网站提供非经营性互联网信息服务，必须办理非经营性网站备案，并保证所提交的所有备案信息真实有效，在备案信息发生变化时及时在备案系统中提交更新信息；`,
        `（4）若您提供电子公告服务，应提供在申请互联网信息服务增值电信业务经营许可证或者办理非经营性互联网信息服务备案时，已经取得专项批准或者专项备案的证明文件；`,
        `（5）若您经营互联网游戏服务，您应依法取得网络文化经营许可证等；`,
        `（5）若您经营互联网游戏服务，您应依法取得网络文化经营许可证等；`,
        `（7）若您从事新闻、出版、教育、医疗保健、药品和医疗器械等互联网信息服务，依照法律、行政法规以及国家有关规定须经有关主管部门审核同意，在申请经营许可或者履行备案手续前，应当依法经有关主管部门审核同意；`,
        `（8）若您从事其他应当依法进行审批、备案等行为的，应提供相应证明文件。`
      ]
    },
    {
      title: '六、用户的权利与义务',
      content: [
        `1. 您保证您对使用本平台产品及服务涉及的网站具有合法、有效、充足的控制权和经营权，您使用本平台产品及服务的行为不会损害任何第三方的合法权益。同时您保证不会利用技术或其他手段破坏或扰乱本网站及其他任何第三方的网站。您同意本公司有权在您违反上述约定时终止向您提供服务并不予退还任何款项，因您的上述行为给本公司造成损失的，您应予赔偿。`,
        `2.您保证使用本服务时将遵从国家、地方法律法规、行业惯例和社会公共道德，不会利用图壤元宇宙提供的服务进行存储、发布、传播如下信息和内容：`,
        `（1）违反国家法律法规政策的任何内容（信息）；`,
        `（2）违反国家规定的政治宣传和/或新闻信息；`,
        `（3）涉及国家秘密和/或安全的信息；`,
        `（4）封建迷信和/或淫秽、色情、下流的信息或教唆犯罪的信息；`,
        `（5）博彩有奖、赌博游戏；违反国家民族和宗教政策的信息；`,
        `（6）妨碍互联网运行安全的信息；`,
        `（7）侵害他人合法权益的信息和/或其他有损于社会秩序、社会治安、公共道德的信息或内容。`,
        `您理解并同意，不得为他人发布上述不符合国家规定和/或本协议约定的信息内容提供任何便利，包括但不限于设置URL、BANNER链接，发送邮件、短信等。您同意图壤元宇宙有权在您违反上述约定时终止向您提供服务并不予退还任何款项，因您的上述行为给图壤元宇宙造成损失的，您应予赔偿。`,
        `3.当您在违反本协议的情况下，图壤元宇宙有权拒绝继续为您提供任何服务。`,
        `4.您保证不会进行任何滥用图壤元宇宙资源的行为，包括但不仅限于任何与黑客攻击相关的行为、虚拟货币挖矿、网格计算等。`,
        `5.您保证网站不会在未经过ICP备案的情况下对外开放。`,
        `6.您应自行对因使用本服务而存储在服务器的各类数据等信息进行备份，并在本服务之外，采取合理、安全的技术措施，确保其安全性和完整性，并对自己的行为（包括但不限于欠费，自行安装软件、采取加密措施或进行其他安全措施等）所引起的结果承担全部责任。`,
        `7. 您在使用本服务时须遵守本协议以及本公司的相关要求，不得利用本服务从事包括但不限于以下行为，也不得为以下行为提供便利：`,
        `（1）将本服务的安全服务器用作虚拟服务器或用作磁盘空间进行出租，以及用作代理服务器（Proxy）或邮箱服务器；`,
        `（2）进行博彩、赌博游戏、“私服”、“外挂”等非法互联网活动；`,
        `（3）发布、传送、传播电子邮件广告、垃圾邮件（SPAM）；`,
        `（4）发布、传送、传播不受欢迎的或者未经请求的电子邮件、电子广告、短信广告或包含反动、色情等有害信息的电子邮件或短信；`,
        `（5）建立或利用有关设备、配置运行与所使用服务无关的程序或进程，导致大量占用本服务中的服务器内存、CPU或者网络带宽资源，给本服务的系统或者本服务中的用户所使用的其他网络、服务器（包括但不限于本地及外地和国际的网络、服务器等）、产品/应用、服务等带来严重的负荷，影响本服务与国际互联网或者本服务与特定网络、服务器及本服务内部的通畅联系，或者导致本服务或者本服务中的用户所使用的服务器宕机、死机或者其他图壤元宇宙服务使用者的产品或服务不可访问等；`,
        `（6）进行任何破坏或试图破坏网络安全的行为，包括但不限于以病毒、木马、恶意代码、钓鱼等方式，采取对其他网站、服务器进行恶意扫描、非法侵入系统、非法获取数据等；`,
        `（7）进行任何改变或试图改变本服务提供的系统配置或破坏系统安全的行为；`,
        `（8）进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现软件的源代码；`,
        `（9）利用技术或其他手段破坏、扰乱本服务的运营或他人对本服务的使用；`,
        `（10）对图壤元宇宙拥有知识产权的内容进行出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；`,
        `（11）对服务运行过程中释放到任何终端内存中的数据、服务运行过程中终端与服务器端的交互数据，以及服务运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经图壤元宇宙授权的第三方工具/服务接入图壤元宇宙服务相关系统；`,
        `（12）以任何方式干扰或企图干扰任何产品或任何部分、功能的正常运行，或者制作、发布、传播上述工具、方法等；`,
        `（13）进行任何可能违反诚实信用、公序良俗、公众道德的行为；以任何方式发布虚假信息，诱导用户分享虚假信息等行为；`,
        `（14）从事包括但不限于“DNS解析”、“安全服务”、“域名代理”、“反向代理”等任何业务，导致用户频繁遭受攻击（包括但不限于DDoS攻击）且未及时更正用户的行为或未根据要求消除影响，从而对本服务平台或其他本服务使用者产生威胁；`,
        `（15）从事任何可能对本公司及其关联方造成损害或不利于本公司及其关联方的行为；`,
        `（16）其他未经图壤元宇宙明示授权的行为。`,
        `如您违反上述承诺，本公司有权随时停止为您提供服务，同时有权暂时或永久限制您账号下所使用的所有产品及/或服务的部分或全部功能并拒绝继续为您提供服务，对于您支付的任何费用，本公司无义务退还。如因您的行为，对本公司及其关联方或任何第三方造成损失或导致本公司及其关联方或任何第三方为此承担了相关的责任，则您需全额赔偿本公司及其关联方或任何第三方的相关支出及损失，包括合理的律师费用。`
      ]
    },
    {
      title: '七、图壤元宇宙的权利与义务',
      content: [
        `1. 图壤元宇宙有权利对您进行审查并决定是否接受您成为图壤元宇宙用户。图壤元宇宙保留在您违反国家、地方现行法律法规规定或违反本协议的情况下单方终止为您提供服务的权利，并有权配合国家上级主管部门，对您进行审查，并且在任何情况下，图壤元宇宙对任何间接、偶然、特殊及继起的损害不承担赔偿责任。`,
        `2.图壤元宇宙保留在您网站没有经过ICP备案就开放的情况下配合国家相关部门解绑资源公网IP的权利。`,
        `3.图壤元宇宙保留在您滥用系统资源的情况下，强制停机及冻结账号的权利。`,
        `4. 图壤元宇宙保留在您资源欠费时将资源彻底销毁的权利，一旦彻底销毁资源将不可恢复。`,
        `5.本平台产品或服务中可能会使用第三方软件或技术，同时，图壤元宇宙将按照相关法规或约定，对相关的协议或其他文件进行展示。前述通过各种形式展现的相关协议或其他文件，均是本协议不可分割的组成部分，与本协议具有同等的法律效力，用户应当遵守这些要求。`,
        `6. 若用户使用图壤元宇宙产品或服务从事用户的权利与义务中禁止的事项，或利用图壤元宇宙从事违法或有损他人的事项，图壤元宇宙有权对用户进行审查，停用账户，并删除相应违规资源。`
      ]
    },
    {
      title: '八、费用结算',
      content: [
        `1.本平台产品及服务的服务费用将在各产品或服务的套餐和服务说明中列明公示，您可自行选择具体服务类型及数量，并按列明的价格予以支付。您可以通过账号内“【订单管理】”查看服务购买情况。`,
        `2. 本公司保留在您未按照约定支付全部费用之前不向您提供服务和技术支持，或者终止服务和技术支持的权利。`,
        `3. 您理解并同意，除非双方另有书面约定，服务一经购买，则不允许申请退款。`,
        `4. 您可通过发送邮件至【anquan_business@baidu.com】向本公司申请开具与用户所购买服务等金额的增值税普通发票。`
      ]
    },
    {
      title: '九、免责声明',
      content: [
        `1. 本公司不保证（包括但不限于）：`,
        `（1）本平台产品及服务适合所有用户的使用要求；`,
        `（2）本平台产品及服务不受干扰，及时、安全、可靠或不出现错误；及用户经由本平台取得的任何产品、服务或其他材料符合用户的期望；`,
        `（3）您使用经由本平台下载或取得的任何资料，其风险由您自行承担；因使用本平台产品及服务导致用户电脑系统损坏或资料流失，您应负全部责任。`,
        `2. 图壤元宇宙保留您违反国家、地方法律法规规定或违反本协议的情况下中止或终止为您提供服务的权利。`,
        `3.您同意您在使用图壤元宇宙服务时如有欺诈、发布或销售伪劣商品、侵犯他人合法权益或其他严重违反任意服务规则的行为，本公司有权注销您的账号，您可能将不能再登录任意一家网站/应用，所有网站/应用服务同时终止。`,
        `4.您在使用本平台产品或服务过程中，本协议内容、页面上出现的关于交易操作的提示或图壤元宇宙发送到您手机的信息（短信或电话等）内容是您使用本服务的相关规则，您使用本服务即表示您同意接受本服务的相关规则。您了解并同意图壤元宇宙有权单方修改服务的相关规则，而无须单独征得您的同意，服务规则应以用户使用服务时的页面提示（或发送到该手机的短信或电话等）为准，请您在购买及使用服务前务必阅读并确认相关规则，您同意并遵照服务规则是您使用本服务的前提。`,
        `5. 图壤元宇宙可能会以电子邮件（或发送到您手机的短信或电话等）方式通知您服务进展情况以及提示用户进行下一步的操作，但图壤元宇宙不保证用户能够收到或者及时收到该邮件（或发送到该手机的短信或电话等），且对此承担任何后果。因此，在服务过程中您应当及时登录到本平台查看和进行交易操作。因您没有及时查看和对服务状态进行修改或确认或未能提交相关申请而导致的任何纠纷或损失，图壤元宇宙不负任何责任。`,
        `6.对由于您向图壤元宇宙提供的联络方式有误以及用户用于接收图壤元宇宙邮件的电子邮箱安全性、稳定性不佳而导致的一切后果，您应自行承担责任，包括但不限于因您未能及时收到图壤元宇宙的相关通知而导致的后果和损失。`,
        `7.系统中断或故障`,
        `系统可能因下列状况无法正常运作，使您无法使用各项联网服务时，图壤元宇宙不承担损害赔偿责任，该状况包括但不限于：`,
        `（1）图壤元宇宙在系统停机维护期间；`,
        `（2）电信设备出现故障不能进行数据传输的；`,
        `（3）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成图壤元宇宙系统障碍不能执行业务的；`,
        `（4）由于黑客攻击、电信部门技术调整或故障、网站升级、银行方面的问题等原因而造成的服务中断或者延迟。`,
        `（5）其他非因本公司单方原因造成的无法正常运作的情况。`,
        `8. 在法律允许的情况下，图壤元宇宙对于与本协议有关或由本协议引起的任何间接的、惩罚性的、特殊的、派生的损失（包括业务损失、收益损失、利润损失、使用数据或其他经济利益的损失），不论是如何产生的，也不论是由对本协议的违约（包括违反保证）还是由侵权造成的，均不负有任何责任，即使事先已被告知此等损失的可能性。另外即使本协议规定的排他性救济没有达到其基本目的，也应排除图壤元宇宙对上述损失的责任。`,
        `9.鉴于网络服务的特殊性，您同意图壤元宇宙在有正当理由的情况下可以随时变更、中断或终止部分或全部的服务，也无需向您承担任何责任。但是，图壤元宇宙会尽可能事前通知您，以便您做好相关数据的转移备份以及业务调整等，以保护您的合法权益。`,
        `10.为了向您提供更完善的服务，图壤元宇宙有权定期或不定期地对提供本服务的平台或相关设备进行检修、维护、升级等，如因此类情况造成相关服务在合理时间内中断或暂停的，图壤元宇宙无需为此向您承担任何责任。`,
        `11.您理解并同意：图壤元宇宙的服务是按照现有技术和条件所能达到的现状提供的。图壤元宇宙会尽最大努力向您提供服务，确保服务的连贯性和安全性；但图壤元宇宙不能保证其所提供的服务毫无瑕疵，也无法随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失、数据泄露或被窃取以及其他的损失和风险。所以您同意：即使图壤元宇宙提供的服务存在瑕疵，但上述瑕疵是当时行业技术水平所无法避免的，其将不被视为图壤元宇宙违约，同时，由此给您造成的数据或信息丢失等损失图壤元宇宙将予以免责。`,
        `12.您理解并同意：在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。出现上述情况时，图壤元宇宙将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失图壤元宇宙将予以免责。`,
        `13.您理解并同意：您自行安装盗版软件、系统、数据资源，造成自身损失的，图壤元宇宙不承担任何责任。您自身的行为导致损失的，保留追偿权。`
      ]
    },
    {
      title: '十、知识产权声明',
      content: [
        `1.  本公司拥有图壤元宇宙服务平台内所有信息内容（包括但不限于文字、图片、软件、音频、视频）的版权及相关知识产权。任何被授权的浏览、复制、打印和传播属于图壤元宇宙服务平台内信息内容都不得用于商业目的且所有信息内容及其任何部分的使用都必须包括此声明。`,
        `2. 图壤元宇宙服务平台上所有的产品、技术、所有程序及商品和服务的标识的知识产权均属于本公司。“图壤元宇宙”、图壤元宇宙其他产品服务名称及相关图形、标识等为的注册商标。未经图壤元宇宙及本公司的许可，任何人不得擅自（包括但不限于：未经授权的使用、复制、传播、展示、镜像、上载、下载）使用。否则，图壤元宇宙及本公司将依法追究法律责任。`,
        `3.您同意图壤元宇宙服务平台的全部运营数据，包括但不限于用户、客户、管理、技术、财务、商业或其他任何方面的数据，其全部的权利均归属于本公司。`,
        `4.未经本公司事先书面同意，任何人不得以任何方式（包括但不限于使用机器人、蜘蛛、其他自动设备、手动程序或在非本公司服务器上设置镜像等）来抓取、复制或监视图壤元宇宙服务平台或其所包含的任何内容；否则，本公司有权依法追究法律责任。`,
        `5.您与本公司承诺，除本协议约定的情形外，您不会将使用本平台产品或服务过程中了解到的有关本公司的保密资料（包括但不限于任何本公司享有权利的数据、协议、往来邮件等纸质或电子版材料、折扣优惠信息、技术诀窍、计算机程序、源代码、研究方案及其他符合法定商业秘密构成要件的信息），以任何形式透露给第三方，并严格限制接触保密资料的员工遵守本条之保密义务。`
      ]
    },
    {
      title: '十一、违约责任',
      content: [
        `1. 根据用户违规情形的严重程度，本公司有权对用户采取如下一种或多种处理措施；情节严重的，本公司还将一并追究用户的法律责任：`,
        `（1）删除违规信息；`,
        `（2）限制、中止用户使用图壤元宇宙服务平台服务；`,
        `（3）终止用户使用图壤元宇宙服务平台服务，终止本协议，并追究用户的法律责任；`,
        `（4）其他本公司认为适合的处理措施。`,
        `2. 您理解并同意本公司有权以普通或非专业人员的知识水平标准对您的行为进行单方认定，并根据单方认定结果适用协议、规则予以处理或中止、终止向您提供服务（包括中止或终止图壤元宇宙服务平台服务），且无须征得您的同意或提前通知予您。本公司根据相关规则对您进行处理后，不免除您应当承担的法律责任。除有确切证据证明本公司的认定结果错误外，您不可撤销地认可本公司的单方认定结果及证据（包括但不限于本公司通过技术方式收集的证据等）。`,
        `3.  如因您违反有关法律法规或者本协议、相关规则之规定，使本公司遭受任何损失、受到其他用户、任何第三方的索赔或任何行政管理部门的处罚，您应对本公司、其他用户或相关第三方的实际损失进行全额赔偿，包括合理的律师费用。`,
        `4.在任何情况下，本公司向用户承担的总体责任和赔偿不超过本公司在截止违约之时基于服务从用户处获得的技术服务费用收入的总额，该等技术服务费用收入也仅限于以货币方式收取的实际收入而不包括其他利益。`
      ]
    },
    {
      title: '十二、协议终止及转让',
      content: [
        `1. 本协议在符合以下任意一项情形时终止：`,
        `1) 通知解除：本公司可提前15天以书面通知的方式终止本协议而无须承担违约责任；`,
        `2) 因法规政策或主管机关要求，图壤元宇宙服务平台及/或图壤元宇宙服务将可能中止或终止，且本公司没有义务或无法事先通知您，您同意不因此要求本公司承担违约责任；`,
        `3) 单方解除权：出现下列情况之一的，本公司有权立刻终止本协议，且按有关规则对您进行处罚：`,
        `a)您违反本协议中的任何承诺或保证，包括但不限于本协议项下的任何约定；`,
        `b) 协议规定的其他协议终止条件发生或实现，导致本协议终止。`,
        `2.无论本协议因何原因终止，您在协议终止前的行为所导致的任何责任，您必须完全且独立地承担；协议终止后，本公司无需继续向您提供服务。`,
        `3. 本公司有权将本协议项下的全部权利和义务转让给关联方，而无须征得您的事先同意，届时本公司将通过邮件、站内信、页面公告等方式向您发出转让通知。`
      ]
    },
    {
      title: '十三、通知及送达',
      content: [
        `1. 本公司对于用户所有的通知均可通过站内信、页面公告、电子邮件、手机短信或常规的信件传送等方式进行；该等通知于发送之日视为已送达收件人。`,
        `2.用户对于本公司的通知应当通过本公司在本页面公布的联系方式进行送达。`
      ]
    },
    {
      title: '十四、 法律适用及争议解决',
      content: [
        `1. 本协议适用中华人民共和国大陆地区法律。`,
        `2.因用户与本公司就本协议的签订、履行或解释发生争议，双方应努力友好协商解决。如协商不成，任何一方均有权向北京市朝阳区人民法院起诉。`
      ]
    },
    {
      title: '十五、联系我们',
      content: [
        `您对本协议有任何意见或建议可发送邮件至【service@chinafocus.net】，我们将在30个工作日内回复。为保障我们高效处理您的问题并及时向您反馈，需要您提交身份证明、有效联系方式和书面请求及相关证据，我们会在验证您的身份处理您的请求。`,
        `[本行以下无正文]`,
        `再次感谢您对图壤元宇宙服务平台的信任和使用！`,
        `\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0中图云创智能科技（北京）有限公司`,
        `2023年6月`
      ]
    }
  ]
}

const privates = {
  title: '隐私政策',
  littleTitle: '图壤元宇宙隐私政策',
  text: [
    {
      title: '',
      content: `本条款是“图壤”的提供者中图云创智能科技（北京）有限公司（以下简称本公司）为遵循保护隐私权及个人资料的相关法律法规，以下是本公司的隐私权与个人资料保护政策（以下称“本政策”），将说明本公司会收集哪些个人资料与如何使用这些资料，并说明其他与您的隐私相关的重要事项。`
    },
    {
      title: '一、个人资料的获取',
      content: [
        `1.当您注册成为“图壤”会员时，会要求您输入注册账号。`,
        `2.当您在使用“图壤”服务时，本公司可能会收集到以下资料：`,
        `（1）您的使用资料，包括但不限于：使用历史、查询资料等；`,
        `（2）技术数据，例如：URL、IP地址、浏览器类型、作业系统资料、语言及访问时间、地理位置资料等。`,
        `3.本公司为提供您正确与快速的服务，当您使用“图壤”服务时，如账户异动申请，意见回馈或参与线上产品营销活动，会请您提供会员账号资料或个人联系方式。`
      ]
    },
    {
      title: '二、个人资料的使用',
      content: [
        `1.本公司收集到的资料，将用于以下用途：`,
        `（1）您所提供的基本个人资料（如姓名），将做为会员身份判定的依据；您所提供的联系方式，将作为联络、营销活动通知、会员状况通知、会员权限修改或其他与“图壤”服务相关更新通知的方式。`,
        `（2）您在“图壤”的使用资料及技术资料，将用以改进“图壤”服务或开发新服务。`,
        `（3）本公司提供消息推送服务以及不定期发送活动通知。若您不希望收到任何消息推送或活动通知，可随时登入账户取消订阅或通知服务。如您在设定上有任何问题，欢迎随时与本公司客服联系。`,
        `2.本公司可能与第三方共用综合性的非个人资料。“综合性非个人资料”是指记录有关用户的并被分组收集的资料，这些资料在经过分组后不再反映或指向某个特定的可被辨识的使用者。`,
        `3.上述资料的处理与利用，可能由本公司的关系企业或其他合作伙伴完成。除非有当地法律除外情况，否则本公司不会将这些资料用做其他用途。本公司亦不会未经用户事先同意向第三方透露其个人资料，但因国家重大利益或为配合执法机关调查而根据法律所需要透露者则除外。`
      ]
    },
    {
      title: '三、个人资料的自主权',
      content: [
        `1.您本人可查询、复制或申请变动属于您个人资料范围的内容，若非本人或在本公司尚未查证您为本人前，您不得申请查询、复制、请求阅览或申请变动不属于本账号的个人资料及账号服务。如非上述情况之申请者，必要时需要您提供相关个人资料及书面资料，以便本公司可协助处理相关问题。`,
        `2.您本人可要求停止利用或删除属于您的个人资料；但一经停止或删除，您将无法继续使用 “图壤”的服务。`,
        `3.在妨害国家重大利益、妨害公务机关执行法定职务、妨害搜集机关或第三人重大利益时，本公司有权拒绝您主动提出的变更、删除或异动个人资料的请求。`
      ]
    },
    {
      title: '四、个人资料的安全',
      content: [
        `1.为保护您的隐私，本公司提供必要的技术及措施保护个人资料。`,
        `2.您的个人资料、注册账号及密码等，请您妥善保管避免外泄。`,
        `3.“图壤”服务有可能包含其他非本公司的网站或网页链接，若您个人同意该网站搜集、处理或利用您的个人资料，本公司不负任何连带责任。`,
        `4.您同意在“图壤”服务进行消费时所留存的资料与事实相符，如发现你的个人资料遭他人非法使用或有任何异常时，应及时通知本公司。`,
        `5.您同意在使用“图壤”服务时所提供及使用的资料皆为合法，并无侵害第三人权利，违反第三方协议或涉及任何违法行为。如因使用服务而致第三人损害，除本公司故意或重大过失外，本公司并不负担相关赔偿责任。`
      ]
    },
    {
      title: '五、隐私权及个人资料保护政策的修订与效力',
      content: [
        `1.本公司将不定时更新本政策，并遵循当地隐私权与个人资料保护的相关法律，若本公司就本隐私权政策进行重大变动而扩大本公司使用您个人资料的权利，本公司将通知您以取得您的同意。`,
        `2.本政策构成服务条款的一部分。如您不同意本政策的任何条款或修改变更的内容，请您立即停止使用服务。`,
        `3.如果您对本公司的隐私权与个人资料保护政策有任何问题，请联系我们：`,
        `expressreader@chinafocus.net`
      ]
    }
  ]
}

const aboutus = {
  title: '关于我们',
  littleTitle: '',
  text: [
    {
      title: '关于中图云创',
      content: `中图云创智能科技（北京）有限公司是中宣部直属中国出版集团成员单位中国图书进出口（集团）有限公司控股子公司，定位于创新型阅读科技企业。致力于文化与科技融合发展，于2019年5G元年率先开展“5G新阅读”业务，用新技术赋能出版融合发展、公共文化智慧服务和数字文化消费。`
    },
    {
      title: '关于图壤',
      content: [
        `「书籍作为人类文明的结晶，将成为元宇宙中的文化景观，以人文思想的丰富性与创造性，为人们提供一个更广阔更饱满的精神世界；以科技为土壤，孕育、催生出阅读与出版的崭新形态，将人类文明突破时空限制，共享至世界的更多角落。」`
      ]
    },
    {
      title: '图壤意为出版行业在新技术赋能下“破圈”生长。',
      content: ''
    },
    {
      title: '合作电话：65002113',
      content: ''
    }
  ]
}

const guide = {
  title: '新手教程',
  littleTitle: '',
  text: [
    {
      title: '【一】 移动行走',
      content: `使用左侧滑轮可控制人物移动`,
      video: 'https://o2public.oss-cn-beijing.aliyuncs.com/电信土壤/Video/新手教学视频/1-移动.mp4'
    },
    {
      title: '【二】 调整视角',
      content: `使用右手在右侧屏幕滑动`,
      video:
        'https://o2public.oss-cn-beijing.aliyuncs.com/电信土壤/Video/新手教学视频/2-调整视角.mp4'
    },
    {
      title: '【三】 空间传送',
      content: `点击「传送」切换空间`,
      video: 'https://o2public.oss-cn-beijing.aliyuncs.com/电信土壤/Video/新手教学视频/3-传送.mp4'
    },
    {
      title: '【四】 乘船',
      content: `点击「出发」乘船游览`,
      video: 'https://o2public.oss-cn-beijing.aliyuncs.com/电信土壤/Video/新手教学视频/4-乘船.mp4'
    },
    {
      title: '【五】 播放全景影片',
      content: `岛屿上多处可触发全景视频观看，可以点击场景中的竹简、灯笼、冰块、图书观看`,
      video:
        'https://o2public.oss-cn-beijing.aliyuncs.com/电信土壤/Video/新手教学视频/5-观看影片.mp4'
    },
    {
      title: '【六】 观看数字藏品',
      content: `点击场景中的小气泡，观赏数字藏品`,
      video:
        'https://o2public.oss-cn-beijing.aliyuncs.com/电信土壤/Video/新手教学视频/6-数字藏品.mp4'
    }
  ]
}

export { users, privates, aboutus, guide }
