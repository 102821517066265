import * as THREE from 'three'
import { screenVideo } from "../../../common/js/screenVideo";
import Vue from '../../main';
import Vuex from './store'

var _o2;
const sceneInteraction={
    init(o2){
        _o2 = o2;
        scope.panelVideo = new screenVideo();
        scope.panoVideo = new screenVideo();
        scope.arrAudioPlayer = [];
        scope.sceneScreen = [];
        scope.panoVideo.videoElement.ontimeupdate = ()=>{
            let video = scope.panoVideo.videoElement;
            Vue.Bus.$emit("onPanoUpdate",video.currentTime/video.duration);
        }
        scope.addListener();
        document.onInteraction = ()=>{
            
            if(scope.bClickScreen){
                scope.setSceneAudio();
            }
        }
        _o2.frame_move_recall.push(scope.onFrame);
    },
    onFrame(t){
        if(_o2.role?.obj){
            scope.arrAudioPlayer.forEach(e=>e.onFrame(t));
            let pos = _o2.role.obj.position;
            let dis = 0;
            if(scope.curScreen){
                dis = pos.distanceTo(scope.curScreen.position);
                if(dis > scope.screenFar){
                    scope.curScreen = null;
                    scope.panelVideo.setScreen();
                    scope.panelVideo.playVideo();
                }
            }else if(scope.sceneScreen){
                scope.curScreen = scope.sceneScreen.find(e=>e.position.distanceTo(pos)<=scope.screenFar);
                if(scope.curScreen){
                    scope.panelVideo.setScreen(scope.curScreen);
                    let name = videoName[scope.curScreen.name];
                    let url = `${assetUrl}Video/${name}.mp4`;
                    scope.panelVideo.playVideo(url);
                }
            }
            if(scope.panelVideo.isPlay){
                if(!scope.panelVideo?.curScreen?.postionWorld){
                    scope.panelVideo.curScreen.postionWorld = new THREE.Vector3();
                }
                let screenPos = scope.panelVideo.curScreen.postionWorld;
                scope.panelVideo?.curScreen?.getWorldPosition(screenPos);
                dis = screenPos.distanceTo(pos);
                let volume = 1-(dis-scope.screenNear)/(scope.screenFar-scope.screenNear);
                volume = Math.min(Math.max(0,volume),1);
                scope.panelVideo.videoElement.volume = volume;
            }
        }
    },    
    onSceneLoad(){
        console.log("开始加载场景");
        if(scope.mapRole)scope.mapRole=null;
        if(scope.mapVideoPanel)scope.mapVideoPanel= null;
        if(scope.skyScreen)scope.skyScreen = null;
        if(scope.curBox)scope.curBox = null;
        if(scope.dt1)scope.dt1 = null;
        if(scope.dt2)scope.dt2 = null;
        if(scope.arrAnimBook)scope.arrAnimBook = null;
        scope.curBook = null;
        scope.curBall = null;
        scope.panelVideo?.setScreen();
        scope.panelVideo?.playVideo();
        scope.panoVideo?.setScreen();
        scope.panoVideo?.playVideo();
        scope.arrAudioPlayer.forEach(e=>{
            e.stop();
            e.setEnabled(false);
        });
    },
    onSceneLoaded(){
        console.log("完成加载场景");
        scope.setSceneAudio();
        scope.setSceneScreen();
        if(_o2.regionNode)_o2.regionNode = null;
        _o2.regionNode = _o2.search_obj_by_name("region");
        if (_o2.regionNode){
            _o2.regionNode.visible = false;
            _o2.regionNode?.children.forEach((e) => {
                e.updateMatrixWorld();
                e.geometry.computeBoundingBox();
                e.geometry.computeBoundingSphere();
            });
        }
        let boxNode = _o2.search_obj_by_name("盒子");
        if(boxNode){
            boxNode.visible = true;
            boxNode.children.forEach(e=>{
                e.visible = true;
                if(e.material)e.material.visible = false;
                e.children?.forEach(sub=>{
                    if(sub.material)sub.material.visible = false;
                    sub.visible = false;
                })
            })
        } 
        scope.skyScreen = document.o2.search_obj_by_name("tiankong");
        if(scope.skyScreen){
            scope.skyScreen.visible = false;
            scope.panoVideo.setScreen(scope.skyScreen);
        }
        if(Vuex.state.sceneCurrentIndex == 0){
            scope.screenFar = 10000;
            scope.screenNear = 1000;
            let node = _o2.search_obj_by_name("角色");
            node?.children.forEach(e => {   
                if(!scope.mapRole){
                    scope.mapRole = {};
                }
                scope.mapRole[e.name] = e;
                e.visible = false;
            });
            node = _o2.search_obj_by_name("视频面板");
            node?.children.forEach(e=>{
                if(!scope.mapVideoPanel){
                    scope.mapVideoPanel = {};
                }
                scope.mapVideoPanel[e.name] = e;
                e.visible = false;
            })
            node = _o2.search_obj_by_name("属性");
            node?.children.forEach(e=>{
                if(!scope.mapPanel){
                    scope.mapPanel = {};
                }
                let name = e.name.substr(0,e.name.length-2);
                scope.mapPanel[name]=e;
                e.visible = false;
            })
        }else if(Vuex.state.sceneCurrentIndex == 1){
            let tl = document.o2.animation.get_timeline_by_name("竹排");
            tl.goto(0);
        }else if(Vuex.state.sceneCurrentIndex == 2){
            scope.screenFar = 15000;
            scope.screenNear = 1000;
            let arrAnimBook = [];
            scope.arrAnimBook = arrAnimBook;
            _o2.scene.children.forEach(e=>{
                if(e.mixer && e.name != "PD"){
                    arrAnimBook.push(e);
                    if (e.curAction)
                    {
                        e.curAction.clampWhenFinished = true;
                        e.curAction.loop = THREE.LoopOnce;
                        e.curAction.timeScale = -1;
                    }
                }
            })
        }else if(Vuex.state.sceneCurrentIndex == 3){
            let tl = _o2.animation.get_timeline_by_name("DT_01");
            scope.dt1 = _o2.search_obj_by_name("DT_01");
            scope.dt2 = _o2.search_obj_by_name("DT_02");
            tl.LoopType = 2;
        }else if(Vuex.state.sceneCurrentIndex == 4){
            scope.screenFar = 3000;
            scope.screenNear = 0;
        }   
    },

    addListener(){
        Vue.Bus.$on("enterRegion",name=>{
            if(Vuex.state.sceneCurrentIndex == 3){
                if(name == "C_02" || name == "C_03"){
                    let tl = _o2.animation.get_timeline_by_name("DT_01");
                    tl.LoopType = tl.LoopType == 0?2:0;
                    scope.curDT = name=="C_02"?scope.dt1:scope.dt2;
                    //document.o2play.setCarrier(scope.curDT);
                    //document.o2play.move_ctrl.posPlayer.copy(pos);
                    document.o2play.move_ctrl.gravity = tl.LoopType==0?98000:980;
                    scope.setSingle(true);
                    tl.play();
                }
            }
        });
        Vue.Bus.$on("leaveRegion",name=>{
            if(Vuex.state.sceneCurrentIndex == 3){
                if((name == "C_02" || name == "C_03") && scope.curDT){
                    document.o2play.move_ctrl.gravity=9800;
                    //document.o2play.move_ctrl.posPlayer.copy(pos);
                    //document.o2play.setCarrier();
                    scope.setSingle(false);
                }
            }
        });
        Vue.Bus.$on("panoVideo",flag=>{
            if(!flag){
                scope.playPanoVideo();
            }
        })
        _o2.ws.on(_o2.ws.responseCmd.MSG_CUSTOM,msg=>{
            console.log(msg);
            let arrStr = msg.split(",");
            if(arrStr && arrStr.length==3){
                let id = parseInt(arrStr[0])||0;
                let cmd = arrStr[1];
                let flag = parseInt(arrStr[2])||0;
                if(cmd == "single" && id != _o2.role?.id){
                    let p = _o2.playerMgr.getPlayerById(id);
                    if(p.obj)p.obj.visible = flag == 0;
                }
            }
        })
    },

    setSceneAudio(){
        let audioNode = _o2.search_obj_by_name("Audio");
        let count = 0;
        audioNode?.children.forEach(e=>{
            let pos = e.position;
            e.geometry.computeBoundingSphere();
            let far = e.geometry.boundingSphere.radius*1000;
            let is3D = true;
            let audioPlayer=null;
            if(scope.arrAudioPlayer.length>count){
               audioPlayer = scope.arrAudioPlayer[0];
               audioPlayer.set3D(pos,far);
               scope.arrAudioPlayer.splice(0,1);
            }else{
                audioPlayer = new sceneAudio(e.name,{is3D,far,pos}); 
            }
            count++;
            let name = audioSceneName[Vuex.state.sceneCurrentIndex];
            let audioName = e.name.split("_")[0];
            let url = `${assetUrl}Audio/${name}/${audioName}.mp3`;
            audioPlayer.play(url);
            audioPlayer.setEnabled(audioName != roleAudioName);
            scope.arrAudioPlayer.push(audioPlayer);
        })
    },

    setSceneScreen(){
        scope.sceneScreen.splice(0);
        for (const key in videoName) {
            let screen = _o2.search_obj_by_name(key);
            if(screen){
                scope.sceneScreen.push(screen);
            }
        }
    },

    onItemClick(intersect){
        let obj = intersect.object;
        if(obj?.parent?.name.startsWith("交互球")){
            scope.onBallClick(obj);
            return true;
        }else if(obj.parent?.name == "盒子"||obj.parent?.name.includes("Box")){
            let skeleton = scope.arrAnimBook?.find(e=>e.name==obj.name);
            if(skeleton){
                scope.onBookClick(obj,skeleton);
            }else{
                let url = scope.getVideoUrl(obj.name);
                let name = obj.name;
                if(url)Vue.Bus.$emit("panoVideo",name);
                scope.playPanoVideo(url);
            }
            return true;
        }else if(obj.name == "出发"){
            let tl = _o2.animation.get_timeline_by_name("竹排");
            let obj = _o2.search_obj_by_name("zhufa");
            document.o2play.setFollow(obj);
            tl.play();
            scope.setSingle(true);
            return true;
        }else if(obj.name == "左岸"){
            document.o2play.setFollow();
            document.o2play.setRolePos(-22282,135,-24007);
            scope.setSingle(false);
            return true;
        }else if(obj.name == "右岸"){
            document.o2play.setFollow();
            document.o2play.setRolePos(-7634,0,-32745);
            scope.setSingle(false);
            return true;
        }else if(obj.name == "返回"){
            let tl = _o2.animation.get_timeline_by_name("竹排");
            tl.goto(0);
            document.o2play.setFollow();
            document.o2play.setRolePos(9521,0,22868);
            scope.setSingle(false);
            return true;
        }//else if(Object.hasOwnProperty.call(videoName, obj.name)){
        //     if(scope.panelVideo.curScreen == obj){
        //         scope.panelVideo.setScreen();
        //         scope.panelVideo.playVideo();
        //     }else{
        //         let name = videoName[obj.name];
        //         let url = assetUrl + "Video/" + name + ".mp4";
        //         scope.panelVideo.setScreen(obj);
        //         scope.panelVideo.playVideo(url);
        //     }
        //     return true;
        // }
        return false;
    },
    
    onBallClick(obj){
        let name = obj.parent.name.substr(4);
        if(scope.curBall != name){
            let  lastData = scope.getDataByBallName(scope.curBall);
            if(lastData?.type == "video"){
                scope.panelVideo.setScreen();
                scope.panelVideo.playVideo();
            }
            if(lastData?.obj) lastData.obj.visible = false;
            if(lastData?.panel)lastData.panel.visible = false;
            scope.curBall = name;
        }
        let audioPlayer = scope.arrAudioPlayer.find(e=>e.id==roleAudioName);
        let data = scope.getDataByBallName(name);
        if(data.obj)data.obj.visible = !data.obj.visible;
        if(data?.panel)data.panel.visible = !data.panel.visible;
        audioPlayer.audio.currentTime = 0;
        if(data.type == "video" && data.obj){
            audioPlayer?.setEnabled(false);
            if(data.obj.visible){
                scope.panelVideo.setScreen(data.obj);
                let url = scope.getVideoUrl(name);
                scope.panelVideo.playVideo(url);
            }else{
                scope.panelVideo.setScreen();
                scope.panelVideo.playVideo();
            }
        }else if(data.type == "role"&&data.obj){
            audioPlayer?.setEnabled(data.obj.visible);
        }
        
    },

    onBookClick(box,skeleton){
        if(!skeleton)return;
        if(scope.curBook != skeleton.name){
            let curSkeleton = scope.arrAnimBook.find(e=>e.name == scope.curBook);
            if(curSkeleton){
                curSkeleton.curAction.timeScale = -1;
                curSkeleton.curAction.enabled = true;
                curSkeleton.curAction.paused = false;
                curSkeleton.curAction.play();
                if(scope.curBox)scope.curBox.visible = true;
                let subBoxNode = scope.curBox.parent?.children.find(
                    e=>e.name == scope.curBox.name+"_Box"
                );
                subBoxNode?.children.forEach(e=>{
                    e.visible = false;
                })
            }
            scope.curBox = box;
            let subBoxNode = box.parent?.children.find(
                e=>e.name == box.name+"_Box"
            );
            subBoxNode?.children.forEach(e=>{
                e.visible = true;
            })
            if(scope.curBox)scope.curBox.visible = false;
            curSkeleton = scope.arrAnimBook.find(e=>e.name == skeleton.name);
            curSkeleton.curAction.enabled = true;
            curSkeleton.curAction.paused = false;
            scope.curBook = skeleton.name;
            skeleton.curAction.timeScale = 1;
            skeleton.curAction.play();
        }
    },

    setSingle(flag){
        document.o2.multiplayer.bSendRolePos = !flag;
        let v = flag ? "1":"0";
        _o2.ws.send(_o2.ws.requestCmd.CMD_USER_CUSTOM+",single,"+v);
    },

    getVideoUrl(name){
        let sceneIdx = Vuex.state.sceneCurrentIndex;
        return assetUrl+sceneName[sceneIdx]+"/"+name+".mp4";
    },

    getDataByBallName(name){
        if(!name)return;
        let obj = null;
        let type = "";
        if((("春夏秋冬").includes(name) || name.includes("神")) && scope.mapVideoPanel){
            type = "video";
            if(("春夏秋冬").includes(name)){
                obj=  scope.mapVideoPanel["四季"];
            }else if(name.includes("神")){
                obj= scope.mapVideoPanel["神"];
            }
        }else if(scope.mapRole){
            type = "role";
            obj = scope.mapRole[name];
        }
        let panel = null;
        if(scope.mapPanel)panel = scope.mapPanel[name];
        return {obj,type,panel};
    },

    playPanoVideo(url){
        if(scope.panelVideo.isPlay){
            if(url){
                scope.panelVideo.videoElement.pause();
            }else{
                scope.panelVideo.videoElement.play();
            }
        }
        if(scope.skyScreen){
            scope.panoVideo.playVideo(url);
            document.o2.scene.children.forEach(e=>{
                if(e.type != "GridHelper")
                    e.visible = url?false:true;
            })
            if(url){
                scope.skyScreen.visible = true;
                document.o2play.move_ctrl.canMove = false;
                scope.cameraPos = _o2.camera.position.clone();
                let pos = _o2.cameraControls.target.clone();
                pos.z -= _o2.cameraControls.minDistance;
                _o2.camera.position.copy(pos);
            }else{
                scope.skyScreen.visible = false;
                let pos = _o2.cameraControls.target.clone();
                pos.z -= _o2.cameraControls.maxDistance;
                if(scope.cameraPos){
                    _o2.camera.position.copy(scope.cameraPos);
                }
                document.o2play.move_ctrl.canMove = true;
            }
        }
    },
}
const scope = sceneInteraction;

const audioSceneName = [
    "BIBF主岛音效",
    "传统文化岛",
    "党建岛",
    "少儿岛",
    "科幻岛"
]

const sceneName = [
    "Main_Island",
    "Culture_Island",
    "Red_Island",
    "Child_Island",
    "Science_Island"
];

const videoName = {
    "mesh_56":"中图公司宣传片-10M",
    "Screen_L_1":"十分钟混剪0912-10M",
    "pm1":"第一部分",
    "pm2":"第二部分"
}
const roleAudioName = "三岔口片头";
const assetUrl = "https://o2public.oss-cn-beijing.aliyuncs.com/电信土壤/";

class sceneAudio{
    constructor(id,cfg){
        this.id = id;
        this.enabled = true;
        this.audio = document.createElement("audio");
        this.audio.id = id;
        this.audio.loop = true||cfg?.loop;
        this.is3D = false||cfg?.is3D;
        //this.audio.muted = true;
        this.far = cfg?.far||10000;
        this.near = cfg.near||1000;
        this.pos = cfg?.pos?.clone()||new THREE.Vector3();
    }

    setLoop(value){
        this.audio.loop = value;
    }

    setEnabled(value){
        this.enabled = value;
        if(!value && this.isPlay){
            //this.audio.currentTime = 0;
            this.audio.pause();
            this.isPlay = false;
        }
    }

    onFrame(t){
        if(this.is3D&&document.o2.role?.obj && this.enabled){
            let listener = document.o2.role.obj;
            let dis = listener.position.distanceTo(this.pos);
            if(dis <= this.far){
                this.audio.volume = 1 - Math.max(0,dis-this.near)/(this.far-this.near)
                if(!this.isPlay){
                    this.isPlay = true;
                    this.audio.play();
                }
            }else{
                this.audio.volume = 0;
                if(this.isPlay){
                    this.isPlay = false;
                    this.audio.pause();
                }
            }
        }
    }

    play(url){
        this.audio.src = url;
        this.audio.load();
    }

    pause(){
        this.audio.pause();
        this.isPlay = false;
    }

    set3D(pos,far,near){
        this.is3D = true;
        if(!this.position)
            this.position = new THREE.Vector3();
        this.pos.copy(pos);
        this.near = near||1000;
        this.far = far||10000;
    }
    stop(){
        this.pause();
        this.audio.src = "";
    }
}

export default sceneInteraction;