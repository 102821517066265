import Hls from "hls.js";
import flvjs from "flv.js";
import TCPlayer from 'tcplayer.js';
import 'tcplayer.js/dist/tcplayer.min.css';
import * as THREE from "three/build/three.module";

const u = navigator.userAgent;
const userAgentInfo = navigator.userAgent;
var Agents = ["iPhone"];
var isiOS = false;
for (var v = 0; v < Agents.length; v++) {
  if (userAgentInfo.indexOf(Agents[v]) > 0) {
    isiOS = true;
    break;
  }
}

class screenVideo {
  constructor(name) {
    this.videoElement = document.createElement('video');
    if(name){
      this.name = name;
      this.videoElement.setAttribute('id',name);
    }
    this.videoElement.setAttribute('crossOrigin', 'Anonymous');
    this.videoElement.setAttribute('webkit-playsinline', 'true');
    this.videoElement.setAttribute('playsinline', 'true');
    this.videoElement.setAttribute('loop', 'true');
    document.body.append(this.videoElement);
    this.videoElement.style.display = "block";
    this.videoElement.style.position = "absolute";
    this.videoElement.style.left = "0px";
    this.videoElement.style.top = "0px";
    this.videoElement.style.zIndex = -1;
    this.videoElement.style.width = "100px";
    this.videoElement.style.height = "100px";
    //this.videoElement.style.display = "none";
    this.videoTex = new THREE.VideoTexture(this.videoElement);
    this.videoTex = this.videoTex;
    this.isPlay = false;
    if (this.videoElement) {
      this.videoElement.oncanplay = () => {
        console.log("播放视频：",this.isPlay);
        if (!this.isPlay) {
          if (this.curScreen) {
            this.curScreen.material.map = this.videoTex;
            this.videoTex.needsUpdate = true;
            this.curScreen.material.needsUpdate = true;
          }
          this.videoElement.play();
          this.videoElement.muted = false;
          this.isPlay = true;
          if(this.videoTime){
            let time = this.videoTime%this.videoElement.duration;
            this.videoElement.currentTime = time;
          }
        }
      };
      this.videoElement.onended = ()=>{
        if(!this.videoElement.loop){
          this.isPlay = false;
        }
      }
    }
  }

  setScreen(screen) {
    if (screen != this.curScreen) {
      if(!screen){
        this.playVideo("");
      }
      if (this.curScreen) {
        this.curScreen.material = this.oldMtl;
      }
      this.curScreen = screen;
      this.curMtl?.dispose();
      if (screen && screen.material) {
        this.oldMtl = screen.material;
        this.curMtl = screen.material.clone();
        this.curScreen.material = this.curMtl;
      }
    }
  }

  playVideo(url,time) {
    try {
      console.log(url)
      this.isPlay = false;
      this.videoTime = 0;
      if(!url){
        this.videoElement.src = "";
        if (this.flvPlayer) {
          this.flvPlayer.detachMediaElement();
          this.flvPlayer.destroy();
          this.flvPlayer = null;
        }
        if (this.hlsPlayer) {
          this.hlsPlayer.detachMedia();
          this.hlsPlayer.destroy();
          this.hlsPlayer = null;
        }
        setTimeout(() => {
          this.tcplayer?.pause();
        }, 1000);
        this.videoElement.pause();
        if (this.curScreen && this.oldMtl) {
          this.curScreen.material = this.oldMtl;
        }
      }else{
        if (url.indexOf("video") != 0
          && this.videoTex.video != this.videoElement) {
          this.videoTex.dispose();
          this.videoTex = new THREE.VideoTexture(this.videoElement);
        }
        if (this.curScreen && this.curMtl) {
          this.curScreen.material = this.curMtl;
        }
        let bPlay = false;
        if(url.startsWith("webrtc")){
          if(!this.tcplayer)
            this.tcplayer = TCPlayer(this.name,{});
          this.tcplayer.src(url);
          bPlay = true;
          this.tcplayer.play();
          return;
        }else{
          let arrStr = url.split("?");
          if (arrStr && arrStr.length > 0) {
            let len = arrStr[0].length;
            if (len < 4) return;
            let name = arrStr[0].substr(len - 3, 3);
            if (name == "flv" && flvjs.isSupported()) {
              this.flvPlayer = flvjs.createPlayer({ type: "flv", url: url });
              this.flvPlayer.attachMediaElement(this.videoElement);
              this.flvPlayer.load();
              this.flvPlayer.play();
              console.log(this.flvPlayer);
              bPlay = true;
            }
            else if (url.indexOf("mp4") >= 0) {
              this.videoElement.src = url;
              this.videoTime = time;
              bPlay = true;
            }
            name = arrStr[0].substr(len - 4, 4);
            if (name == "m3u8") {
              // alert(`当前设备是${isiOS?'ios':'安卓'}`)
              if (isiOS) {
                this.videoElement.src = url; 
                bPlay = true;
              } else if (Hls.isSupported()) {
                this.hlsPlayer = new Hls();
                this.hlsPlayer.attachMedia(this.videoElement);
                this.hlsPlayer.loadSource(url);
                //this.videoElement.play();
                bPlay = true;
              }
            }
          }
        }
        if (!bPlay) {
          if (url.indexOf("video") == 0) {
            let video = document.getElementById(url);
            if (video) {
              this.videoTex.dispose();
              this.videoTex = new THREE.VideoTexture(video);
              this.curMtl.map = this.videoTex;
              this.curMtl.needsUpdate = true;
              this.videoElement.pause();
              return;
              //bPlay = true;
            }
          }
        }else{
          this.videoElement.load();
          this.videoElement.muted = true;
          return;
        }
      }
      //this.videoElement.pause();
    } catch (e) {
      console.log(e);
    }
  }
}

export { screenVideo }