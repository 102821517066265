import Vue from 'vue'
import App from './App'
import Vuex from 'vuex'
import router from './assets/js/router'
Vue.config.productionTip = false

// O2VR
import { o2vr } from '../common/js/o2'

// Css
import './assets/css/tencent.css'
import '../common/css/normal.scss'
import './assets/css/font.css'
import gsap from 'gsap'
import VueAnimXYZ from '@animxyz/vue'
import '@animxyz/core'
import 'hover.css'
import 'animate.css'
import TDesign from 'tdesign-vue'
import 'tdesign-vue/es/style/index.css'

// /* 按需引入element ui */
import 'element-ui/lib/theme-chalk/index.css'
import { Select, Option, Tooltip, Button, Badge, Slider } from 'element-ui'

/* Js */
import Bus from '../common/js/bus'
import store from './assets/js/store'

import { o2Init } from './assets/js/o2Init'
import lyh from '../common/js/lyh'

// 文件上传
import { o2Function } from '../common/js/commonUpload'

// 人物
import { playerMgr } from '../common/js/playerMgr_OffLine'
import { player } from '../common/js/player'
import { o2play } from './assets/js/o2play'

// TRTC
import { o2vrClient } from '../common/js/commonTRTC'

// 多人在线
import { multiplayer } from './assets/js/multiplayer'

// 引入 聊天室 插件
import JwChat from 'jwchat'

// 小地图
// import { map } from './assets/js/map'

// 实例化 初始化
Vue.prototype.Bus = Bus
Vue.prototype.$gsap = gsap
Vue.prototype.$o2Init = new o2Init()
Vue.prototype.$lyh = new lyh()
Vue.prototype.$client = new o2vrClient()
Vue.prototype.$o2Function = new o2Function()

document.o2 = new o2vr()
document.o2.play = new o2play()
document.o2.playerMgr = new playerMgr()
document.o2.playerMgr.bCfgLoaded = true
document.o2.multiplayer = new multiplayer(document.o2)
player.cfg = require('./assets/role/szrCfg_9.json')
// document.o2.map = new map(document.o2)

Vue.use(Vuex)
Vue.use(VueAnimXYZ)
Vue.use(TDesign)
Vue.use(Select)
Vue.use(Option)
Vue.use(Slider)
Vue.use(Tooltip)
Vue.use(Button)
Vue.use(Badge)
Vue.use(JwChat)

// 禁止IOS 缩放
document.addEventListener('gesturestart', function(event) {
  event.preventDefault()
})

const vue = new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

export default vue
