/*
 * @Author: 雷云鹤
 * @Date: 2023-02-21 14:46:55
 * @Last Modified by: 雷云鹤
 * @Last Modified time: 2023-06-11 14:04:50
 */

/* 请遵守小驼峰命名规范 谢谢合作 *^____^* */
// target:[] 相机原点
import Vuex from './store'
import Vue from '../../main'
import { getshow, editBoard, sit } from './api'
import { screenVideo } from '../../../common/js/screenVideo'
import sceneInteraction from './sceneInteraction'
import * as THREE from 'three/build/three.module'

class o2Init {
  constructor() {
    this.initConfig = Vuex.state.initConfig
    this.timer = null
    this.interval = null
    this.move = false // 传送成功是否
  }

  createContainer() {
    let container = document.createElement('div')
    container.id = this.initConfig.containerId
    container.classList.add('o2vr_container')
    document.getElementById('o2vr_container_fenghuang').appendChild(container)
  }

  async init() {
    try {
      let _o2 = document.o2
      let IC = this.initConfig
      let IC_SCENE = IC.sceneList[Vuex.state.sceneCurrentIndex]

      // 开启所有bus
      this.busInit()
      this.initMultiplayer()
      this.sInteraction = sceneInteraction
      document.sInteraction = sceneInteraction
      sceneInteraction.init(_o2)
      document.o2play.onItemClick = this.sInteraction.onItemClick
      _o2.script.init = () => {
        // 判断当前是竖屏还是横屏 以便切换操作
        this.landscapeWatch()
        // 开启监听 以便切换操作
        window.addEventListener(
          'orientationchange',
          () => {
            if (document.visibilityState === 'visible') {
              if (
                navigator.userAgent.indexOf('Windows') == -1 &&
                (window.orientation == 90 || window.orientation == -90)
              ) {
                console.log('横屏')
                // document.o2.cameraControls.orientation = 'orientation'
                Vuex.commit('chat/changeyaoganWatch', 2)
                // if (navigator.userAgent.indexOf('iPhone') > -1) {
                //   document.getElementById('container').className = 'o2vr_container_fenghuang'
                // }
              }
              if (
                navigator.userAgent.indexOf('Windows') == -1 &&
                (window.orientation == 180 || window.orientation == 0)
              ) {
                console.log('竖屏')
                // document.o2.cameraControls.orientation = 'landscape'
                Vuex.commit('chat/changeyaoganWatch', 4)
                // if (navigator.userAgent.indexOf('iPhone') > -1) {
                //   document.getElementById('container').className = 'o2vr_container'
                // }
              }
            }
          },
          false
        )
        _o2.cameraControls.target.set(...IC_SCENE.cameraTarget)
        _o2.camera.position.set(...IC_SCENE.cameraPosition)
        _o2.cameraControls.panSpeed = 0.5
        _o2.cameraControls.maxDistance = IC_SCENE.maxDistance
        _o2.cameraControls.minDistance = IC_SCENE.minDistance
        _o2.cameraControls.maxPolarAngle = Math.PI - Math.PI * 0.1
        _o2.cameraControls.minPolarAngle = Math.PI / 10
        _o2.camera.fov = IC_SCENE.fov
        _o2.view_length = IC_SCENE.view_length // 摄像机碰撞检测距离
        document.o2play.move_ctrl.moveSpd = IC_SCENE.moveSpeed // 初始化移速
        document.o2play.move_ctrl.bLookAtForward = IC_SCENE.bLookAtForward // 朝向是否自动跟随镜头
        _o2.camera.updateProjectionMatrix()
      }

      console.log('%cO2VR Scene Engine ======loading====>>>>>>>>', 'color:#409EFF')

      this.createContainer()

      _o2.init(document.getElementById(IC.containerId)) // o2 初始化canvas

      document.o2.play.init(document.o2) // 场景交互逻辑的初始化 必须在o2绑定 container 之后
      document.o2.play.prepare_run() // 绑定监听事件

      try {
        await this.loadScene(true, true)
      } catch (error) {
        throw error
      }
    } catch (error) {
      console.log('%c o2init Error: ' + error, 'color:red;font-size:20px')
      throw 'o2init Error: ' + error
    }
  }

  onSceneLoaded() {
    this.sInteraction.onSceneLoaded()
  }
  onSceneLoad() {
    document.o2play.resetMC()
    this.sInteraction.onSceneLoad()
  }

  //多人
  initMultiplayer() {
    let _o2 = document.o2
    // let auth = Vuex.state.auth
    // _o2.multiplayer.bSendRolePos = auth >= 3
    _o2.multiplayer.customPlayerInfo = (info) => {
      //info.bLoad = Vuex.state.chat.role.type !== 'guest'
      return info
    }
    _o2.multiplayer.onPlayerLoaded = (p) => {
      if (p.namePanel) p.namePanel.position.y = 1800
      p.info?.modelIdx == 0 ? p.role?.scale.set(1.5, 1.5, 1.5) : p.role?.scale.set(1, 1, 1)
    }
  }
  // 本地建人
  createRole() {
    let _o2 = document.o2
    let IC = this.initConfig
    let { account_id, name, info } = IC.roleConfig

    _o2.playerMgr
      .import_player({
        account_id,
        name,
        info
      })
      .then((p) => {
        _o2.role = p
        _o2.player = p.obj
        p.obj.visible = true
        _o2.playerMgr.current_player = p
        p.obj.traverse((c) => {
          if (c.type == 'SkinnedMesh') {
            c.frustumCulled = false
          }
        })

        // 设置出生点
        document.o2play.move_ctrl.posPlayer.set(
          ...IC.sceneList[Vuex.state.sceneCurrentIndex].rolePosition
        )
        // _o2.role.obj.position.set(
        //   ...IC.sceneList[Vuex.state.sceneCurrentIndex].rolePosition
        // )
      })
  }

  // 判断当前是横屏还是竖屏
  landscapeWatch() {
    if (
      navigator.userAgent.indexOf('Windows') == -1 &&
      (window.orientation == 90 || window.orientation == -90)
    ) {
      console.log('横屏')
      // document.o2.cameraControls.orientation = 'orientation'
      Vuex.commit('chat/changeyaoganWatch', 2)
    }
    if (
      navigator.userAgent.indexOf('Windows') == -1 &&
      (window.orientation == 180 || window.orientation == 0)
    ) {
      console.log('竖屏')
      // document.o2.cameraControls.orientation = 'landscape'
      Vuex.commit('chat/changeyaoganWatch', 4)
    }
  }

  // 监听横竖屏切换 以改变视角移动  不可直接调用 否则无效
  // watchLandscape() {
  //   window.addEventListener('orientationchange', this.landscapeWatch())
  // }

  async loadScene(time, isLoadScene) {
    try {
      let _o2 = document.o2
      let IC = this.initConfig
      let IC_SCENE = IC.sceneList[Vuex.state.sceneCurrentIndex]
      Vuex.commit('setPlayed', false)

      // 是否加载场景
      if (isLoadScene) {
        try {
          this.onSceneLoad()
          await _o2.load_zip_scene(IC_SCENE.url)
          console.log('%cScene loaded successfully', 'color:#67C23A')
          this.onSceneLoaded()
          if (Vuex.state.initConfig.sceneList[Vuex.state.sceneCurrentIndex].sceneId === 'art') {
            this.loadScreen('screen')
          } else if (
            Vuex.state.initConfig.sceneList[Vuex.state.sceneCurrentIndex].sceneId === 'meeting1'
          ) {
            this.loadScreen('对象002')
          } else if (
            Vuex.state.initConfig.sceneList[Vuex.state.sceneCurrentIndex].sceneId === 'meeting2'
          ) {
            this.loadScreen('对象004')
          }

          if (Vuex.state.chat.notJoinRoomButStream) {
            Vue.Bus.$emit('screenStream', Vuex.state.chat.notJoinRoomButStream)
          }

          // document.o2play.onEnterRegion = (name) => {}

          // document.o2play.onLeaveRegion = (name) => {}

          document.o2play.regionNode = _o2.search_obj_by_name('REGION')
          // 获取展板数据
          // this.get_show()

          // Vuex.state.chat.sits.forEach((f) => {
          //   document.o2play.add_lable_to_sit(f.name, f.x, f.y + 1000, f.z)
          // })
        } catch (error) {
          throw 'o2.js load_zip_scene error! ' + error
        }
      }

      // let mapPanel = _o2.search_obj_by_name('dt')
      // _o2.map.setMap(mapPanel)
      //_o2.scene.add(_o2.playerMgr.playerNode)
      let node = _o2.playerMgr.playerNode
      if (node.parent) node.parent.remove(node)
      _o2.scene?.add(node)

      document.o2play.move_ctrl.colli = _o2.search_obj_by_name('colli')
      document.o2play.move_ctrl.posPlayer.set(...IC_SCENE.rolePosition)
      document.o2play.move_ctrl.ground_height = IC_SCENE.ground_height
      document.o2play.move_ctrl.moveSpd = IC_SCENE.moveSpeed
      _o2.cameraControls.maxDistance = IC_SCENE.maxDistance
      _o2.cameraControls.minDistance = IC_SCENE.minDistance

      // 是否初次加载
      if (!time) {
        _o2.cameraControls.target.set(...IC_SCENE.cameraTarget)
        _o2.camera.position.set(...IC_SCENE.cameraPosition)
        _o2.camera.fov = IC_SCENE.fov
        _o2.camera.updateProjectionMatrix()
        document.o2play.move_ctrl.bLookAtForward = IC_SCENE.bLookAtForward
      }

      if (!isLoadScene) {
        console.log('%cChange position successfully', 'color:#67C23A')
      }
    } catch (error) {
      throw error
    }
  }

  // 坐下起立事件
  standAndUp() {
    // 坐下起立的回调事件
    document.o2.role.onStateChg.push(async (state) => {
      if (state === 'sit') {
        if (Vuex.state.chat.role.auth > 0) {
          if (
            Vuex.state.initConfig.sceneList[Vuex.state.sceneCurrentIndex].sceneId.includes(
              'phoenix'
            ) &&
            Vuex.state.roomId !== 101
          ) {
            let num = document.o2.role.sit.replace(/[^\d]/g, ' ')
            let roomid = parseInt(num)

            if (roomid && Vuex.state.roomArr.phoenix.indexOf(roomid) != -1) {
              // 是否创建过 trtc client
              if (Vue.$client.client) {
                Vuex.commit('client/settrtcDomShow', true)

                Vuex.commit('client/setClientSwitch', false)

                setTimeout(async () => {
                  await Vue.$client.reJoin(roomid)

                  Vuex.commit('client/setClientSwitch', true)
                  Vuex.commit('chat/setStandStatus', 1)
                }, 100)
              } else {
                Vuex.commit('client/settrtcDomShow', true)

                Vuex.commit('client/setClientSwitch', false)

                setTimeout(async () => {
                  await Vue.$client.create(Vuex.state.chat.role.id, roomid)

                  Vuex.commit('client/setClientSwitch', true)
                  Vuex.commit('chat/setStandStatus', 1)
                }, 100)
              }

              Vuex.commit('setroomId', roomid)
              Vuex.commit(
                'chat/send',
                `${Vuex.state.chat.connectInfo.CMD_USER_CUSTOM},changeRoomId-${(Vuex.state.chat.role
                  .id,
                roomid)}`
              )
            } else {
              Vuex.commit('chat/setStandStatus', 1)
            }
          } else {
            Vuex.commit('chat/setStandStatus', 1)
          }
        } else {
          Vuex.commit('chat/setStandStatus', 1)
        }
      } else if (state === 'idle') {
        if (Vuex.state.chat.role.auth > 0) {
          if (
            Vuex.state.initConfig.sceneList[Vuex.state.sceneCurrentIndex].sceneId.includes(
              'phoenix'
            ) &&
            Vuex.state.client.hasJoin
          ) {
            await Vue.$client.leave()
            Vuex.commit(
              'chat/send',
              `${Vuex.state.chat.connectInfo.CMD_USER_CUSTOM},changeRoomId-${(Vuex.state.chat.role
                .id,
              100)}`
            )
            Vuex.commit('setroomId', 100)
            Vuex.commit('client/settrtcDomShow', false)
            Vuex.commit('chat/setStandStatus', 2)
          } else {
            Vuex.commit('chat/setStandStatus', 2)
          }
        } else {
          Vuex.commit('chat/setStandStatus', 2)
        }
      }
    })
  }

  // 加载屏幕播放
  loadScreen(val) {
    let screen = document.o2.search_obj_by_name(val)

    let screen_video = new screenVideo()

    if (val === 'screen') {
      document.o2.screen = screen_video
    } else if (val === '对象002') {
      document.o2.screen002 = screen_video
    } else if (val === '对象004') {
      document.o2.screen004 = screen_video
    }

    screen_video.setScreen(screen)
  }

  busInit() {
    // 登录webcokset成功
    Vue.Bus.$on('ws_login_success', async () => {
      if (Vuex.state.chat.yaoganWatch !== 'landscape') {
        Vue.$message.success(
          Vuex.state.chat.role.auth
            ? `尊敬的${Vuex.state.chat.role.auth === 1 ? 'Vip用户' : '管理员'}, 您好~ 欢迎进入${
                Vuex.state.initConfig.sceneList[Vuex.state.sceneCurrentIndex].sceneName
              }`
            : `欢迎进入${Vuex.state.initConfig.sceneList[Vuex.state.sceneCurrentIndex].sceneName}`
        )
      }
    })

    // 被挤掉
    Vue.Bus.$on('relogin', async () => {
      Vue.$message.info('来自另一个地方的ip试图取代你')
      await Vuex.commit('clearToken')
    })

    // 被管理员T
    Vue.Bus.$on('outpersonSingle', async ({ sendid, id }) => {
      if (Vuex.state.chat.role.id == id) {
        Vue.$message.info('管理员已将你请出房间')
        await Vuex.commit('clearToken')
      }

      if (Vuex.state.chat.role.id == sendid) {
        Vue.$message.success('操作成功')
      }
    })

    // 编辑模式切换
    Vue.Bus.$on('editMode_change', async () => {
      if (Vuex.state.chat.editMode) {
        Vue.$message.success(
          '编辑模式开启,双击模型进行展板编辑，管理员请使用WSAD或方向键进行移动，鼠标移动已禁用'
        )
      } else {
        Vue.$message.info('编辑模式关闭,鼠标移动已恢复')
      }
    })

    // 提示
    Vue.Bus.$on('message_tips', ({ theme, content }) => {
      Vue.$message[theme]({
        content
      })
    })

    // 屏幕播放
    Vue.Bus.$on('screenShare', (close) => {
      if (Vuex.state.initConfig.sceneList[Vuex.state.sceneCurrentIndex].sceneId === 'art') {
        if (document.o2.screen)
          document.o2.screen.playVideo(
            close ? '' : document.getElementById('share_stream').querySelector('video').id
          )
      }

      if (Vuex.state.initConfig.sceneList[Vuex.state.sceneCurrentIndex].sceneId === 'meeting1') {
        if (document.o2.screen002)
          document.o2.screen002.playVideo(
            close ? '' : document.getElementById('share_stream').querySelector('video').id
          )
      }

      if (Vuex.state.initConfig.sceneList[Vuex.state.sceneCurrentIndex].sceneId === 'meeting2') {
        if (document.o2.screen004)
          document.o2.screen004.playVideo(
            close ? '' : document.getElementById('share_stream').querySelector('video').id
          )
      }
    })

    // 屏幕推流
    Vue.Bus.$on('screenStream', (url) => {
      if (Vuex.state.initConfig.sceneList[Vuex.state.sceneCurrentIndex].sceneId === 'art') {
        if (document.o2.screen) document.o2.screen.playVideo(url ? url : '')
      }

      if (Vuex.state.initConfig.sceneList[Vuex.state.sceneCurrentIndex].sceneId === 'meeting1') {
        if (document.o2.screen002) document.o2.screen002.playVideo(url ? url : '')
      }

      if (Vuex.state.initConfig.sceneList[Vuex.state.sceneCurrentIndex].sceneId === 'meeting2') {
        if (document.o2.screen004) document.o2.screen004.playVideo(url ? url : '')
      }
    })

    // 弹出传送
    Vue.Bus.$on('enterRegion', (name) => {
      if (name === 'C_01') {
        Vuex.commit('changeMapShow2', true)
        setTimeout(() => {
          Vuex.commit('changeMapShow', true)
        }, 0)
      }
    })

    // 关闭传送
    Vue.Bus.$on('leaveRegion', (name) => {
      if (name === 'C_01') {
        Vuex.commit('changeMapShow', false)
        setTimeout(() => {
          Vuex.commit('changeMapShow2', false)
        }, 500)
      }
    })

    // 全景视频
    Vue.Bus.$on('panoVideo', (val) => {
      if (val) {
        if (!document.getElementById('bgm').paused) {
          document.getElementById('bgm').pause()
          if (document.getElementById('sound')) {
            document.getElementById('sound').pause()
          }
        }

        Vuex.commit('setPlayVideoName', val)
        setTimeout(() => {
          const duration = document.sInteraction.panoVideo.videoElement.duration // 假设视频duration为1小时1分5秒
          const hours = Math.floor(duration / 3600) // 小时数
          const minutes = Math.floor((duration % 3600) / 60) // 分钟数
          const seconds = Math.floor(duration % 60) // 秒数
          console.log(seconds)
          let timeString = ''
          if (hours > 0) {
            timeString = `${hours.toString().padStart(2, '0')}:`
          }
          timeString += `${minutes.toString().padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`

          Vuex.commit('setDuration', timeString)
          console.log(timeString) // 输出格式为“时:分:秒”，不足2位前面补0，如果小于1小时省略小时位
        }, 500)

        Vuex.commit('setPlayVideoState', 1)
        Vuex.commit('chat/changeWebui', false)
      }
    })
  }

  // 展板配置
  async get_show(meshId) {
    const res = await getshow()
    let _o2 = document.o2
    Vuex.commit('chat/setboardData', res)

    if (meshId) {
      let ob = _o2.search_obj_by_guid2(meshId)
      ob.material.map = null
      ob.material.color.setRGB(1, 0, 0)
      ob.material.needsUpdate = true
    }

    res.forEach((f) => {
      f.object = _o2.search_obj_by_guid2(f.model_guid)
      if (f.object && f.object.material) {
        if (f.publish_preview) {
          f.object.material.map = _o2.create_map(f.publish_preview)
          f.object.material.color.setRGB(1, 1, 1)
          f.object.material.needsUpdate = true
          f.object.sw = f
        } else {
          console.log('未布展')
          f.object.material.color.setRGB(0, 0, 0)
        }

        if (f.link && !f.publish_preview) {
          f.object.material.map = null
          f.object.material.color.set('#409EFF')
          f.object.material.needsUpdate = true
        }
      }
    })
  }

  async edit_Board({ type, id, resid }) {
    await editBoard({
      type,
      id,
      resid
    })
  }
}

export { o2Init }
