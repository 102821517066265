<template>
  <div id="app">
    <router-view />

    <loginTips />
  </div>
</template>

<script>
import loginTips from '@/components/pageFunc/loginTips.vue'

export default {
  name: 'App',
  data() {
    return {
      go: false
    }
  },
  async beforeCreate() {
    this.$store.commit('initFrames')

    // let loadedCount = 0

    // function preloadImg(src) {
    //   return new Promise((resolve, reject) => {
    //     const img = new Image()
    //     img.src = src
    //     img.crossOrigin = 'anonymous'
    //     img.onload = function() {
    //       loadedCount++
    //       console.log(`已加载 ${loadedCount} 张图片`)
    //       resolve(img)
    //     }
    //     img.onerror = function() {
    //       reject(new Error(`加载图片失败：${src}`))
    //     }
    //   })
    // }

    // await Promise.all(this.$store.state.frames.map((src) => preloadImg(src)))
    // await Promise.all(this.$store.state.xulie.boy1.map((src) => preloadImg(src)))
    // await Promise.all(this.$store.state.xulie.boy2.map((src) => preloadImg(src)))
    // await Promise.all(this.$store.state.xulie.boy3.map((src) => preloadImg(src)))
    // await Promise.all(this.$store.state.xulie.girl1.map((src) => preloadImg(src)))
    // await Promise.all(this.$store.state.xulie.girl2.map((src) => preloadImg(src)))
    // await Promise.all(this.$store.state.xulie.girl3.map((src) => preloadImg(src)))
    // console.log('所有图片加载完毕，开始渲染 DOM')
    // this.go = true
    // 在这里开始渲染 DOM
  },
  components: {
    loginTips
  }
}
</script>
<style lang="scss">
// body {
//   &::after {
//     content: '';
//     position: fixed;
//     left: 0;
//     top: 0;
//     width: 100vw;
//     height: 100vh;
//     z-index: 9999;
//     background: linear-gradient(to bottom, #104655, #0a2e3d, #020d1d);
//   }

//   &::before {
//     content: '敬请期待';
//     position: fixed;
//     left: 50%;
//     top: 40%;
//     transform: translate(-50%, -50%);
//     font-size: 100px;
//     letter-spacing: 2px;
//     z-index: 10000;
//     white-space: nowrap;
//     color: #fff;
//     text-shadow: 0 0 10px #dbfdff, 0 0 1px #dbfdff, 0 0 1px #dbfdff, 0 0 1px #dbfdff;
//   }
// }

// // 移动端 竖屏
// @media screen and (max-width: 767px) and (max-height: 999px) and (orientation: portrait),
//   screen and (max-width: 999px) and (max-height: 500px) {
//   body {
//     &::after {
//       z-index: -9999;
//       background: #fff;
//     }

//     &::before {
//       z-index: -10000;
//     }
//   }
// }
</style>
