import * as THREE from 'three/build/three.module'
import Bus from '../../../common/js/bus'
import Vuex from './store'
import Vue from '../../main'
let mc = {}
var _o2
var _o2play
var bTempSit //临时坐下
var curRegion //当前区域
class o2play {
  constructor() {
    //行走
    this.bRunning = false
    this.regionNode = null
    this.move_ctrl = {
      canMove: true,
      bFreeView: true,
      target_angle: 0,
      ground_height: 0,
      colli_height: 1000,
      down_speed: 0,
      last_clip_name: '',
      posPlayer: new THREE.Vector3(0, 0, 0),
      startPos: new THREE.Vector3(0, 0, 0),
      endPos: new THREE.Vector3(0, 0, 0),
      viewHeight: 1500,
      moveSpd: 1500,
      moveDir: new THREE.Vector3(),
      bAutoMove: false,
      bCtrlMove: false,
      bLookAtForward: false,
      targetAutoMove: new THREE.Vector3(),
      gravity: 9800
    }
    mc = this.move_ctrl
    document.o2play = this
    _o2play = this
    _o2 = document.o2
  }

  resetMC(){
    mc.gravity = 9800
    mc.canMove = true
    mc.followObj = null
    mc.down_speed = 0
    mc.colli = null
  }

  update_player_position(time_passed) {
    //处理角色移动
    mc = this.move_ctrl
    if (!_o2 || !mc.canMove) return
    let mc = this.move_ctrl
    let target = mc.posPlayer.clone()
    let role = _o2.dummy
    if (_o2.role && _o2.role.obj) role = _o2.role.obj
    if (!role || _o2?.role?.sit) return //没有角色直接返回
    let movedir = mc.moveDir.clone()
    if (movedir.length() <= 0.001) {
      movedir.set(0, 0, -1)
    }
    let dir = new THREE.Vector3()
    dir.set(0, 0, 0)
    dir.applyMatrix4(_o2.camera.matrixWorld)
    movedir.applyMatrix4(_o2.camera.matrixWorld)
    movedir.sub(dir)
    movedir.y = 0
    movedir.normalize()
    let clip_name = 'idle'
    if (_o2play.carrier) {
      _o2play.carrier.getWorldPosition(_o2play.carrier.positionWorld)
    }
    if (1) {
      if (mc.bAutoMove) {
        mc.bCtrlMove = true
        clip_name = 'walk'
        //先自动移动
        let dst = mc.targetAutoMove.clone()
        dst.sub(mc.posPlayer)
        dst.y = 0
        let len = dst.length()
        if (len <= mc.moveSpd * time_passed) {
          mc.bAutoMove = false
          mc.bMoveFinish = true
          target = mc.targetAutoMove.clone()
        } else {
          dst.normalize()
          movedir.set(dst.x, dst.y, dst.z)
          dst.multiplyScalar(mc.moveSpd * time_passed)
          target = mc.posPlayer.clone()
          target.add(dst)
        }
      } else {
        //然后键盘控制
        let move = mc.moveDir.clone()
        let len = move.length()
        if (len > 0.01) {
          move.normalize()
          mc.bCtrlMove = true
          clip_name = 'walk'
          let dir = new THREE.Vector3()
          dir.set(0, 0, 0)
          dir.applyMatrix4(_o2.camera.matrixWorld)
          move.applyMatrix4(_o2.camera.matrixWorld)
          move.sub(dir)
          move.y = 0
          move.normalize()
          move.multiplyScalar(time_passed * mc.moveSpd)
          target = mc.posPlayer.clone()
          target.add(move)
        } else {
          movedir.set(0, 0, 0)
        }
      }
      if (_o2play.carrier) {
        _o2play.carrier.getWorldPosition(_o2play.carrier.positionWorld)
        target.add(_o2play.carrier.positionWorld.clone().sub(mc.posCarrier))
        mc.posCarrier.copy(_o2play.carrier.positionWorld)
      }
      target = this.move_with_colli(target, time_passed)
      let oldpos_player = mc.posPlayer.clone()
      mc.posPlayer.set(target.x, target.y, target.z)

      if (mc.bAutoMove) {
        //判断下移动距离停止移动
        oldpos_player.sub(mc.posPlayer)
        let len = oldpos_player.length()
        if (len < 1) {
          mc.bAutoMove = false
          mc.bMoveFinish = true
        }
      }
    }
    if (mc.followObj) {
      mc.posPlayer.copy(mc.followObj.position)
    }
    //更新人物位置
    if (_o2play.carrier) {
      let localPos = mc.posPlayer.clone()
      let mat = _o2play.carrier.matrixWorld.clone().invert()
      localPos.applyMatrix4(mat)
      role.position.copy(localPos)
    } else {
      role.position.set(mc.posPlayer.x, mc.posPlayer.y, mc.posPlayer.z)
    }
    //朝向和动作
    if (role && !_o2?.role?.sit) {
      if (movedir.length() > 0.01) {
        let angle = Math.asin(movedir.x)
        if (movedir.z < 0) angle = 3.1415 - angle
        mc.target_angle = angle
      } else if (mc.bLookAtForward) {
        let dir = _o2.cameraControls.target.clone()
        dir.sub(_o2.camera.position)
        dir.y = 0
        dir.normalize()
        mc.target_angle = Math.atan2(dir.x, dir.z)
      }
      let current = role.rotation._y
      let dst = (mc.target_angle * 180) / 3.1415 - (current * 180) / 3.1415
      if (Math.abs(dst) > 180) {
        if (dst < 0) current = current - 3.1415 * 2
        else current = current + 3.1415 * 2
      }
      current = current * 0.8 + mc.target_angle * 0.2
      // if (!bCtrlMove) current = old_angle
      // else old_angle = current
      role.rotation.set(role.rotation.x, current, role.rotation.z)

      if (mc.last_clip_name != clip_name) {
        _o2.role.setState(clip_name)
        mc.last_clip_name = clip_name
      }
    }
  }
  onMOveFinish() {
    if (mc.bMoveFinish) {
      mc.bMoveFinish = false
      let min_dst = 500 * 500
      let found_sit
      let sits = Vuex.state.chat.sits
      for (let i = 0; i < sits.length; i++) {
        let sit = sits[i]
        let sitpos = sit.pos
        let dsty = Math.abs(sitpos.y - mc.posPlayer.y)
        let dstxz =
          (sitpos.x - mc.posPlayer.x) * (sit.x - mc.posPlayer.x) +
          (sitpos.z - mc.posPlayer.z) * (sitpos.z - mc.posPlayer.z)
        // console.log(sit.name,dsty,dstxz);
        if (dsty < 1000 && dstxz < min_dst) {
          min_dst = dstxz
          found_sit = sit
          console.log(found_sit)

          let curScene = Vuex.state.initConfig.sceneList[Vuex.state.sceneCurrentIndex].sceneId

          console.log(found_sit.name)

          if (found_sit.name.includes(curScene)) {
            this.try_sit(found_sit)
          }
          // if(curScene == "phoenix" && sit?.name.includes("phoenix")){
          // this.try_sit(found_sit)
          // }else if(curScene == "extra_01" && !sit?.name.includes("phoenix")){
          // }
          break
        }
      }
    }
  }
  updateCamera() {
    //更新角色和相机位置到posPlayer
    let oldtarget = _o2.cameraControls.target.clone()
    let dir = _o2.camera.position.clone()
    dir.sub(oldtarget)
    let neweye = mc.posPlayer.clone()
    neweye.add(dir)
    //视线后端碰撞检测
    if (!_o2.fixed_view && _o2.view_length > 1000) {
      let target = new THREE.Vector3(mc.posPlayer.x, mc.posPlayer.y + mc.viewHeight, mc.posPlayer.z)
      let dir2 = dir.clone()
      dir2.normalize()

      let ray = new THREE.Raycaster(target, dir2, 10, _o2.view_length)
      ray.camera = _o2.camera
      let rt = _o2.ray_test3(ray)
      if (rt.length) {
        let pos = rt[0].point
        neweye.set(pos.x, pos.y - mc.viewHeight, pos.z)
        //console.log('ray_tested', rt[0])
      }
    }
    if (!_o2.fixed_view) {
      _o2.camera.position.set(neweye.x, neweye.y + mc.viewHeight, neweye.z)
      _o2.cameraControls.target.set(mc.posPlayer.x, mc.posPlayer.y + mc.viewHeight, mc.posPlayer.z)
    }
  }

  try_sit(sit) {
    // if (!Vuex.state.chat.role.auth && Vuex.state.initConfig.sceneList[Vuex.state.sceneCurrentIndex].sceneId.includes('phoenix')) {
    //   Vue.$message.info('无权限')
    //   return
    // }

    _o2.found_sit = sit
    let ws = _o2.ws
    if (ws) {
      let str = ws.requestCmd.CMD_USER_SIT_DOWN + ',' + sit.name + ',' + _o2.role.id
      ws.send(str)
    } else {
      _o2.role?.setSit(sit)
    }
  }

  checkRegion() {
    if (_o2.regionNode && _o2.role?.obj) {
      if (!_o2.role.obj.positionWorld) {
        _o2.role.obj.positionWorld = new THREE.Vector3()
      }
      _o2.role.obj.getWorldPosition(_o2.role.obj.positionWorld)
      if (curRegion) {
        let pos = _o2.role.obj.positionWorld.clone()
        pos.y += 100
        let box = curRegion.geometry.boundingBox
        let sphere = curRegion.geometry.boundingSphere
        pos.applyMatrix4(curRegion.matrixWorld.clone().invert())
        let v1 =
          (curRegion.name.startsWith('R_') && pos.x > box.max.x) ||
          pos.x < box.min.x ||
          pos.y > box.max.y ||
          pos.y < box.min.y ||
          pos.z > box.max.z ||
          pos.z < box.min.z
        let v2 = curRegion.name.startsWith('C_') && pos.length() > sphere.radius
        if (v1 || v2) {
          Bus.$emit('leaveRegion', curRegion.name)
          console.log('leaveRegion:', curRegion.name)
          curRegion = null
        }
      } else {
        for (let i = 0; i < _o2.regionNode.children.length; i++) {
          const e = _o2.regionNode.children[i]
          let box = e.geometry.boundingBox
          let sphere = e.geometry.boundingSphere
          let pos = _o2.role.obj.positionWorld.clone()
          pos.y += 100
          pos.applyMatrix4(e.matrixWorld.clone().invert())
          let v1 =
            e.name.startsWith('R_') &&
            pos.x < box.max.x &&
            pos.x > box.min.x &&
            pos.y < box.max.y &&
            pos.y > box.min.y &&
            pos.z < box.max.z &&
            pos.z > box.min.z
          let v2 = e.name.startsWith('C_') && pos.length() < sphere.radius
          if (v1 || v2) {
            curRegion = e
            Bus.$emit('enterRegion', curRegion.name)
            console.log('enterRegion:', curRegion.name)
            break
          }
        }
      }
    }
  }

  move_with_colli(target, time_passed) {
    if (!mc.colli || _o2play.carrier) {
      mc.posPlayer.set(target.x, target.y, target.z)
      return target
    }
    let radius = 400
    let final = mc.posPlayer.clone()
    let org = mc.posPlayer.clone()
    org.y += mc.colli_height
    let target2 = target.clone()
    target2.y += mc.colli_height
    let dst = target2.clone()
    dst.sub(org)
    let len = dst.length() + radius
    let raycaster = new THREE.Raycaster()
    raycaster.far = 1000
    let dir = dst.clone()
    dir.normalize()
    org.sub(dir)
    raycaster.set(org, dir)
    var intersects = raycaster.intersectObjects(mc.colli.children, true)
    if (intersects.length > 0) {
      if (intersects[0].distance < len) {
        dir.multiplyScalar(intersects[0].distance - radius)
        final.add(dir) //现在是退回到被拦截的位置，下面需要从切线方向滑行
        //滑行碰撞检测
        dir.normalize()
        let normal = intersects[0].face.normal.clone()
        normal.applyEuler(intersects[0].object.rotation)
        let spit = new THREE.Vector3(0, 1, 0)
        spit.cross(normal)
        spit.normalize()
        if (spit.dot(dir) < 0) spit = spit.multiplyScalar(-1) //旋转方向至行走方向的一边
        let len1 = dst.dot(spit)
        let len_final = len1 + radius
        raycaster.set(org, spit)
        let intersects3 = raycaster.intersectObjects(mc.colli.children, true)
        if (intersects3.length) {
          if (intersects[0].distance < len_final) {
            spit.multiplyScalar(intersects[0].distance - radius)
            final.add(spit)
          } else {
            spit.multiplyScalar(len_final - radius)
            final.add(spit)
          }
        } else {
          spit.multiplyScalar(len_final - radius)
          final.add(spit)
        }
      } else {
        final.set(target.x, mc.posPlayer.y, target.z)
      }
    } else {
      final.set(target.x, mc.posPlayer.y, target.z)
    }

    //下落
    mc.down_speed = mc.down_speed + mc.gravity * time_passed
    let down_length = mc.down_speed * time_passed
    org.set(org.x, mc.posPlayer.y + 500, org.z)
    dir.set(0, -1, 0)
    let y = mc.posPlayer.y - down_length
    let raycaster2 = new THREE.Raycaster()
    raycaster2.far = 1000
    raycaster2.set(org, dir)
    intersects = raycaster2.intersectObjects(mc.colli.children, true)
    if (intersects.length > 0) {
      //有碰撞到
      let pos = intersects[0].point
      //console.log("地面高度:" + pos.y);
      if (intersects[0].distance < 500 + down_length) {
        mc.down_speed = 0
        y = pos.y
        let dst = pos.y - mc.posPlayer.y
        if (dst < mc.colli_height) {
          if (pos.y > mc.posPlayer.y) {
            y = mc.posPlayer.y * 0.5 + pos.y * 0.5
          } else {
            y = pos.y
          }
        }
      }
    }
    if (y < mc.ground_height) {
      //落到了0平面
      y = mc.ground_height
      mc.down_speed = 0
    }
    final.y = y
    //mc.posPlayer.set(final.x, final.y, final.z);
    //console.log("地面高度:" + final.y);
    return final
  }

  move_to(intersect) {
    if (!mc.canMove) return
    mc.bAutoMove = true
    let point = intersect.point
    mc.targetAutoMove.x = point.x
    mc.targetAutoMove.y = mc.ground_height
    mc.targetAutoMove.z = point.z
  }

  setTempSit(intersect) {
    if (intersect) {
      let pos = intersect.point
      let obj = _o2.role.obj
      if (obj && _o2.role) {
        mc.posPlayer.set(pos.x, pos.y - 460, pos.z)
        obj.position.set(pos.x, pos.y - 460, pos.z)
        _o2.role.sit = 'temp'
        _o2.role.setState('sit')
      }
    }
  }

  setFollow(obj) {
    mc.followObj = obj
    if (_o2.role?.obj && obj) {
      _o2.role.obj.position.copy(obj.position)
      //mc.canMove = false;
    }
  }

  setRolePos(x, y, z) {
    _o2.role?.obj.position.set(x || 0, y || 0, z || 0)
    mc.posPlayer.set(x || 0, y || 0, z || 0)
  }

  setCarrier(obj) {
    if (obj && _o2.role?.obj) {
      _o2play.carrier = obj
      obj.add(_o2.role.obj)

      obj.updateMatrixWorld()
      let mat = obj.matrixWorld.clone().invert()
      _o2.role.obj.applyMatrix4(mat)
      if (!obj.positionWorld) {
        obj.positionWorld = new THREE.Vector3()
      }
      obj.getWorldPosition(obj.positionWorld)
      _o2play.move_ctrl.posCarrier = obj.positionWorld.clone()
    } else if (_o2play.carrier && _o2.role?.obj) {
      _o2play.carrier.updateMatrixWorld()
      _o2.role.obj.applyMatrix4(_o2play.carrier.matrixWorld)
      _o2.playerMgr.playerNode.add(_o2.role?.obj)
      _o2play.carrier = null
    }
  }

  frame_move(time_passed) {
    this.update_player_position(time_passed)
    this.checkRegion()
    this.updateCamera()
    // this.onMOveFinish()
  }
  onMouseDown(event) {
    event.preventDefault()
    // console.log('player mouse down')
    mc.clickTime = Date.now()
    if(this.onInteraction)this.onInteraction();
  }
  onMouseUp(event) {
    event.preventDefault()
    let intersects = _o2.ray_test(event)
    let time = Date.now()
    if (time - mc.clickTime > 200) return

    if (Vuex.state.videoplayState === 1) {
      // 正在播放中
      Vuex.commit('resetVideoSlowShow')
    }

    if (intersects.length > 0) {
      console.log(intersects[0].object)
      if (Vuex.state.chat.editMode) {
        if (bTempSit) {
          console.log(bTempSit)
          _o2play.setTempSit(intersects[0])
          return
        }
        //_o2play.onItemClick(intersects[0])
      } else {
        if (intersects[0].object.sw) {
          // 查看图片或视频
          Vuex.commit('chat/setboxShow', true)
          Bus.$emit('showBox', [intersects[0].object.sw.publish_url], intersects[0].object.sw.link)
          return
        } else {
          if (_o2play.onItemClick) {
            let flag = _o2play.onItemClick(intersects[0])
            if (flag) return
          }
          _o2play.move_to(intersects[0])
        }
      }
    }

    // console.log('player mouse up')
  }

  onMouseMove(event) {}
  onKeyDown(e) {
    let mc = _o2play.move_ctrl
    if (_o2.role && _o2.role.obj && _o2.role.sit == 'temp') {
      let angle_sit = _o2.role.obj.rotation.y
      let pos_sit = _o2.role.obj.position.clone()
      switch (e.key) {
        case 'ArrowLeft':
          angle_sit = angle_sit + (5 / 180) * 3.1416
          break
        case 'ArrowRight':
          angle_sit = angle_sit - (5 / 180) * 3.1416
        case 'ArrowUp':
          pos_sit.y = pos_sit.y + 5
          break
        case 'ArrowDown':
          pos_sit.y = pos_sit.y - 5
          break
        // case 'r':
        // case 'R':
        //   let str = prompt('输入座位名称(楼层-房间-座位号)')
        //   if (str && str != '') {
        //     add_sit({
        //       name: str,
        //       x: Math.ceil(pos_sit.x),
        //       y: Math.ceil(pos_sit.y),
        //       z: Math.ceil(pos_sit.z),
        //       angle: Math.round((180 * angle_sit) / 3.1416)
        //     }).then((res) => {
        //       if (res.code == 0) {
        //         that.$message.success('注册成功')
        //       } else {
        //         that.$message.error(res.msg)
        //       }
        //     })
        //     add_lable_to_sit(str, pos_sit.x, pos_sit.y, pos_sit.z)
        //   }
        //   break
        case 'w':
        case 'W':
          angle_sit = 0
          break
        case 'S':
        case 's':
          angle_sit = 3.1416
          break
        case 'a':
        case 'A':
          angle_sit = 3.1416 / 2
          break
        case 'd':
        case 'D':
          angle_sit = -3.1416 / 2
          break
        case 'z':
        case 'Z':
          if (Vue.$lyh.isMobile()) return
          bTempSit = true
          break
        case 'r':
        case 'R':
          if (Vue.$lyh.isMobile()) return
          Bus.$emit('openSetSit', {
            x: Math.ceil(pos_sit.x),
            y: Math.ceil(pos_sit.y),
            z: Math.ceil(pos_sit.z),
            angle: Math.round((180 * angle_sit) / 3.1416)
          })
          Vuex.commit('chat/changeSitShow', true)
          document.o2play.pauseKeyStop()
          break
        case 'x':
        case 'X':
          if (_o2.role && _o2.role.sit == 'temp') {
            _o2.role.setSit()
          }
          break
      }
      mc.posPlayer.y = pos_sit.y
      _o2.role.obj.position.y = pos_sit.y
      _o2.role.obj.rotation.y = angle_sit
      return
    }

    switch (e.key) {
      case 'p':
      case 'P':
        {
          // _o2.chat_pop(
          //   _o2.player,
          //   "这是一大长串文字，不知道能不能自动换行啥的道能不能自动换行啥的道能不能自动换行啥的"
          // );
        }
        break
      case 'q':
      case 'Q':
        mc.moveDir.y = mc.moveSpd
        mc.bAutoMove = false
        break
      case 'e':
      case 'E':
        mc.moveDir.y = -mc.moveSpd
        mc.bAutoMove = false
        break
      case 'w':
      case 'W':
        mc.moveDir.z = -mc.moveSpd
        mc.bAutoMove = false
        break
      case 'ArrowUp':
        mc.moveDir.z = -mc.moveSpd
        mc.bAutoMove = false
        break
      case 'a':
      case 'A':
        mc.moveDir.x = -mc.moveSpd
        mc.bAutoMove = false
        break
      case 'ArrowLeft':
        mc.moveDir.x = -mc.moveSpd
        mc.bAutoMove = false
        break
      case 's':
      case 'S':
        mc.moveDir.z = mc.moveSpd
        mc.bAutoMove = false
        break
      case 'ArrowDown':
        mc.moveDir.z = mc.moveSpd
        mc.bAutoMove = false
        break
      case 'd':
      case 'D':
        mc.moveDir.x = mc.moveSpd
        mc.bAutoMove = false
        break
      case 'ArrowRight':
        mc.moveDir.x = mc.moveSpd
        mc.bAutoMove = false
        break
      case 'b':
      case 'B':
        if (Vue.$lyh.isMobile()) return
        Vuex.commit('chat/changeEditMode')
        break
      case 'z':
      case 'Z':
        if (Vue.$lyh.isMobile()) return
        bTempSit = true
        break
      case 'h':
      case 'H':
      // if (Vue.$lyh.isMobile()) return
      // Vuex.commit('chat/editAuth', true)
    }
  }
  onKeyUp(e) {
    let mc = _o2play.move_ctrl
    switch (e.key) {
      case 'q':
      case 'Q':
        mc.moveDir.y = 0
        break
      case 'e':
      case 'E':
        mc.moveDir.y = 0
        break
      case 'w':
      case 'W':
        mc.moveDir.z = 0
        break
      case 'ArrowUp':
        mc.moveDir.z = 0
        break
      case 'a':
      case 'A':
        mc.moveDir.x = 0
        break
      case 'ArrowLeft':
        mc.moveDir.x = 0
        break
      case 's':
      case 'S':
        mc.moveDir.z = 0
        break
      case 'ArrowDown':
        mc.moveDir.z = 0
        break
      case 'd':
      case 'D':
        mc.moveDir.x = 0
        break
      case 'ArrowRight':
        mc.moveDir.x = 0
        break
      case 'z':
      case 'Z':
        bTempSit = false
        break
    }
  }

  add_lable_to_sit(str, x, y, z) {
    let canvas = document.createElement('canvas')
    canvas.width = 80
    canvas.height = 32
    const drawingContext = canvas.getContext('2d')
    drawingContext.fillStyle = '#000000'
    drawingContext.globalAlpha = 0.1
    drawingContext.fillRect(0, 0, 80, 32)
    drawingContext.globalAlpha = 1
    drawingContext.fillStyle = '#ffffff'
    drawingContext.font = '20px Georgia'
    drawingContext.textAlign = 'center'
    drawingContext.fillText(str, 40, 14)
    let map = new THREE.CanvasTexture(canvas)
    let sitSprite = new THREE.Sprite(new THREE.SpriteMaterial({ map: map, color: '#ffffff' }))
    sitSprite.position.set(x, y + 800, z)
    sitSprite.scale.set(600, 120, 1)
    sitSprite.material.depthWrite = false
    _o2.scene.add(sitSprite)
  }

  init = (o2) => {
    this.o2 = o2
  }

  prepare_run = () => {
    this.bRunning = true
    if (!this.o2) return
    let that = this
    this.recall_fun = function(time_passed) {
      that.frame_move(time_passed)
    }
    this.o2.frame_move_recall.push(this.recall_fun)
    let container = this.o2.container
    container.addEventListener('pointerdown', this.onMouseDown, false)
    container.addEventListener('pointerup', this.onMouseUp, false)
    container.addEventListener('touchdown', this.onMouseDown, false)
    container.addEventListener('touchup', this.onMouseUp, false)
    container.addEventListener('pointermove', this.onMouseMove, false)
    container.addEventListener('dblclick', this.onDBClick, false)
    document.onkeydown = this.onKeyDown
    document.onkeyup = this.onKeyUp
    ////
    //初始化相机和位置
    let o2 = this.o2
    if (o2.play_setting) {
      if (o2.play_setting.fov >= 30 && o2.play_setting.fov <= 150) {
        o2.camera.fov = o2.play_setting.fov
        o2.camera.updateProjectionMatrix()
      }
      if (o2.play_setting.init_camera) {
        o2.camera.position.set(
          o2.play_setting.init_camera.eye.x,
          o2.play_setting.init_camera.eye.y,
          o2.play_setting.init_camera.eye.z
        )
        o2.cameraControls.target.set(
          o2.play_setting.init_camera.target.x,
          o2.play_setting.init_camera.target.y,
          o2.play_setting.init_camera.target.z
        )
        if (o2.play_setting.init_camera.posPlayer) {
          this.mc.posPlayer.set(
            o2.play_setting.init_camera.posPlayer.x,
            o2.play_setting.init_camera.posPlayer.y,
            o2.play_setting.init_camera.posPlayer.z
          )
        } else {
          this.mc.posPlayer.set(0, 0, 0)
        }
      }
    }
  }

  after_run = () => {
    this.bRunning = false
    if (!this.o2) return
    let lst = []
    for (let i = 0; i < this.o2.frame_move_recall.length; i++) {
      if (this.o2.frame_move_recall[i] === this.recall_fun) {
      } else {
        lst.push(this.o2.frame_move_recall[i])
      }
    }
    this.o2.frame_move_recall = lst
    let container = this.o2.container
    container.removeEventListener('pointerdown', this.onMouseDown, false)
    container.removeEventListener('pointerup', this.onMouseUp, false)
    container.removeEventListener('touchdown', this.onMouseDown, false)
    container.removeEventListener('touchup', this.onMouseUp, false)
    container.removeEventListener('pointermove', this.onMouseMove, false)
    container.removeEventListener('dbclick', this.onDBClick, false)
    document.onkeydown = null
    document.onkeyup = null
  }

  pauseKeyStop() {
    document.onkeydown = null
    document.onkeyup = null
  }

  pauseKeyStart() {
    document.onkeydown = this.onKeyDown
    document.onkeyup = this.onKeyUp
  }

  // 控制摇杆
  yaogan(x, y) {
    this.move_ctrl.bAutoMove = false
    this.move_ctrl.moveDir.set(x, 0, y)
  }

  // onItemClick({ object }) {
  //   // 处理展板事件
  //   Bus.$emit('sendBoard', object)
  // }

  // 拖拽处理事件
  async dragEvent(fileData) {
    if (!Vuex.state.chat.editMode) {
      return
    }

    if (fileData.file.size > 1024 * 1024 * 300) {
      Bus.$emit('message_tips', {
        theme: 'info',
        content: '素材大小不能超过300M'
      })
      return
    }

    let support = false

    const FILE = {
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'video/mp4': 'mp4'
    }

    for (const key in FILE) {
      if (fileData.file.type === key) {
        support = true
        break
      }
    }

    if (!support) {
      Bus.$emit('message_tips', {
        theme: 'info',
        content: '目前上传的文件类型仅支持图片或视频,图片格式为：jpg/png,视频格式为:mp4'
      })
      return
    }

    fileData.offsetX = fileData.x
    fileData.offsetY = fileData.y

    let found_show = null

    let intersects = document.o2.ray_test(fileData)

    if (intersects.length > 0) {
      let obj = intersects[0].object
      let f = Vuex.state.chat.boardData.find((f) => f.object == obj)
      if (f) {
        found_show = f
      }
    }

    if (!found_show) {
      Bus.$emit('message_tips', {
        theme: 'info',
        content: '请将素材往展板上拖拽'
      })
      return
    }

    let instance = Vue.$loading({
      fullscreen: true,
      attach: 'body',
      preventScrollThrough: false,
      showOverlay: true,
      text: '上传中..'
    })

    // 30秒自动关闭
    const timer = setTimeout(() => {
      clearTimeout(timer)
      instance.hide()
      instance = null
      console.log('超时,自动关闭窗口')
    }, 30000)

    const res = await Vue.$o2Function.upload(fileData.file)

    if (instance) {
      instance.hide()
      clearTimeout(timer)
    }

    console.log(res)

    await Vue.$o2Init.edit_Board({
      type: 1,
      id: found_show.id,
      resid: res.id
    })

    await Vue.$o2Init.get_show()
    Vue.$message.success('上传成功')
  }
}

export { o2play }
