import axios from 'axios'
import QS from 'qs'
import Vuex from './store'
import Vue from '../../main'

axios.defaults.baseURL = Vuex.state.api
axios.defaults.timeout = Vuex.state.timeout
axios.defaults.headers.post['Content-Type'] = Vuex.state.contentType

export async function get(url, params) {
  axios.defaults.headers.get.token = Vuex.state.token
  try {
    const res = await axios.get(url, {
      params: params
    })
    if (res.data.code == 201) {
      Vue.$message.error('token 已过期,请重新登录')
      await Vuex.commit('clearToken')
    }
    return res.data
  } catch (error) {
    throw error
  }
}

export async function post(url, params) {
  axios.defaults.headers.post.token = Vuex.state.token
  try {
    const res = await axios.post(url, QS.stringify(params))
    if (res.data.code == 201) {
      Vue.$message.error('token 已过期,请重新登录')
      await Vuex.commit('clearToken')
    }
    return res.data
  } catch (error) {
    throw error
  }
}
