/*
 * @Author: 雷云鹤
 * @Date: 2023-03-24 12:40:47
 * @Last Modified by: 雷云鹤
 * @Last Modified time: 2023-05-23 10:31:31
 */
/*  基于 trtc-js-sdk v4.15.0+ 请注意版本匹配 */
import TRTC from 'trtc-js-sdk'
import Bus from './bus'
import Vuex from '@/assets/js/store'
import { TrtcUserSig } from '@/assets/js/api'

class o2vrClient {
  constructor() {
    this.config = null // 音视频客户端参数
    this.shareConfig = null // 屏幕分享端参数
    this.client = '' // 推流端
    this.shareClient = '' // 屏幕分享端
    this.localStream = '' //本地流
    this.shareStream = '' //屏幕分享流
    this.micFlag = true // 是否采集音频
    this.cameraFlag = true // 是否采集视频
    this.share_ENV = true // 是否支持屏幕共享
    this.speakerFlag = true // 是否有扬声器
    this.userId = '' // 用户ID
    this.roomId = '' // 房间ID
    this.localStreamPlayFlag = false // 本地流是否开始play
    this.clientSubscribe = false // 本地流是否订阅
  }

  // 检测TRTC环境
  async testing_environment() {
    try {
      // 是否支持TRTC
      const canTRTC = await TRTC.checkSystemRequirements()
      if (!canTRTC) {
        throw 'Your browser is not compatible with TRTC.'
      }

      //获取麦
      const micList = await TRTC.getMicrophones()
      if (micList.length === 0) {
        // 'Microphone not found'
        Bus.$emit('TRTC_AUDIO_BAR', false)
        this.micFlag = false
      }

      //获取摄像头
      const cameraList = await TRTC.getCameras()
      if (cameraList.length === 0) {
        // 'Camera not found'
        Bus.$emit('TRTC_VIDEO_BAR', false)
        this.cameraFlag = false
      }

      // 获取扬声器
      const speakersList = await TRTC.getSpeakers()
      if (speakersList.length === 0) {
        // 'Speaker not found'
        this.speakerFlag = false
      }

      // 都未找到则无法开启TRTC
      if (!this.micFlag && !this.cameraFlag) {
        Bus.$emit('TRTC_SHARE_BAR', false)
        if (!this.speakerFlag) {
          throw 'Microphone and camera and speakers not found.'
        } else {
          // 打开 重新连接开关
          Bus.$emit('openReloadBtn', true)
          Bus.$emit('TRTC_ERROR', 'Microphone and camera not found.')
        }
      }
      if (!TRTC.isScreenShareSupported()) {
        // 设置不可点击屏幕共享
        Bus.$emit('TRTC_SHARE_BAR', false)
        this.share_ENV = false
      }
    } catch (error) {
      throw 'environment_Error: ' + error
    }
  }

  // 创建 推流端 拉流端 并开启拉流监听 userid必须为String类型 roomid 必须为number 类型
  async create(userId, roomId) {
    try {
      this.userId = userId.toString()
      this.roomId = roomId

      // TRTC 环境监测
      try {
        await this.testing_environment()

        // 获取appid和配置
        try {
          const app = await TrtcUserSig({ userid: this.userId })

          this.config = {
            mode: 'rtc',
            sdkAppId: app.appid,
            userId: this.userId,
            userSig: app.sig
          }
        } catch (error) {
          throw 'PHP API Error：' + error
        }

        //建立 推流端
        try {
          this.client = TRTC.createClient(this.config)
        } catch (error) {
          throw 'Failed to establish client：' + error
        }

        // 获取appid和配置
        try {
          const share_id = `share-${this.userId}`
          const app = await TrtcUserSig({ userid: share_id })

          this.shareConfig = {
            mode: 'rtc',
            sdkAppId: this.config.sdkAppId,
            userId: share_id,
            userSig: app.sig
          }
        } catch (error) {
          throw 'PHP API Error：' + error
        }

        //建立 屏幕分享端
        try {
          this.shareClient = TRTC.createClient(this.shareConfig)
        } catch (error) {
          throw 'Failed to establish shareClient' + error
        }

        //远方流增加
        this.client.on('stream-added', async (event) => {
          const remoteStream = event.stream
          const id = remoteStream.getUserId()
          await this.client.subscribe(remoteStream)

          // 分享
          if (id.includes('share')) {
            await remoteStream.play('share_stream')

            if (this.userId == remoteStream.getUserId().split('-')[1]) {
              // 打开按钮开关
              Vuex.commit('client/setShare', true)
            }

            Vuex.commit('client/setShareId', remoteStream.getUserId().split('-')[1])
            // 打开DOM
            Vuex.commit('client/setShareFlag', true)

            Bus.$emit('screenShare')
          } else {
            // 音视频流
            Bus.$emit('addlist', remoteStream)
          }
        })

        //远方流 订阅成功
        this.client.on('stream-subscribed', async (event) => {
          this.clientSubscribe = true
        })

        //远方流 移除
        this.client.on('stream-removed', async (event) => {
          const remoteStream = event.stream
          const id = remoteStream.getUserId()
          // 分享
          if (id.includes('share')) {
            // 关闭共享
            Vuex.commit('client/setShareFlag', false)

            // 关闭屏幕推流
            Bus.$emit('screenShare', true)
          } else {
            Vuex.commit('chat/changeTRTCStatus', {
              type: 'remove',
              id,
              flag: false
            })
          }
        })

        this.client.on('mute-audio', (obj) => {
          console.log('语音关', obj)
          Vuex.commit('chat/changeTRTCStatus', {
            type: 'audio',
            id: obj.userId,
            flag: false
          })
        })

        this.client.on('unmute-audio', (obj) => {
          console.log('语音开', obj)
          Vuex.commit('chat/changeTRTCStatus', {
            type: 'audio',
            id: obj.userId,
            flag: true
          })
        })

        this.client.on('mute-video', (obj) => {
          console.log('视频关', obj)
          Vuex.commit('chat/changeTRTCStatus', {
            type: 'video',
            id: obj.userId,
            flag: false
          })
        })

        this.client.on('unmute-video', (obj) => {
          console.log('视频开', obj)
          Vuex.commit('chat/changeTRTCStatus', {
            type: 'video',
            id: obj.userId,
            flag: true
          })
        })

        // 加入语音视频房间
        await this.join()
      } catch (error) {
        throw error
      }
    } catch (error) {
      if (error.includes('join')) {
        Bus.$emit('openReloadBtn', true)
        Bus.$emit('TRTC_ERROR', '加入房间失败，请您检查网络后重试')
      } else {
        Bus.$emit('TRTC_ERROR', 'TRTC Error: ' + error)
      }
    }
  }

  // 加入房间
  async join() {
    try {
      try {
        await this.client.join({ roomId: this.roomId })
      } catch (error) {
        throw 'Failed to join the clientRoom：' + error
      }

      try {
        await this.shareClient.join({ roomId: this.roomId })
      } catch (error) {
        throw 'Failed to join the shareClientRoom: ' + error
      }

      Vuex.commit('client/sethasJoin', true)

      if (!this.micFlag && !this.cameraFlag) {
        // 没有麦克风和视频的将到此结束
        return
      } else {
        try {
          // 创建本地流
          await this.createLocalStream()
        } catch (error) {
          throw 'createLocalStream_Error：' + error
        }

        try {
          if (this.share_ENV) {
            // 创建屏幕共享流
            await this.createShareStream()
          }
        } catch (error) {
          throw 'createShareStream_Error：' + error
        }

        // 没有问题
        Bus.$emit('login_trtc')
        // 关闭 重新连接开关
        Bus.$emit('openReloadBtn', false)
      }
    } catch (error) {
      throw error
    }
  }

  // 创建本地流
  async createLocalStream() {
    try {
      // 创建本地流
      this.localStream = TRTC.createStream({
        userId: this.config.userId,
        audio: this.micFlag,
        video: this.cameraFlag
      })
    } catch (error) {
      throw error
    }
  }

  // 创建屏幕分享流
  async createShareStream() {
    try {
      this.shareStream = TRTC.createStream({
        userId: `share-${this.userId}`,
        screenAudio: this.micFlag,
        screen: true,
        screenAudio: true // 采集系统音频
      })

      // 分享流停止
      this.shareStream.on('screen-sharing-stopped', async (event) => {
        // 改变发布状态 防止用户在发布过程中点击其它按钮
        Vuex.commit('client/setPublishState', 1)
        await this.shareClient.unpublish(this.shareStream)
        this.shareStream.close()
        // 改变发布状态 防止用户在发布过程中点击其它按钮
        Vuex.commit('client/setPublishState', 0)

        // 关闭共享
        Vuex.commit('client/setShareFlag', false)
        Vuex.commit('client/setShareId', null)
        // 关闭按钮开关
        Vuex.commit('client/setShare', false)

        // 关闭屏幕推流
        Bus.$emit('screenShare', true)
      })

      // 指定 Profile
      this.shareStream.setScreenProfile('1080p')
    } catch (error) {
      throw error
    }
  }

  // 初始化本地音视频流。
  async initLocalStream() {
    try {
      await this.localStream.initialize()

      // //SDK 内部会在该 div 元素下自动创建相应的音视频标签并在其上播放音视频。 播放本地流
      this.localStream.play('local_stream')

      // 暂时关闭 语音和视频
      await this.localStream.muteVideo()
      await this.localStream.muteAudio()
    } catch (error) {
      switch (error.name) {
        case 'NotAllowedError':
          // 提示用户不授权摄像头/麦克风访问无法进行音视频通话
          throw 'If you do not authorize camera/microphone access, you cannot make audio/video calls.'
        case 'NotReadableError':
          // 提示用户：暂时无法访问摄像头/麦克风，请确保当前没有其他应用请求访问摄像头/麦克风，并重试。
          throw 'Unable to access the camera/microphone temporarily. Please ensure that no other apps currently request access to the camera/microphone and try again.'
        default:
          throw 'Failed to initialize localStream: ' + error.name
      }
    }
  }

  // 开关 语音
  async muteAudio(flag) {
    try {
      if (flag) {
        try {
          // 若未发布
          if (!Vuex.state.client.publishFlag) {
            try {
              // 改变发布状态 防止用户在发布过程中点击其它按钮
              Vuex.commit('client/setPublishState', 1)
              if (!Vuex.state.client.initFlag) {
                await this.initLocalStream()
                Vuex.commit('client/setInitFlag', true)
              }
              await this.localStream.unmuteAudio()
              await this.client.publish(this.localStream)
              // 保存已发布信息
              Vuex.commit('client/setPublishFlag', true)
            } catch (error) {
              throw error
            } finally {
              Vuex.commit('client/setPublishState', 2)
            }
          } else {
            await this.localStream.unmuteAudio()
          }

          // 打开audio按钮
          Vuex.commit('client/setAudio', true)
        } catch (error) {
          throw 'client publish error:(muteAudio) ' + error
        }
      } else {
        try {
          // 若视频已经关闭
          if (!Vuex.state.client.video) {
            try {
              // 改变发布状态 防止用户在发布过程中点击其它按钮
              Vuex.commit('client/setPublishState', 1)
              await this.localStream.muteAudio()
              await this.client.unpublish(this.localStream)
              // 保存停止发布信息
              Vuex.commit('client/setPublishFlag', false)
            } catch (error) {
              throw error
            } finally {
              Vuex.commit('client/setPublishState', 0)
            }
          } else {
            await this.localStream.muteAudio()
          }
          // 关闭audio按钮
          Vuex.commit('client/setAudio', false)
        } catch (error) {
          throw 'client unpublish error:(muteAudio) ' + error
        }
      }
    } catch (error) {
      throw 'muteAudio(0x004): ' + error
    }
  }

  // 开关 视频
  async muteVideo(flag) {
    try {
      if (flag) {
        try {
          // 若未发布
          if (!Vuex.state.client.publishFlag) {
            try {
              // 改变发布状态 防止用户在发布过程中点击其它按钮
              Vuex.commit('client/setPublishState', 1)
              if (!Vuex.state.client.initFlag) {
                await this.initLocalStream()
                Vuex.commit('client/setInitFlag', true)
              }
              await this.localStream.unmuteVideo()
              await this.client.publish(this.localStream)
              // 保存已发布信息
              Vuex.commit('client/setPublishFlag', true)
            } catch (error) {
              throw error
            } finally {
              // 改变发布状态 防止用户在发布过程中点击其它按钮
              Vuex.commit('client/setPublishState', 2)
            }
          } else {
            await this.localStream.unmuteVideo()
          }

          // 打开avideo按钮
          Vuex.commit('client/setVideo', true)
        } catch (error) {
          throw 'client publish error:(muteVideo) ' + error
        }
      } else {
        try {
          // 若音频已经关闭
          if (!Vuex.state.client.audio) {
            try {
              // 改变发布状态 防止用户在发布过程中点击其它按钮
              Vuex.commit('client/setPublishState', 1)
              await this.localStream.muteVideo()
              await this.client.unpublish(this.localStream)
              // 保存停止发布信息
              Vuex.commit('client/setPublishFlag', false)
            } catch (error) {
              throw error
            } finally {
              Vuex.commit('client/setPublishState', 0)
            }
          } else {
            await this.localStream.muteVideo()
          }
          // 关闭avideo按钮
          Vuex.commit('client/setVideo', false)
        } catch (error) {
          throw 'client unpublish error:(muteVideo) ' + error
        }
      }
    } catch (error) {
      throw 'muteVideo(0x005): ' + error
    }
  }

  // 开关 屏幕共享
  async shareScreen(flag) {
    try {
      if (!flag) {
        // 关闭屏幕分享
        try {
          // 改变发布状态 防止用户在发布过程中点击其它按钮
          Vuex.commit('client/setPublishState', 1)
          await this.shareClient.unpublish(this.shareStream)
          // 关闭屏幕分享流
          this.shareStream.close()
          // 关闭按钮开关
          Vuex.commit('client/setShare', false)
          Vuex.commit('client/setShareId', null)
          // 关闭Dom
          Vuex.commit('client/setShareFlag', false)
        } catch (error) {
          throw 'shareClient unpublish Error(0x001): ' + error
        } finally {
          // 改变发布状态 防止用户在发布过程中点击其它按钮
          Vuex.commit('client/setPublishState', 0)
        }
      } else {
        // 初始化 屏幕分享流
        // 弹出选择窗口界面 请用户选择
        try {
          // 改变发布状态 防止用户在发布过程中点击其它按钮
          Vuex.commit('client/setPublishState', 1)
          await this.shareStream.initialize()
        } catch (error) {
          // 改变发布状态 防止用户在发布过程中点击其它按钮
          Vuex.commit('client/setPublishState', 0)
          throw 'shareStream initialize Error:(0x002): ' + error
        }

        try {
          await this.shareClient.publish(this.shareStream)
          // 改变发布状态 防止用户在发布过程中点击其它按钮
          Vuex.commit('client/setShareId', this.userId)
        } catch (error) {
          throw 'client publish shareStream Error:(0x003): ' + error
        } finally {
          Vuex.commit('client/setPublishState', 2)
        }
      }
    } catch (error) {
      throw error
    }
  }

  // 报错登出房间
  async loginOut() {
    await this.client.leave()
    await this.shareClient.leave()
    this.config = null // 音视频客户端参数
    this.shareConfig = null // 屏幕分享端参数
    this.client = '' // 推流端
    this.shareClient = '' // 屏幕分享端
    this.localStream = '' //本地流
    this.shareStream = '' //屏幕分享流
    this.micFlag = true // 是否采集音频
    this.cameraFlag = true // 是否采集视频
    this.share_ENV = true // 是否支持屏幕共享
    this.speakerFlag = true // 是否有扬声器
    this.userId = '' // 用户ID
    this.roomId = '' // 房间ID
    this.clientSubscribe = false // 本地流是否订阅
  }

  // 正常退出房间
  async leave() {
    if (Vuex.state.client.audio) {
      Vuex.commit('client/setAudio', false)
      await this.muteAudio(false)
    }

    if (Vuex.state.client.video) {
      Vuex.commit('client/setVideo', false)
      await this.muteVideo(false)
    }

    if (Vuex.state.client.share) {
      Vuex.commit('client/setShare', false)
      await this.shareScreen(false)
    }

    if (Vuex.state.client.initFlag) {
      this.localStream.stop('local_stream')
    }

    Vuex.commit('client/setShareId', null)

    await this.client.leave()
    await this.shareClient.leave()
    Vuex.commit('client/sethasJoin', false)
  }

  // 重新加入房间
  async reJoin(roomId) {
    try {
      try {
        await this.client.join({ roomId })
      } catch (error) {
        throw 'Failed to join the clientRoom：' + error
      }

      try {
        await this.shareClient.join({ roomId })
      } catch (error) {
        throw 'Failed to join the shareClientRoom: ' + error
      }

      if (Vuex.state.client.initFlag) {
        this.localStream.play('local_stream')
      }

      Vuex.commit('client/sethasJoin', true)

      // 没有问题
      Bus.$emit('login_trtc')
    } catch (error) {
      Bus.$emit('openReloadBtn', true)
      Bus.$emit('TRTC_ERROR', '加入房间失败，请您检查网络后重试')
    }
  }
}

export { o2vrClient }
