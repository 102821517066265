/*
 * @Author: 雷云鹤
 * @Date: 2023-03-02 10:40:52
 * @Last Modified by: 雷云鹤
 * @Last Modified time: 2023-05-29 15:37:22
 */
class lyh {
  constructor() {}

  // 判断移动端还是手机端 true为移动端
  isMobile() {
    var u = navigator.userAgent
    var browser = {
      versions: {
        //移动终端浏览器版本信息
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
        iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
        iPad: u.indexOf('iPad') > -1, //是否iPad
        weixin: u.indexOf('MicroMessenger') > -1, //是否微信
        qq: u.match(/\sQQ/i) == ' qq' //是否QQ
      }
    }

    let mobile = false

    for (const key in browser.versions) {
      if (browser.versions[key]) {
        mobile = true
      }
    }

    return mobile
  }

  // 判断移动端还是手机端 true为移动端
  isPhone() {
    var u = navigator.userAgent
    var browser = {
      versions: {
        //移动终端浏览器版本信息
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
        iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
        weixin: u.indexOf('MicroMessenger') > -1, //是否微信
        qq: u.match(/\sQQ/i) == ' qq' //是否QQ
      }
    }

    let mobile = false

    for (const key in browser.versions) {
      if (browser.versions[key]) {
        mobile = true
      }
    }

    return mobile
  }

  // 监听dom中的video出现 并禁用画中画
  disable_pictureInPicture(dom_id) {
    try {
      const targetNode = document.getElementById(dom_id)
      // 观察器的配置（需要观察什么变动）
      const config = { attributes: true, childList: true, subtree: true }
      // 当观察到变动时执行的回调函数
      const callback = function(mutationsList, observer) {
        // if (that.huazhonghua) {
        //   return
        // }
        for (let mutation of mutationsList) {
          if (mutation.type === 'childList') {
            console.log('有节点发生改变，当前节点的内容是：')
            if (!targetNode.querySelector('video').hasAttribute('disablePictureInPicture')) {
              targetNode.querySelector('video').setAttribute('disablePictureInPicture')
            }
            // else {
            //   that.huazhonghua = true
            // }
          } else if (mutation.type === 'attributes') {
            console.log('修改了属性')
          }
        }
      }
      // 创建一个观察器实例并传入回调函数
      const observer = new MutationObserver(callback)
      // 以上述配置开始观察目标节点
      observer.observe(targetNode, config)
    } catch (error) {
      console.log(error)
    }
  }

  // vue2 在线人物 场景 一键初始化
  async init(config, that) {
    try {
      document.o2.multiplayer.init(...config) // 连接websocket 并创建人物
      await that.$o2Init.init() // 下载场景并加载场景
    } catch (error) {
      throw error
    }
  }

  // 验证手机号
  phoneValidate(v) {
    let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    return reg.test(v)
  }

  // 验证 验证码
  codeValidate(v) {
    let reg = /^[+-]?\d*(\.\d*)?(e[+-]?\d+)?$/
    return reg.test(v)
  }

  // 防抖
  debounce(func, delay) {
    let timer = null
    return function(...args) {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        func.apply(this, args)
      }, delay)
    }
  }

  getColor() {
    const colorArr = [
      // 'linear-gradient(45deg, #f5fe00, #ff6600) !important',
      // 'linear-gradient(45deg, #ff9700, #ed1c24) !important',
      // 'linear-gradient(45deg, #f33a41, #ed0586) !important',
      // 'linear-gradient(45deg, #fea894, #ff1047) !important',
      // 'linear-gradient(45deg, #c01f95, #7115cc) !important',
      // 'linear-gradient(45deg, #9829ea, #5908fb) !important',
      'linear-gradient(45deg, #00b8f9, #0166eb) !important',
      'linear-gradient(45deg, #06edfe, #48b2fe) !important',
      // 'linear-gradient(45deg, #3ab54a, #8cc63f) !important',
      'linear-gradient(45deg, #90e630, #39d266) !important',
      'linear-gradient(45deg, #9aadb9, #354855) !important'
      // 'linear-gradient(45deg, #ca6f2e, #cb1413) !important'
    ]

    return colorArr[Math.floor(Math.random() * colorArr.length)]
  }

  preventIOS_SCALE() {
    // 禁止IOS 缩放
    document.addEventListener('gesturestart', function(event) {
      event.preventDefault()
    })
  }

  // 随机值
  RandomNumBoth(Min, Max) {
    var Range = Max - Min
    var Rand = Math.random()
    var num = Min + Math.round(Rand * Range) //四舍五入
    return num
  }

  // 生成指定范围随机数
  createRandom(arr) {
    let min = 0
    let max = arr.length
    var res = Math.floor(min + Math.random() * (max - min))
    return res
  }

  // 随机昵称
  generateRandomString(length) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  // 预加载图像
  preloadImage(url) {
    let img = new Image()
    img.src = url
    img.onload = function() {
      // 图像加载成功
      // 将 Image 对象添加到 HTML 页面中
      console.log('Gif预加载成功')
    }
    img.onerror = function() {
      // 图像加载失败
      console.log('Gif预加载成功')
    }
  }

  // \u00A0
}

export default lyh
